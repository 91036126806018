//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    minWidth: "200px",
    [theme.breakpoints.up("md")]: {
      width: "35%",
    },
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    float: "left",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px!important",
    lineHeight: "18px",
    marginTop: "9px",
  },
  closeIcon: {
    padding: "10px 22px",
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
    "&:hover": {
      color: "#ffffff",
    },
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  saveButton: {
    color: "white",
    padding: "10px 22px",
  },
  closesaveButton: {
    color: "#ffffff",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "15px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class MyRightModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            backgroundImage: this.props.bgColor ? this.props.bgColor : "gray",
            backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
            fontWeight: 700,
            fontStyle: "initial",
          }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4">
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="medium"
            onClick={this.props.onClose}
            startIcon={<CloseIcon />}
            style={{
              backgroundColor: "white",
              fontWeight: 700,
              fontStyle: "initial",
              color: "black",
              padding: "10px 22px",
            }}
            className={classes.closeButton}
          >
            Close
          </Button>

          <Button
            style={{
              backgroundImage: this.props.bgColor ? this.props.bgColor : "gray",
              fontWeight: 700,
              fontStyle: "initial",
              backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
              fontWeight: 700,
              fontStyle: "initial",
            }}
            variant="outlined"
            size="medium"
            className={classes.saveButton}
            startIcon={<SaveIcon />}
            onClick={this.props.handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyRightModal);
