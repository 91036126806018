import { HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const timeOffServices = {
    fetchData,
    addData,
    editData,
    updateStatus,
    deleteData,
    fetchTeamData
}


function fetchData( data) {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timeoffs/my?page=${data.pageNo}&limit=${process.env.REACT_APP_LIMIT}`
    if(data.lid){
      url=`${url}&lid=${data.lid}`
    }
    return axios
      .get(
        url,
        HEADER_TOKEN
      )
      .then((response) => response);
}


function fetchTeamData( data) {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timeoffs/team?page=${data.pageNo}&limit=${process.env.REACT_APP_LIMIT}`
    if(data.lid){
      url=`${url}&lid=${data.lid}`
    }
    if(data.user_name){
        url=`${url}&user_name=${data.user_name}`
    }
    return axios
      .get(
        url,
        HEADER_TOKEN
      )
      .then((response) => response);
}

function addData(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timeoffs`, data , HEADER_TOKEN).then(response => response)
};

function editData(data,id) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/edit/${id}`,  data , HEADER_TOKEN).then(response => response)
};

function updateStatus(data,id) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timeoffs/approve/${id}`, data , HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${id}`, HEADER_TOKEN).then(response => response)
};