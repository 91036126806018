import {
  SET_TIMEOFF_LOADING,
  SET_TIMEOFF,
  ADD_TIMEOFF,
  EDIT_TIMEOFF,
  DELETE_TIMEOFF,
  EDIT_STATUS_TIMEOFF
} from "./constants";
import { alert, invalidToken } from '../../../../utilities';
import { timeOffServices } from './services';
import { fetchSidebarCountData } from "../../../../layout/SchedulerLayout"

export function fetchData(lid, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_TIMEOFF_LOADING,
        payload: true
      });
    }

    timeOffServices.fetchData(lid)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_TIMEOFF,
            payload: response.data
          });
        }
      });
  };
}


export function deleteData(id) {
  return function (dispatch) {
    timeOffServices.deleteData(id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          fetchSidebarCountData()
          alert.success(response.data.message)
          dispatch({
            type: DELETE_TIMEOFF,
            payload: id
          });
        }
      });
  };
}


export function addData(data) {
  return function (dispatch) {
    timeOffServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else if(response.data.success === 0){
          alert.error(response.data.message)
        }
        else {
          fetchSidebarCountData()
          alert.success(response.data.message)
          dispatch({
            type: ADD_TIMEOFF,
            payload: response.data.addedData
          });
        }
      });
  };
}

export function updateStatus(data,id) {
  return function (dispatch) {
    timeOffServices.updateStatus(data,id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_STATUS_TIMEOFF,
            //payload: response.data.post
          });
        }
      });
  };
}


export function editData(data,id) {
  return function (dispatch) {
    timeOffServices.editData(data,id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          // dispatch({
          //   type: EDIT_TIMEOFF,
          //   payload: response.data.post
          // });
        }
      });
  };
}