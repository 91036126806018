import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import {
  alert,
  localTimeToUtc,
  utcDateToLocal,
  utcToLocal,
} from "../../../../utilities";
import axios from "axios";
import moment from "moment";
export const timesheetServices = {
  fetchData,
  getTimeCardSettings,
  addTimecard,
  editTimecard,
  viewTimecard,
  approveRevisedHours,
  approveBreaks,
  deleteTimecard,
  toggleTimesheetIssue,
  fetchPayrollTimecode,
  approveWholetimecard,
};

function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet?`;
  if (data.lid) {
    url = `${url}lid=${data.lid}`;
  }
  if (data.payPeriod) {
    url = `${url}&payPeriod=${data.payPeriod}`;
  }
  if (data.filter_sort_by) {
    url = `${url}&filter_sort_by=${data.filter_sort_by}`;
  }
  if (data.filter_group_by) {
    url = `${url}&filter_group_by=${data.filter_group_by}`;
  }
  if (data.filter_employee_uid) {
    url = `${url}&filter_employee_uid=${data.filter_employee_uid}`;
  }
  if (data.isReset) {
    url = `${url}&isReset=${data.isReset}`;
  }
  if (data.status_is_approved) {
    url = `${url}&status_is_approved=${data.status_is_approved}`;
  }
  if (data.startDate) {
    url = `${url}&startDate=${data.startDate}`;
  }
  if (data.endDate) {
    url = `${url}&endDate=${data.endDate}`;
  }
  if (data.Hide_Employees_With_No_Hours !== undefined) {
    url = `${url}&Hide_Employees_With_No_Hours=${data.Hide_Employees_With_No_Hours}`;
  }
  if (data.Hide_Employees_With_No_Timecard !== undefined) {
    url = `${url}&Hide_Employees_With_No_Timecard=${data.Hide_Employees_With_No_Timecard}`;
  }
  if (data.Display_Edited_Timecard_Only !== undefined) {
    url = `${url}&Display_Edited_Timecard_Only=${data.Display_Edited_Timecard_Only}`;
  }
  if (data.Display_Unscheduled_Shifts_Only !== undefined) {
    url = `${url}&Display_Unscheduled_Shifts_Only=${data.Display_Unscheduled_Shifts_Only}`;
  }
  if (data.Display_Scheduled_Shifts_Only !== undefined) {
    url = `${url}&Display_Scheduled_Shifts_Only=${data.Display_Scheduled_Shifts_Only}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function getTimeCardSettings(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/settings/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function toggleTimesheetIssue(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/timesheet/toogle_issue`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addTimecard(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/timecard`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveWholetimecard(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/approve`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editTimecard(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/timecard/${data?.job_id}/${data?.currentDate}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveRevisedHours(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/individual/approve`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveBreaks(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/timecard/approve/breaks`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function viewTimecard(myItem) {
  var date = moment(utcToLocal(myItem.currentDate)).format("YYYY-MM-DD");
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/timecard/${myItem.job_id}/${date}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchPayrollTimecode() {
  return axios
    .get(`${MoNGO_DB_API_URL}/payroll_timecode`, HEADER_TOKEN)
    .then((response) => response);
}

function deleteTimecard(value) {
  var data = {
    job_id: value.job_id,
    currentDate: value.currentDate,
  };
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/timesheet/delete/${data.job_id}/${data.currentDate}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
