import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  MyLeftModal,
} from "../../../components";
import { connect } from "react-redux";
import moment from "moment";
import { alert } from "../../../utilities";
import { schedulerColor } from "../../../config";
import { MyAvailabilityService } from "./modules/services";
import AddMyAvailability from "./AddMyAvailability";

import { Grid } from "@material-ui/core";
import CollapsedMyAvailability from "./CollapsedMyAvailability";
import AddEditMYAV from "./AddEditMYAV";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  TableCell: {
    padding: "15px",
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#ffffff",
    fontSize: "16px",
    lineHeight: "27px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: "1 1 100%",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#1E2134",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  userName: {
    maxWidth: "250px",
    // lineHeight: 3,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    lineHeight: "22.88px",
    fontWeight: 500,
    color: "#1E2134",
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "35px",
    width: "35px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  button: {
    color: "#ffffff",
    padding: "8px 22px",
    width: "150px",
    fontWeight: "400",
  },
});

export function fetchNewMyAvailabilityData() {
  window.MyAvailability.fetchData();
}

class MyAvailability extends Component {
  constructor(props) {
    super(props);
    window.MyAvailability = this;
    this.state = {
      myItem: {
        is_wholeday: 0,
        is_unavailable: 0,
        is_preferred: 0,
        unavailable_start_time: new Date(),
        unavailable_end_time: new Date(),
        prefered_start_time: new Date(),
        prefered_end_time: new Date(),
      },
      order: "asc",
      orderBy: "id",
      timeArray: [],
      isChecked: false,
      nextDayUn: false,
      nextDayPref: false,
      errors: {},
      errorsHelper: {},
      errorMessage: "",
    };
  }

  componentDidMount = () => {
    if (this.props.permissionsData?.scheduler_availability) {
      this.fetchData();
      var timeArray = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          var id = moment({ hour, minute }).format();
          id = new Date(id);
          id = id.toLocaleString("en-US", {
            timeZone: this.props.timezone[0]?.location_timezone,
          });
          var name = moment(id).format("hh:mm A");
          timeArray.push({ id, name });
        }
      }
      this.setState({ timeArray });
    }
  };

  fetchData = () => {
    // var data = {
    //   lid: this.props.scheduler_lid,
    // };
    var date = moment(this.state.effective_date).format("YYYY-MM-DD");
    this.setState({ isLoading: true });
    MyAvailabilityService.fetchMyAvailabilityData().then((response) => {
      if (response.data.success === 2) {
      } else {
        this.setState({
          getApiResponse: response.data,
          isLoading: false,
        });
      }
    });
  };

  handlePopupModal = (item, index, loc, value) => {
    const convertToTimezone = (date, timezone) => {
      if (!date) {
        return new Date().toLocaleString(); // Use system time without timezone conversion if date is null
      }
      return new Date(date).toLocaleString("en-US", {
        timeZone: timezone,
      });
    };

    const timezone = this.props.timezone[0]?.location_timezone; // Assuming the timezone is stored like this

    if (value === "edit") {
      const myItem = {
        ...item,
        unavailable_start_time: convertToTimezone(
          item.unavailable_start_time,
          timezone
        ),
        unavailable_end_time: convertToTimezone(
          item.unavailable_end_time,
          timezone
        ),
        prefered_start_time: convertToTimezone(
          item.prefered_start_time,
          timezone
        ),
        prefered_end_time: convertToTimezone(item.prefered_end_time, timezone),
      };

      this.setState(
        {
          addOrEdit: value,
          openModal: true,
          errors: "",
          errorsHelper: "",
          myItem,
          currentTitleLoc: loc,
        },
        () => {
          const {
            prefered_start_time,
            prefered_end_time,
            unavailable_start_time,
            unavailable_end_time,
          } = this.state.myItem;

          if (prefered_start_time && prefered_end_time) {
            const startDate = new Date(prefered_start_time);
            const endDate = new Date(prefered_end_time);

            if (endDate < startDate) {
              const nextDay = new Date(startDate);
              nextDay.setDate(nextDay.getDate() + 1);

              this.setState({ nextDayPref: true });
            } else {
              this.setState({ nextDayPref: false });
            }
          }

          if (unavailable_start_time && unavailable_end_time) {
            const startDate = new Date(unavailable_start_time);
            const endDate = new Date(unavailable_end_time);

            if (endDate < startDate) {
              const nextDay = new Date(startDate);
              nextDay.setDate(nextDay.getDate() + 1);

              this.setState({ nextDayUn: true });
            } else {
              this.setState({ nextDayUn: false });
            }
          }
        }
      );
    } else {
      const now = new Date();
      const myItem = {
        ...item,
        prefered_start_time: convertToTimezone(now, timezone),
        prefered_end_time: convertToTimezone(now, timezone),
        unavailable_start_time: convertToTimezone(now, timezone),
        unavailable_end_time: convertToTimezone(now, timezone),
      };

      this.setState({
        addOrEdit: value,
        openModal: true,
        errors: "",
        errorsHelper: "",
        myItem,
        nextDayUn: false,
        nextDayPref: false,
        currentTitleLoc: loc,
      });
    }
  };

  handlePopupModalClose = () => {
    this.setState({
      openModal: false,
    });
  };

  publishEffectiveDate = (av_id, items) => {
    this.setState({ isLoading: true });
    var data = {
      av_id: av_id,
      // user_notes: "",
      // lid: items.lid,
      // uid: items.uid,
      is_publish: 1,
    };
    MyAvailabilityService.publishEffectiveDate(data).then((response) => {
      if (response.data.success === 2) {
        alert.success(response.data.message);
      } else if (response.data.success === 0) {
        this.setState({ isLoading: false });
        alert.error(response.data.message);
      } else {
        alert.success(response.data.message);
        this.fetchData();
      }
    });

    // this.setState({
    //   isLoading: false
    // })
  };

  handleSubmit = async () => {
    var myItem = this.state.myItem;
    const curstTIME = moment(myItem.start_time).format("HH:mm:ss");
    const curendTIME = moment(myItem.end_time).format("HH:mm:ss");
    const prefferedStart = myItem.prefered_start_time;
    const prefferedEnd = myItem.prefered_end_time;
    const unavStart = myItem.unavailable_start_time;
    const unavEnd = myItem.unavailable_end_time;

    // Reset errors
    this.setState({ errors: {}, errorsHelper: {}, isLoading: true });

    // Validation for unavailable time
    if (
      (myItem.unavailable_start_time !== null ||
        myItem.unavailable_end_time !== null) &&
      myItem.is_wholeday === 0 &&
      myItem.is_unavailable === 1
    ) {
      var startbreakdate = moment(myItem.unavailable_start_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      var endbreakdate = moment(myItem.unavailable_end_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      if (startbreakdate >= endbreakdate) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            unavailable_end_time: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            unavailable_end_time: "End time must be greater than start time",
          },
          isLoading: false,
        }));
        return;
      }
    }

    // Validation for preferred time
    if (
      (myItem.prefered_start_time !== null ||
        myItem.prefered_end_time !== null) &&
      myItem.is_wholeday === 0 &&
      myItem.is_preferred === 1
    ) {
      var startbreakdate = moment(myItem.prefered_start_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      var endbreakdate = moment(myItem.prefered_end_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      if (startbreakdate >= endbreakdate) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            prefered_end_time: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            prefered_end_time: "End time must be greater than start time",
          },
          isLoading: false,
        }));
        return;
      }
    }

    // Check for overlapping times
    if (
      myItem.unavailable_start_time !== null &&
      myItem.unavailable_end_time !== null &&
      myItem.prefered_start_time !== null &&
      myItem.prefered_end_time !== null &&
      myItem.is_preferred === 1 &&
      myItem.is_unavailable === 1 &&
      myItem.is_wholeday === 0
    ) {
      var startbreakdate = new Date(moment(myItem.unavailable_start_time));
      var endbreakdate = new Date(moment(myItem.unavailable_end_time));
      var startbreakdatePref = new Date(moment(myItem.prefered_start_time));
      var endbreakdatePref = new Date(moment(myItem.prefered_end_time));

      if (
        this.isConflicting(
          startbreakdate,
          endbreakdate,
          startbreakdatePref,
          endbreakdatePref
        )
      ) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            prefered_end_time: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            prefered_end_time:
              "Preferred and Unavailable time should not be the same or overlap each other",
          },
          isLoading: false,
        }));
        return;
      }
    }

    // Continue with submission if no validation errors
    var dataWholeDay = {
      _id: myItem._id,
      av_id: myItem.av_id,
      lid: myItem.lid,
      is_unavailable: myItem?.is_unavailable,
      is_preferred: myItem?.is_preferred,
      is_publish: 0,
      is_wholeday: myItem.is_wholeday,
      day_id: myItem.day_id,
      prefered_start_time: null,
      prefered_end_time: null,
      unavailable_start_time: null,
      unavailable_end_time: null,
    };

    const data = {
      _id: myItem._id,
      av_id: myItem.av_id,
      lid: myItem.lid,
      is_publish: 0,
      is_wholeday: myItem.is_wholeday,
      day_id: myItem.day_id,
      is_unavailable: myItem?.is_unavailable,
      is_preferred: myItem?.is_preferred,
      prefered_start_time: myItem.is_preferred
        ? moment(prefferedStart).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      prefered_end_time: myItem.is_preferred
        ? moment(prefferedEnd).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      unavailable_start_time: myItem.is_unavailable
        ? moment(unavStart).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      unavailable_end_time: myItem.is_unavailable
        ? moment(unavEnd).format("YYYY-MM-DDTHH:mm:ss")
        : null,
    };

    MyAvailabilityService.editAvailability(
      myItem.is_wholeday ? dataWholeDay : data
    ).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else if (response.data.success === 0) {
        this.setState({
          isLoading: false,
        });
        this.handlePopupModalClose();
        alert.error(response.data.message);
      } else {
        this.fetchData();
        this.handlePopupModalClose();
        alert.success(response.data.message);
      }
    });

    this.setState({
      isLoading: false,
    });
  };

  isConflicting = (
    unavailableStart,
    unavailableEnd,
    preferredStart,
    preferredEnd
  ) => {
    return (
      (preferredStart > unavailableStart && preferredStart < unavailableEnd) ||
      (preferredEnd > unavailableStart && preferredEnd < unavailableEnd) ||
      (unavailableStart > preferredStart && unavailableStart < preferredEnd) ||
      (unavailableEnd > preferredStart && unavailableEnd < preferredEnd)
    );
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleChangeWholeDay = () => {
    var is_wholeday = this.state.myItem.is_wholeday;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        is_wholeday: is_wholeday ? 0 : 1,
        is_unavailable: is_wholeday ? 0 : 1,
      },
    }));
  };

  handleChangePrefered = (e) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [e.target.name]: e.target.checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeStartTimePrefered = (date) => {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      console.error("Invalid date provided");
      return;
    }

    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          prefered_start_time: date.$d,
        },
        errorMessage: "", // Clear any previous errors
      }),
      () => {
        const {
          prefered_start_time,
          prefered_end_time,
          unavailable_start_time,
          unavailable_end_time,
        } = this.state.myItem;

        const startDate = new Date(prefered_start_time);

        if (unavailable_start_time && unavailable_end_time) {
          const unavailableStart = new Date(unavailable_start_time);
          const unavailableEnd = new Date(unavailable_end_time);

          if (startDate >= unavailableStart && startDate <= unavailableEnd) {
            this.setState({
              errorMessage:
                "Preferred start time cannot be within unavailable time.",
            });
            return;
          }
        }

        if (prefered_start_time !== null && prefered_end_time !== null) {
          const endDate = new Date(prefered_end_time);
          if (endDate < startDate) {
            const nextDay = new Date(startDate);
            nextDay.setDate(nextDay.getDate() + 1);

            this.setState({ nextDayPref: true });
          } else {
            this.setState({ nextDayPref: false });
          }
        }
      }
    );
  };

  handleChangeEndTimePrefered = (date) => {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      console.error("Invalid date provided");
      return;
    }

    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          prefered_end_time: date.$d,
        },
        errorMessage: "", // Clear any previous errors
      }),
      () => {
        const {
          prefered_start_time,
          prefered_end_time,
          unavailable_start_time,
          unavailable_end_time,
        } = this.state.myItem;

        const endDate = new Date(prefered_end_time);

        if (unavailable_start_time && unavailable_end_time) {
          const unavailableStart = new Date(unavailable_start_time);
          const unavailableEnd = new Date(unavailable_end_time);

          if (endDate >= unavailableStart && endDate <= unavailableEnd) {
            this.setState({
              errorMessage:
                "Preferred end time cannot be within unavailable time.",
            });
            return;
          }
        }

        if (prefered_start_time !== null && prefered_end_time !== null) {
          const startDate = new Date(prefered_start_time);
          if (endDate < startDate) {
            const nextDay = new Date(startDate);
            nextDay.setDate(nextDay.getDate() + 1);

            this.setState({ nextDayPref: true });
          } else {
            this.setState({ nextDayPref: false });
          }
        }
      }
    );
  };

  handleChangeStartTimeUnavailable = (date) => {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      console.error("Invalid date provided");
      return;
    }

    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          unavailable_start_time: date.$d,
        },
      }),
      () => {
        const { unavailable_start_time, unavailable_end_time } =
          this.state.myItem;

        if (unavailable_start_time !== null && unavailable_end_time !== null) {
          const startDate = new Date(unavailable_start_time);
          const endDate = new Date(unavailable_end_time);

          if (endDate < startDate) {
            const nextDay = new Date(startDate);
            nextDay.setDate(nextDay.getDate() + 1);

            this.setState({
              nextDayUn: true,
            });
          } else {
            this.setState({
              nextDayUn: false,
            });
          }
        }
      }
    );
  };

  handleChangeEndTimeUnavailable = (date) => {
    if (!date || !moment(date).isValid()) {
      console.error("Invalid date provided");
      return;
    }

    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          unavailable_end_time: date.$d, // Use ISO string for consistency
        },
      }),
      () => {
        const { unavailable_start_time, unavailable_end_time } =
          this.state.myItem;

        if (unavailable_start_time !== null && unavailable_end_time !== null) {
          const startDate = new Date(unavailable_start_time);
          const endDate = new Date(unavailable_end_time);

          if (endDate < startDate) {
            const nextDay = new Date(startDate);
            nextDay.setDate(nextDay.getDate() + 1);

            this.setState({
              nextDayUn: true,
            });
          } else {
            this.setState({
              nextDayUn: false,
            });
          }
        }
      }
    );
  };

  // handleChangeStartTimeUnavailable = (date) => {
  //     this.setState(prevState => ({
  //         myItem: {
  //             ...prevState.myItem,
  //             unavailable_start_time: date.$d
  //         },
  //     }), () => {
  //         if (this.state.myItem.unavailable_start_time !== null || this.state.myItem.unavailable_end_time !== null) {
  //         if (new Date(this.state.myItem.unavailable_end_time) < new Date(this.state.myItem.unavailable_start_time)) {
  //             var date = new Date(moment(date.$d)).format("YYYY-MM-DD");
  //             date.setDate(date.getDate() + 1)
  //             // var endTimee = moment(date).format("YYYY-MM-DD") + "T" + moment(endTimeSelected).format("HH:mm:ss")
  //             // var startTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.startTimeSelected).format("HH:mm:ss")
  //             this.setState({
  //               nextDay: true
  //             })
  //           } else {
  //             // var endTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.endTimeSelected).format("HH:mm:ss")
  //             // var startTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.startTimeSelected).format("HH:mm:ss")
  //             this.setState({
  //               nextDay: false
  //             })
  //           }
  //         }
  //         // if (this.state.myItem.unavailable_start_time !== null || this.state.myItem.unavailable_end_time !== null) {
  //         //     var startbreakdate = moment(moment(this.state.myItem.unavailable_start_time)).format("YYYY-MM-DD HH:mm:ss");
  //         //     var endbreakdate = moment(moment(this.state.myItem.unavailable_end_time)).format("YYYY-MM-DD HH:mm:ss");
  //         //     if (startbreakdate >= endbreakdate) {
  //         //         this.setState(prevState => ({
  //         //             errors: {
  //         //                 ...prevState.errors,
  //         //                 unavailable_end_time: true
  //         //             },
  //         //             errorsHelper: {
  //         //                 ...prevState.errorsHelper,
  //         //                 unavailable_end_time: "End  time must be greater than start time"
  //         //             },
  //         //         }))
  //         //     } else {
  //         //         this.setState(prevState => ({
  //         //             errors: {
  //         //                 ...prevState.errors,
  //         //                 unavailable_end_time: false
  //         //             },
  //         //         }))
  //         //     }
  //         // }
  //     })
  // }

  // handleChangeEndTimeUnavailable = (date) => {
  //     this.setState(prevState => ({
  //         myItem: {
  //             ...prevState.myItem,
  //             unavailable_end_time: date.$d
  //         },
  //     }), () => {
  //         if (this.state.myItem.unavailable_start_time !== null || this.state.myItem.unavailable_end_time !== null) {
  //         if (new Date(this.state.myItem.unavailable_end_time) < new Date(this.state.myItem.unavailable_start_time)) {
  //             var date = new Date(moment(date.$d)).format("YYYY-MM-DD");
  //             date.setDate(date.getDate() + 1)
  //             // var endTimee = moment(date).format("YYYY-MM-DD") + "T" + moment(endTimeSelected).format("HH:mm:ss")
  //             // var startTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.startTimeSelected).format("HH:mm:ss")
  //             this.setState({
  //               nextDay: true
  //             })
  //           } else {
  //             // var endTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.endTimeSelected).format("HH:mm:ss")
  //             // var startTimee = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(this.state.startTimeSelected).format("HH:mm:ss")
  //             this.setState({
  //               nextDay: false
  //             })
  //           }
  //         }
  //         // if (this.state.myItem.unavailable_start_time !== null || this.state.myItem.unavailable_end_time !== null) {
  //         //     var startbreakdate = moment(moment(this.state.myItem.unavailable_start_time)).format("YYYY-MM-DD HH:mm:ss");
  //         //     var endbreakdate = moment(moment(this.state.myItem.unavailable_end_time)).format("YYYY-MM-DD HH:mm:ss");
  //         //     if (startbreakdate >= endbreakdate) {
  //         //         this.setState(prevState => ({
  //         //             errors: {
  //         //                 ...prevState.errors,
  //         //                 unavailable_end_time: true
  //         //             },
  //         //             errorsHelper: {
  //         //                 ...prevState.errorsHelper,
  //         //                 unavailable_end_time: "End  time must be greater than start time"
  //         //             },
  //         //         }))
  //         //     } else {
  //         //         this.setState(prevState => ({
  //         //             errors: {
  //         //                 ...prevState.errors,
  //         //                 unavailable_end_time: false
  //         //             },
  //         //         }))
  //         //     }
  //         // }
  //     })
  // }

  handleClickToggle = (open, index) => {
    const newData = this.state.getApiResponse?.my_avail.map(
      (items, newIndex) => {
        items.effective_dates &&
          items.effective_dates.map((gg) => {
            const ddd = gg;
            ddd["openMYInside"] = false;
            return ddd;
          });
        const cloneItem = items;
        if (index === newIndex) {
          cloneItem["open"] = open ? false : true;
        } else {
          cloneItem["open"] = false;
        }
        return cloneItem;
      }
    );
    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        my_avail: newData,
      },
    }));
  };

  handleClickToggleMYInside = (open, index, id, av_id) => {
    var newData = [];
    this.state.getApiResponse?.my_avail.map((bb) => {
      if (bb.effective_dates) {
        bb.effective_dates.map((ff) => {
          newData.push(ff);
        });
      }
    });
    var tempDate = newData;
    tempDate.map((inner) => {
      if (inner.av_id === av_id) {
        inner["openMYInside"] = open ? false : true;
      } else {
        inner["openMYInside"] = false;
      }
    });
    newData["data"] = tempDate;
    var myAvaData = this.state.getApiResponse?.my_avail.map((myITems) =>
      myITems.lid === newData.lid ? { ...newData } : myITems
    );

    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        my_avail: myAvaData,
      },
    }));
  };

  handleEffectiveDate = (date, e) => {
    this.setState({
      effective_date: date,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      isChecked: e.target.checked ? 1 : 0,
    });
  };

  newAvailability = () => {
    var effective_date = moment(this.state.effective_date).format("YYYY-MM-DD");
    var data = {
      effective_start_date: effective_date,
      user_notes: "",
      avail_status: 0,
      lid: this.state.userValue.lid,
      uid: this.state.userValue.uid,
    };

    MyAvailabilityService.addMyAvailability(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        this.newModalClose();
        this.fetchData();
      }
    });
  };

  newModalOpen = (userValue) => {
    this.setState({
      newModal: true,
      isChecked: false,
      userValue: userValue,
      effective_date: moment().format("YYYY-MM-DD"),
    });
  };

  newModalClose = () => {
    this.setState({
      newModal: false,
      userValue: "",
    });
  };

  render() {
    const { classes, pageNo, permissionsData, rowsPerPage } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return <LoadingData />;
    return permissionsData.scheduler_availability ? (
      <Grid container spacing={4} className={classes.root}>
        <CollapsedMyAvailability
          handleClickToggle={this.handleClickToggle}
          permissionsData={permissionsData}
          handleClickToggleMYInside={this.handleClickToggleMYInside}
          getApiResponse={this.state.getApiResponse}
          timezone={this.props.timezone}
          newModalOpen={this.newModalOpen}
          publishEffectiveDate={this.publishEffectiveDate}
          isLoading={this.state.isLoading}
          handlePopupModal={this.handlePopupModal}
        />

        <AddMyAvailability
          isChecked={this.state.isChecked}
          handleEffectiveDate={this.handleEffectiveDate}
          effective_date={this.state.effective_date}
          handleCheckBox={this.handleCheckBox}
          dropdownData={this.state.dropdownData}
          newAvailability={this.newAvailability}
          newModal={this.state.newModal}
          newModalClose={this.newModalClose}
        />

        <MyLeftModal
          bgColor={schedulerColor.main}
          // userName={this.props.ovUserName}
          // userImage={this.props.ovUserImage}
          title={this.state.currentTitleLoc}
          open={this.state.openModal}
          onClose={this.handlePopupModalClose}
          handleSubmit={this.handleSubmit}
        >
          <AddEditMYAV
            timeArray={this.state.timeArray}
            errors={this.state.errors}
            timezone={this.props.timezone}
            errorsHelper={this.state.errorsHelper}
            myItem={this.state.myItem}
            nextDayUn={this.state.nextDayUn}
            nextDayPref={this.state.nextDayPref}
            prefferedTimeErrorMessage={this.state.errorMessage}
            // locationData={locationData}
            handleChangePrefered={this.handleChangePrefered}
            handleChangeWholeDay={this.handleChangeWholeDay}
            handleChange={this.handleChange}
            handleFocus={this.handleFocus}
            handleChangeStartTimePrefered={this.handleChangeStartTimePrefered}
            handleChangeEndTimePrefered={this.handleChangeEndTimePrefered}
            handleChangeStartTimeUnavailable={
              this.handleChangeStartTimeUnavailable
            }
            handleChangeEndTimeUnavailable={this.handleChangeEndTimeUnavailable}
          />
        </MyLeftModal>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerTradeShift.isLoading,
  mytradeData: state.schedulerTradeShift.mytradeData,
});

const ConnectWith = connect(mapStateToProps, null)(MyAvailability);
export default withStyles(styles)(ConnectWith);
