import {
  SET_TIMESHEET_LOADING,
  SET_TIMESHEET,
  SET_PAYROLL_TIMECODE,
  GET_TIMECARD_SETTINGS,
  GET_TIMECARD_SETTINGS_LOADING,
} from "./constants";
import { invalidToken } from "../../../../utilities";
import { timesheetServices } from "./services";
import { history } from "../../../../history";

export function fetchData(data, isReload) {
  return function (dispatch) {
    dispatch({
      type: SET_TIMESHEET_LOADING,
      payload: true,
    });

    timesheetServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        if (data.isReset === 1) {
          history.push("/scheduler/timesheets");
        }
        dispatch({
          type: SET_TIMESHEET,
          payload: response.data?.result,
        });
      }
    });
  };
}

export function getTimeCardSettings(lid) {
  return function (dispatch) {
    dispatch({
      type: GET_TIMECARD_SETTINGS_LOADING,
      payload: true,
    });
    timesheetServices.getTimeCardSettings(lid).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_TIMECARD_SETTINGS,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchDataNew(data, isReload) {
  return function (dispatch) {
    dispatch({
      type: SET_TIMESHEET_LOADING,
      payload: true,
    });

    timesheetServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_TIMESHEET,
          payload: response.data?.result,
        });
      }
    });
  };
}

export function fetchPayrollTimecode() {
  return function (dispatch) {
    timesheetServices.fetchPayrollTimecode().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_PAYROLL_TIMECODE,
          payload: response.data,
        });
      }
    });
  };
}
