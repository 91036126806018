import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Confirmation,
  NoPermission,
  MyTextArea,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  DeleteModal,
  MySelectField,
  MoreMenu,
  MySearchBar,
  MyTextfield,
} from "../../../components";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import {
  fetchData,
  addData,
  editData,
  deleteData,
  updateStatus,
} from "./modules/actions";
import { connect } from "react-redux";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import moment from "moment";
import { localTimeToUtc, utcToLocal } from "../../../utilities";
import { utcToTimeZone } from "../../../utilities/utcToTimeZone";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
// import { MuiThemeProvider, muiTheme } from 'material-ui/styles';
import DateFnsUtils from "@date-io/date-fns";
import { alpha, styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { history } from "../../../history";
import "moment/locale/fr";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  TextField,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from "@material-ui/core";
import { FILE_URL, schedulerColor, settingColor } from "../../../config";
import { localToUtc, validationIsEmpty } from "../../../utilities";
import { scheduler } from "../../../icons";
import { DatePicker } from "@material-ui/pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  headCell: {
    padding: "0.3rem 0rem",
    color: "#aa076b",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "27px",
    fontSize: "18px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  subtitle: {
    marginBottom: "3px",
    color: "#7C7C7C",
  },

  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "35%",
    },
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  myTitle: {
    lineHeight: "2rem",
    // float: "left",
    color: "#ffffff",
    fontWeight: 600,
    textAlign: "center",
  },
  closeIcon: {
    cursor: "pointer",
    float: "right",
    color: "white",
  },
  userName: {
    // maxWidth: "250px",
    // lineHeight: 3,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    // WebkitBoxOrient: 'vertical',
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: "14px",
    color: "white",
  },
  closeButton: {
    textTransform: "capitalize",
    padding: "8px 22px",
    color: "white",
  },
  saveButton: {
    textTransform: "capitalize",
    color: "#ffffff",
    padding: "8px 22px",
    width: "150px",
  },
  dialogOptions: {
    padding: "5px 1px",
  },

  moreData: {
    "&:hover": {
      backgroundColor: "red",
    },
  },
  headTableRow: {
    width: "100%",
  },
  headTableCell: {
    width: "20%",
    minWidth: "150px",
  },
  TableCell: {
    width: "20%",
    minWidth: "150px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "5px 0 5px 0",
  },
});

export function fetchTimeOffRequestData() {
  window.TimeOffRequest.fetchData(true);
}

class TeamTimeOffRequest extends Component {
  constructor(props) {
    super(props);
    window.TimeOffRequest = this;
    this.state = {
      isApprove: 0,
      usersData: [],
      timeOffData: [],
      selectedValue: "all",
      searchUsers: "",
      page: 1,
      myItem: {},
      order: "desc",
      orderBy: "_id",
      headCells: [
        { id: "user_name", label: "Req detail", numeric: false },
        { id: "location_name", label: "Category", numeric: false },
        { id: "created_At", label: "Reason", numeric: false },
        { id: "requestedBy", label: "Approved details", numeric: false },
        { id: "user_status", label: "Status", numeric: false },
      ],
      errors: {},
      errorsHelper: {},
      moreData: [
        {
          icon: EditIcon,
          title: "Edit",
          sortName: "edit",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_timeoffrequests_edit,
        },
        {
          icon: DeleteIcon,
          title: "Delete",
          sortName: "delete",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_timeoffrequests_delete,
        },
        // {
        //     icon: CheckIcon,
        //     title: "Approve",
        //     sortName: "approve",
        //     onClick: this.modifyData,
        //     permission: props.permissionsData.scheduler_timeoffrequests_approve
        // },
        // {
        //     icon: CloseIcon,
        //     title: "Reject",
        //     sortName: "reject",
        //     onClick: this.modifyData,
        //     permission: props.permissionsData.scheduler_timeoffrequests_approve
        // }
      ],
      moreData2: [
        {
          icon: EditIcon,
          title: "Edit",
          sortName: "edit",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_timeoffrequests_edit,
        },
        {
          icon: DeleteIcon,
          title: "Delete",
          sortName: "delete",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_timeoffrequests_delete,
        },
      ],
    };
  }

  componentDidMount = () => {
    if (this.props.permissionsData?.scheduler_timeoff_request) {
      moment.tz.setDefault("");
      var isReload = this.props.timeOffData.length > 0 ? false : true;
      const queryParameters = new URLSearchParams(window.location.search);
      const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
      this.fetchData(isReload, value);
      if (this.props.timeOffData.length > 0) {
        this.setState({
          timeOffData: this.props.timeOffData,
          timeOffDataTemp: this.props.timeOffData,
          usersData: this.props.usersData,
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    moment.tz.setDefault("");
    if (
      JSON.stringify(prevProps.usersData) !==
      JSON.stringify(this.props.usersData)
    ) {
      this.setState({
        usersData: this.props.usersData,
      });
    }
    if (
      JSON.stringify(prevProps.timeOffData) !==
      JSON.stringify(this.props.timeOffData)
    ) {
      this.setState({
        timeOffData: this.props.timeOffData,
        timeOffDataTemp: this.props.timeOffData,
      });
    }
  }

  handleChangePage = (event, value) => {
    var url = `/scheduler/timeoffrequest/my?page=` + value;
    this.setState(
      {
        page: value,
      },
      () => {
        this.fetchData(true, value);
        history.push(url);
      }
    );
  };

  fetchData = (isReload, value) => {
    this.setState({
      page: value,
    });
    var data = {
      rowsPerPage: 10,
      pageNo: value,
      lid: this.props.scheduler_lid,
    };
    this.props.fetchData(data, isReload);
    this.setState({
      timeOffData: this.props.timeOffData,
    });
  };

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleSubmit = async () => {
    var userItem = this.state.myItem;
    var valid_uid = !userItem.uid ? true : false;
    var valid_category = !userItem.category ? true : false;
    var valid_reason = await validationIsEmpty(userItem.reason);
    var valid_wholeday = !userItem.wholeday ? true : false;
    if (valid_wholeday === true) {
      if (userItem.startTime === null) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            setDate: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            setDate: "Please select start time",
          },
        }));
      }

      if (userItem.endTime === null) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            setDateEnd: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            setDateEnd: "Please select end time",
          },
        }));
      }
      if (userItem.startTime === null || userItem.endTime === null) {
        return;
      }
    }

    if (valid_category) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          category: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          category: "Please select category",
        },
      }));
      return;
    }

    if (valid_reason) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          reason: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          reason: "Please enter reason",
        },
      }));

      return;
    }
    // if (valid_uid) {
    //     this.setState(prevState => ({
    //         errors: {
    //             ...prevState.errors,
    //             user_name: true
    //         },
    //         errorsHelper: {
    //             ...prevState.errorsHelper,
    //             user_name: "Please enter name"
    //         }
    //     }))
    // }

    if (this.state.myItem.wholeday) {
      if (userItem.wholeDayDate === null) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            wholeDayDate: true,
          },
          errorsHelper: {
            ...prevState.errorsHelper,
            wholeDayDate: "Please enter date",
          },
        }));

        return;
      }
    } else {
      if (userItem.startTime === null || userItem.endTime === null) {
        return;
      }
    }

    if ((valid_reason || valid_category) && valid_wholeday === true) {
      return;
    }

    if (!userItem.wholeday) {
      if (userItem.startTime !== null || userItem.endTime !== null) {
        var startbreakdate = moment(moment(userItem.startTime)).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        var endbreakdate = moment(moment(userItem.endTime)).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        if (startbreakdate >= endbreakdate) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              endTime: true,
            },
            errorsHelper: {
              ...prevState.errorsHelper,
              endTime: "End time must be greater than start time",
            },
          }));
          return;
        }
      }
    }
    if (userItem.wholeday) {
      let currentDateTime = moment();
      let wholeDayDate = moment(this.state.myItem.wholedayDate);
      let isToday = wholeDayDate.isSame(currentDateTime, "day");

      let adjustedStartTime;
      if (isToday) {
        // Set time to current time + 1 minute
        adjustedStartTime = currentDateTime.add(1, "minute");
      } else {
        // Keep the original date and time
        adjustedStartTime = wholeDayDate.startOf("day");
      }

      var data = {
        lid: this.state.myItem.lid,
        reason: this.state.myItem.reason,
        startTime: adjustedStartTime.format("YYYY-MM-DDTHH:mm:ss"), // Adjusted start time
        endTime: wholeDayDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"), // End of the same day
        category: this.state.myItem.category,
      };
    } else {
      var data = {
        lid: this.state.myItem.lid,
        //uid: this.state.myItem.uid,
        reason: this.state.myItem.reason,
        // wholeday: this.state.myItem.wholeday ? 1 : 0,
        startTime:
          moment(this.state.myItem.startTime).format("YYYY-MM-DD") +
          "T" +
          moment(this.state.myItem.startTime).format("HH:mm:ss"),
        // + this.props.timezone[0].location_offset => this offset pass on startime and endtime whenever you use just concat it
        endTime:
          moment(this.state.myItem.endTime).format("YYYY-MM-DD") +
          "T" +
          moment(this.state.myItem.endTime).format("HH:mm:ss"),
        //startTime: moment(this.state.myItem.startTime).utc().format('YYYY-MM-DD HH:mm:ssZ'),
        //endTime: moment(this.state.myItem.endTime).utc().format('YYYY-MM-DD HH:mm:ssZ'),
        category: this.state.myItem.category,
      };
    }

    // }

    this.setState({ addEditModal: false });
    if (this.state.isEdit) {
      //  data.id = this.state.myItem._id
      this.props.editData(data, this.state.myItem._id);
      this.fetchData(false, this.state.page);
    } else {
      this.props.addData(data);
      // this.fetchData(false, this.state.page)
    }
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    this.props.deleteData(this.state.myItem._id);
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  modifyData = (value, item) => {
    this.setState({
      isEdit: value === "edit" ? 1 : 0,
      isApprove: value === "approve" ? 1 : 2,
      confirmModal: value === "approve" || value === "reject" ? true : false,
      addEditModal: value === "add" || value === "edit" ? true : false,
      deleteModal: value === "delete" ? true : false,
    });
  };

  moreMenuClick = (item, value) => {
    // var startTiime = new Date(item ? item.startTime : "")
    // var endTiime = new Date(item ? item.endTime : "")
    // var startTime = startTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0]?.location_timezone });
    // var endTime = endTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0]?.location_timezone });
    // var strtTime =  moment(startTime).format("DD/MM/YYYY, hh:mm:ss A")
    let myItem = {
      lid: this.props.scheduler_lid,
      uid: this.props.ovUid,
      reason: "",
      wholeday: 0,
      startTime: new Date().toLocaleString("en-US", {
        timeZone: this.props.timezone[0]?.location_timezone,
      }),
      endTime: new Date().toLocaleString("en-US", {
        timeZone: this.props.timezone[0]?.location_timezone,
      }),
      category: 0,
    };
    if (item) {
      // var startTime = moment(utcToLocal(item.startTime)).format("YYYY-MM-DD HH:mm:ss")
      // var endTime = moment(utcToLocal(item.endTime)).format("YYYY-MM-DD HH:mm:ss")
      myItem = item;
      // myItem.startTime = startTime
      // myItem.endTime = endTime
    }
    this.setState({
      isEdit: 0,
      myItem,
      addEditModal: value === "add" ? true : false,
    });
  };

  toggleWholeDay = () => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        wholeday: !this.state.myItem.wholeday,
      },
    }));
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  filterTimeOffRequest = (e) => {
    var target = e.target.value;
    if (target === "all") {
      this.setState({ selectedValue: target }, () => {
        var updatedList = this.state.timeOffDataTemp;
        this.setState({
          timeOffData: updatedList,
        });
      });
    } else {
      this.setState({ selectedValue: target }, () => {
        var updatedList = this.state.timeOffDataTemp;
        updatedList = updatedList.filter(function (item) {
          return item.status === parseInt(target);
        });
        this.setState({
          timeOffData: updatedList,
        });
      });
    }
  };

  confirmModalClose = () => {
    this.setState({
      confirmModal: false,
    });
  };

  updateStatus = () => {
    var data = {
      status: this.state.isApprove,
    };
    this.setState({
      confirmModal: false,
    });
    this.props.updateStatus(data, this.state.myItem._id);
    this.fetchData(false, this.state.page);
  };

  handleChangeStartDate = (date) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        // startTime: moment(localTimeToUtc(date).format("MMMM d, yyyy h:mma")),
        startTime: date,
      },
    }));
  };

  handleChangeEndDate = (date, e) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        endTime: date,
      },
    }));
  };

  handleChangeWholeDayDate = (date, e) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        wholedayDate: date,
      },
    }));
  };

  handleSearch = (target) => {
    var updatedList = this.state.timeOffDataTemp;
    if (target) {
      updatedList = updatedList.filter(function (item) {
        return item.user_name.toLowerCase().search(target.toLowerCase()) !== -1;
      });
    }
    var timeOffData = updatedList.filter((item) => item.user_name);
    this.setState({
      searchUsers: target,
      timeOffData,
    });
  };

  clearSearch = () => {
    var timeOffData = this.state.timeOffDataTemp.filter(
      (item) => item.user_name
    );
    this.setState({
      searchUsers: "",
      timeOffData,
    });
  };

  render() {
    const {
      classes,
      isLoading,
      pageNo,
      rowsPerPage,
      timeOffCategory,
      permissionsData,
      timezone,
    } = this.props;
    const { timeOffData, usersData } = this.state;
    if (isLoading) return <LoadingData />;
    return permissionsData.scheduler_timeoff_request ? (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullScreen
            TransitionComponent={Transition}
            open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <DialogTitle
              style={{ backgroundImage: schedulerColor.main }}
              className={classes.DialogTitle}
            >
              {" "}
              <Typography className={classes.myTitle} variant="h4">
                {this.state.isEdit ? "Edit Time Off" : "Add Time Off"}
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogcontent}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {/* <Grid item xs={12}
                                            className={classes.dialogOptions}
                                        >
                                            <MySelectField
                                                label="Name"
                                                name="uid"
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                value={this.state.myItem.uid}
                                                error={this.state.errors.uid}
                                                helperText={this.state.errors.uid ? this.state.errorsHelper.uid : ""}
                                                placeholder="Please Select Name"
                                             error={this.state.errors.uid}
                                            >
                                                {usersData.map((item) => {
                                                    return (
                                                        <option key={item.uid} value={item.uid}>
                                                            {item.user_name}
                                                        </option>
                                                    )
                                                })}
                                                {usersData.map((user) => {
                                                    return (
                                                        <option key={user._id} value={user._id} >
                                                            {user.user_name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid> */}
                  <Grid item xs={12}>
                    {/* <MuiThemeProvider muiTheme={muiTheme}> */}
                    <FormGroup style={{ float: "right", marginRight: "0" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            className={classes.Switch}
                            checked={this.state.myItem.wholeday}
                            onChange={this.toggleWholeDay}
                          />
                        }
                        label="Whole Day"
                      />
                      {/* <FormControlLabel
                                                    control={
                                                        <BlueSwitch
                                                            // checked={data.status ? true : false}
                                                            className={classes.switchBase}
                                                            checked={this.state.myItem.wholeday}
                                                            onChange={this.toggleWholeDay}
                                                        />}
                                                    label='Whole Day'>
                                                </FormControlLabel> */}
                    </FormGroup>

                    {/* </MuiThemeProvider> */}
                  </Grid>
                  <Grid item xs={12} className={classes.dialogOptions}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        disabled={this.state.myItem.wholeday ? true : false}
                        // fullWidth
                        // size="small"
                        // variant="inline"
                        // inputVariant="outlined"
                        // disablePast={true}
                        // format="MMMM d, yyyy h:mma"
                        // name="startTime"
                        // label="Start"
                        // onChange={this.handleChangeStartDate}
                        // onFocus={this.handleFocus}
                        // value={this.state.myItem.startTime}
                        // error={this.state.errors.startTime}
                        // helperText={
                        //     this.state.errors.startTime
                        //         ? this.state.errorsHelper.startTime
                        //         : ""
                        // }
                        // placeholder="Select start-time"
                        key={`dateTime-${this.props.timezone[0]?.location_timezone}`}
                        fullWidth
                        size="small"
                        variant="inline"
                        inputVariant="outlined"
                        name="startTime"
                        label="Start"
                        className={classes.dateTimePicker}
                        format="MMMM d, yyyy h:mma"
                        onChange={this.handleChangeStartDate}
                        onFocus={this.handleFocus}
                        showTodayButton
                        value={this.state.myItem.startTime}
                        error={this.state.errors.startTime}
                        helperText={
                          this.state.errors.startTime
                            ? this.state.errorsHelper.startTime
                            : ""
                        }
                        placeholder="Select start-time"
                        // disabled="true"
                      ></KeyboardDateTimePicker>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} className={classes.dialogOptions}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        disabled={this.state.myItem.wholeday ? true : false}
                        // fullWidth
                        // size="small"
                        // variant="inline"
                        // inputVariant="outlined"
                        // disablePast={true}
                        // format="MMMM d, yyyy h:mma"
                        // name="endTime"
                        // label="End"
                        // onChange={this.handleChangeEndDate}
                        // onFocus={this.handleFocus}
                        // value={this.state.myItem.endTime}
                        // error={this.state.errors.endTime}
                        // helperText={
                        //     this.state.errors.endTime
                        //         ? this.state.errorsHelper.endTime
                        //         : ""
                        // }
                        // placeholder="Select end-time"
                        key={`dateTime-${this.props.timezone[0]?.location_timezone}`}
                        fullWidth
                        size="small"
                        variant="inline"
                        inputVariant="outlined"
                        name="startTime"
                        label="End"
                        className={classes.dateTimePicker}
                        value={this.state.myItem.endTime}
                        format="MMMM d, yyyy h:mma"
                        onChange={this.handleChangeEndDate}
                        onFocus={this.handleFocus}
                        error={this.state.errors.endTime}
                        helperText={
                          this.state.errors.endTime
                            ? this.state.errorsHelper.endTime
                            : ""
                        }
                        showTodayButton
                        placeholder="Select end-time"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {!!this.state.myItem.wholeday && (
                    <Grid item xs={12} className={classes.dialogOptions}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disabled={!this.state.myItem.wholeday}
                          key={`date-${this.props.timezone[0]?.location_timezone}`}
                          fullWidth
                          size="small"
                          variant="inline"
                          inputVariant="outlined"
                          name="Date"
                          label="Date"
                          className={classes.datePicker}
                          value={this.state.myItem.wholedayDate}
                          format="MMMM d, yyyy"
                          onChange={this.handleChangeWholeDayDate}
                          onFocus={this.handleFocus}
                          error={this.state.errors.wholedayDate}
                          helperText={
                            this.state.errors.wholedayDate
                              ? this.state.errorsHelper.wholedayDate
                              : ""
                          }
                          showTodayButton
                          placeholder="Select date"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  )}

                  <Grid item xs={12} className={classes.dialogOptions}>
                    <MySelectField
                      label="Category"
                      name="category"
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      value={this.state.myItem.category}
                      error={this.state.errors.category}
                      helperText={
                        this.state.errors.category
                          ? this.state.errorsHelper.category
                          : ""
                      }
                      placeholder="Please Select Category"
                    >
                      <option key={0} value={0}>
                        Select Category
                      </option>
                      {timeOffCategory.map((item, id) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </MySelectField>
                  </Grid>

                  <Grid item xs={12} className={classes.dialogOptions}>
                    <MyTextfield
                      name="reason"
                      label="Reason"
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      value={this.state.myItem.reason}
                      type="text"
                      error={this.state.errors.reason}
                      helperText={
                        this.state.errors.reason
                          ? this.state.errorsHelper.reason
                          : ""
                      }
                      placeholder="Enter reason"
                      rows={8}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={this.addEditModalClose}
                startIcon={<CloseIcon />}
                className={classes.closeButton}
                style={{ backgroundColor: "#7C7C7C" }}
              >
                Close
              </Button>

              <Button
                style={{ backgroundImage: schedulerColor.main }}
                variant="outlined"
                size="medium"
                className={classes.saveButton}
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Toolbar variant="regular" className={classes.rootToolbar}></Toolbar>
          <Paper>
            <Toolbar variant="regular" className={classes.rootToolbar}>
              <Grid container xs={12} style={{ padding: "0.8rem 0rem" }}>
                <Grid item xs={12} md={4} style={{ height: "40px" }}>
                  <Typography
                    display="inline"
                    className={classes.title}
                    style={{ marginLeft: "-1rem" }}
                    variant="h4"
                  >
                    <b
                      style={{
                        color: "#AA076B",
                        fontWeight: "500",
                        fontSize: "17px",
                        marginLeft: "15px",
                      }}
                    >
                      My Time Off Request
                    </b>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{ height: "40px" }}>
                  <TextField
                    autoFocus={false}
                    medium
                    select
                    SelectProps={{ native: true }}
                    style={{ width: "250px" }}
                    required={this.props.required ? this.props.required : false}
                    label="Time Off Request"
                    onChange={this.filterTimeOffRequest}
                    value={this.state.selectedValue}
                    variant="outlined"
                    size="small"
                  >
                    <option key={0} value="all">
                      All
                    </option>
                    <option key={1} value={1}>
                      Approved
                    </option>
                    <option key={2} value={2}>
                      Rejected
                    </option>
                  </TextField>

                  {/* <div style={{ width: "250px" }}> */}
                  {/* <MySearchBar
                                                clearSearch={this.clearSearch}
                                                handleSearch={this.handleSearch}
                                                value={this.state.searchUsers}
                                                placeholder="Search user"
                                            /> */}
                  {/* </div> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    height: "40px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {permissionsData.scheduler_timeoffrequests_add ? (
                    <Tooltip arrow title="Add">
                      <IconButton
                        style={{ float: "right" }}
                        size="small"
                        onClick={() => {
                          this.moreMenuClick(null, "add");
                        }}
                      >
                        <AddIcon
                          style={{
                            border: "3px",
                            borderRadius: "50%",
                            backgroundImage: schedulerColor.main,
                            color: "white",
                            fontWeight: "500",
                            height: "auto",
                            width: "20px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Toolbar>

            <TableContainer>
              <Table className={classes.table} size="medium">
                <TableHead
                  style={{ backgroundColor: "rgba(170, 7, 107, 0.125)" }}
                >
                  <TableRow className={classes.headTableRow}>
                    {this.state.headCells.map((headCell) => (
                      <TableCell
                        align={headCell.align}
                        key={headCell.id}
                        className={classes.headTableCell}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                      >
                        <TableSortLabel
                          className={classes.headCell}
                          active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={this.createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {this.state.orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {this.state.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(
                    timeOffData,
                    getComparator(this.state.order, this.state.orderBy)
                  )
                    .slice(
                      pageNo * rowsPerPage,
                      pageNo * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      var approved_datetemp = new Date(row.approved_date);
                      var approved_date = approved_datetemp.toLocaleString(
                        "en-US"
                        // {timeZone:this.props.timezone[0]?.location_timezone}
                      );
                      var startTiime = new Date(row.startTime);
                      var endTiime = new Date(row.endTime);
                      var startTime = startTiime.toLocaleString("en-US", {
                        timeZone: this.props.timezone[0]?.location_timezone,
                      });
                      var endTime = endTiime.toLocaleString("en-US", {
                        timeZone: this.props.timezone[0]?.location_timezone,
                      });
                      var startTimeold = new Date(row.approved_date);
                      var startTimeee = startTimeold.toLocaleString("en-US", {
                        timeZone: this.props.timezone[0]?.location_timezone,
                      });
                      var startTimeeeee = moment(startTimeee)
                        .locale("en-US")
                        .format("DD MMM YYYY, hh:mm A");
                      return (
                        <TableRow hover tabIndex={-1} key={row._id}>
                          <TableCell
                            style={{
                              verticalAlign: "baseline",
                            }}
                            className={classes.TableCell}
                          >
                            <Typography className={classes.userName}>
                              {moment(startTime)
                                .locale("en-US")
                                .format("DD MMM YYYY, hh:mm A")}{" "}
                              -
                              {moment(endTime)
                                .locale("en-US")
                                .format("DD MMM YYYY, hh:mm A")}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" className={classes.TableCell}>
                            <Typography className={classes.userName}>
                              {row.category_name
                                ? row.category_name
                                : row.categoryName}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" className={classes.TableCell}>
                            <Typography className={classes.userName}>
                              {row.reason}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" className={classes.TableCell}>
                            <Typography className={classes.userName}>
                              {row.approved_date
                                ? "By" + " " + row.approved_by + " " + "at"
                                : ""}
                              <br />
                              {row.approved_date
                                ? moment(approved_date)
                                    .locale("en-US")
                                    .format("DD MMM YYYY, hh:mm A")
                                : ""}
                            </Typography>
                          </TableCell>

                          <TableCell className={classes.TableCell}>
                            <Typography
                              className={classes.userName}
                              style={{
                                color:
                                  parseInt(row.status) === 1
                                    ? "#1B693F"
                                    : "#ADB005",
                              }}
                            >
                              {parseInt(row.status) === 1
                                ? "Approved"
                                : parseInt(row.status) === 0
                                ? "Pending"
                                : "Rejected"}
                            </Typography>
                          </TableCell>

                          {/* <TableCell colSpan={3} align="right" className={classes.TableCell}>
                                                            <MoreMenu
                                                                className={classes.moreData}
                                                                moreMenuClick={() => { this.moreMenuClick(row) }}
                                                                moreData={row.status ? this.state.moreData2 : this.state.moreData}
                                                            />
                                                        </TableCell> */}
                        </TableRow>
                      );
                    })}

                  {timeOffData && timeOffData.length < 1 ? (
                    <TableRecordNotFound colSpan={8} label="No Data Found.." />
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
              <Pagination
                color="primary"
                count={this.props.totalpage}
                variant="outlined"
                page={parseInt(this.state.page)}
                onChange={this.handleChangePage}
                className={classes.pagination}
              />
            </div>
          </Paper>

          <Confirmation
            bgColor={schedulerColor.main}
            desc={"Are you sure you want to update?"}
            open={this.state.confirmModal}
            onClose={this.confirmModalClose}
            handleSubmit={this.updateStatus}
          />

          <DeleteModal
            bgColor={schedulerColor.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerTimeOffRequest.isLoading,
  timeOffData: state.schedulerTimeOffRequest.mytimeOffData,
  timeOffCategory: state.schedulerTimeOffRequest.timeOffCategory,
  totalpage: state.schedulerTimeOffRequest.totalpage,
  usersData: state.schedulerTimeOffRequest.usersData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  addData,
  editData,
  deleteData,
  updateStatus,
})(TeamTimeOffRequest);
export default withStyles(styles)(ConnectWith);
