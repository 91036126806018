//ConfirmationModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  title: {
    textAlign: "center",
  },
  dialog: {
    borderRadius: 0,
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "2rem",
    borderRadius: 0,
    overflow: "hidden",
    "&:last-child": {
      borderRadius: 0,
      padding: "2rem",
    },
  },
  saveButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid green",
    padding: "10px 22px",
  },
  closeButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid gray",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmationModal extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogcontent} dividers>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography className={classes.title} variant="h3" gutterBottom>
                {this.props.desc}
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Button
                    onClick={this.props.onClose}
                    size="small"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "gray",
                      border: `1px solid ${this.props.bgColor}`,
                      color: this.props.bgColor,
                      borderRadius: "5px",
                    }}
                    className={classes.closeButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button
                    style={{
                      backgroundImage: this.props.bgColor
                        ? this.props.bgColor
                        : "gray",
                      backgroundColor: this.props.bgColor
                        ? this.props.bgColor
                        : "gray",
                      border: `1px solid ${this.props.bgColor}`,
                      borderRadius: "5px",
                    }}
                    onClick={this.props.handleSubmit}
                    size="small"
                    fullWidth
                    variant="contained"
                    className={classes.saveButton}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmationModal);
