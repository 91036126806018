import {  HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const scheduleServices = {
    fetchWeartherList,
    fetchData,
    deleteShifts,
    duplicateShifts,
    addEvents,
    editEvents,
    addShifts,
    setUsersPriority,
    pasteToWeek,
    editShifts,
    publishShifts,
    clearUnpublishShifts,
    clearEntireSchedule,
    inviteSchedulerUser,
    addData,
    editData,
    dragAndDropShift,
    updateToolsSettings,
    fetchUser,
    deleteShiftsEvent,
    updateUserPayroll,
    hideShowEmploye
}

function fetchWeartherList() {
    return axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=23.161386&lon=72.5444895&start=1612352464&end=1612870864&appid=0e353b9ffb7ccc7cb60aa2271c18586c`).then(response => response)
};

// function fetchData(data) {
//     // return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule`, { data }, HEADER_TOKEN).then(response => response)
//     return axios.post(`${MoNGO_DB_API_URL}/v5/web/scheduler/team/shifts`, { data }, HEADER_TOKEN).then(response => response)
// };

function fetchData( data) {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/team?`
    if(data.lid){
      url=`${url}&lid=${data.lid}`
    }
    if(data.date){
        url=`${url}&date=${data.date}`
      }
    return axios
      .get(
        url,
        HEADER_TOKEN
      )
      .then((response) => response);
}


function updateToolsSettings(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/settings/users/tools`,  data.toolbarSettings , HEADER_TOKEN).then(response => response)
};

function dragAndDropShift(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/drag_drop`, { data }, HEADER_TOKEN).then(response => response)
};

function pasteToWeek(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/copy`, { data }, HEADER_TOKEN).then(response => response)
};

function hideShowEmploye(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/settings/users/visibility`,  data , HEADER_TOKEN).then(response => response)
};

function setUsersPriority(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/priority`, { data }, HEADER_TOKEN).then(response => response)
};

function addShifts(data) {
    // return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule/utcshift`, { data }, HEADER_TOKEN).then(response => response)
    // return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule/shift`, { data }, HEADER_TOKEN).then(response => response)
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts`, { data }, HEADER_TOKEN).then(response => response)
};

function inviteSchedulerUser(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule/user`, { data }, HEADER_TOKEN).then(response => response)
};

function addEvents(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/events`, { data }, HEADER_TOKEN).then(response => response)
};

function duplicateShifts(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/duplicate`, { data }, HEADER_TOKEN).then(response => response)
};

function editEvents(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/events/${data._id}`, { data }, HEADER_TOKEN).then(response => response)
};

function editShifts(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/${data.job_id}`, { data }, HEADER_TOKEN).then(response => response)
};

function publishShifts(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/publish`, { data }, HEADER_TOKEN).then(response => response)
};

function clearUnpublishShifts(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shift/clear/unpublish`, { data }, HEADER_TOKEN).then(response => response)
};

function clearEntireSchedule(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shift/clear/entire`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteShifts(data) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/${data.job_id}`, HEADER_TOKEN).then(response => response)
};

function deleteShiftsEvent(data) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/events/${data.uid}`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts`, { data }, HEADER_TOKEN).then(response => response)
};


function editData(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/shifts/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function fetchUser(data){
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule/view/payrollcompany`, { data }, HEADER_TOKEN).then(response => response)
}

function updateUserPayroll(data){
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/schedule/update/payroll`, { data }, HEADER_TOKEN).then(response => response)
}

