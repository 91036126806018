import {
  SET_PAYROLL_SUMMARY_LOADING,
  SET_PAYROLL_SUMMARY,
  ADD_PAYROLL_SUMMARY,
  EDIT_PAYROLL_SUMMARY,
  DELETE_PAYROLL_SUMMARY,
  GET_SCHEDULE_SETTING_DATA,
  SET_PAYROLL_HEADER
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { payrollSummaryServices } from './services';

export function fetchData(data, isReload) {
  return function (dispatch) {
    payrollSummaryServices.fetchData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_PAYROLL_SUMMARY,
            payload: response.data
          });
        }
      });
  };
}

export function getScheduleData(lid) {
  return function (dispatch) {
    // dispatch({
    //   type: SCHEDULER_SETTINGS_LOADING,
    //   payload: true,
    // });
    payrollSummaryServices.getScheduleData(lid).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_SCHEDULE_SETTING_DATA,
          payload: response.data.result,
        });
      }
    });
  };
}


export function getTableAdData(data) {
  return function (dispatch) {
    payrollSummaryServices.getTableAdData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_PAYROLL_HEADER,
            payload: response.data
          });
        }
      });
  };
}

export function deleteData(cid) {
  return function (dispatch) {
    // dispatch({
    //   type: SET_COMPANY_LOADING,
    //   payload: true
    // });
    payrollSummaryServices.deleteData(cid)
      .then(response => {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_PAYROLL_SUMMARY,
          payload: cid
        });
      },
        error => {
          // dispatch({
          //   type: SET_COMPANY_LOADING,
          //   payload: false
          // });
        });
  };
};

export function addData(data) {
  return function (dispatch) {
    payrollSummaryServices.addData(data)
      .then(response => {
        alert.success(response.data.message)
        dispatch({
          type: ADD_PAYROLL_SUMMARY,
          payload: response.data.post
        });
      });
  };
};

export function editData(data) {
  return function (dispatch) {
    payrollSummaryServices.editData(data)
      .then(response => {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_PAYROLL_SUMMARY,
          payload: response.data.post
        });
      },
        error => {
          // dispatch({
          //   type: SET_COMPANY_LOADING,
          //   payload: false
          // });
        });
  };
};