import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  DeleteModal,
  MyDatePicker,
  MySelectField,
  MyMultiSelect,
  MyLeftModal,
  MyTextfield,
  MyAutocomplete,
} from "../../../components";
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import {
//   getScheduleData,
//   updateScheduleData,
// } from "../Settings/modules/actions";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
import { alert } from "../../../utilities";
import moment from "moment";
import { history } from "../../../history";
import { settingsServices } from "../Settings/modules/services";
import "../TeamAvailability/setting.css";
import dayjs from "dayjs";
import {
  CardContent,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  Collapse,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
// import TimePicker from 'rc-time-picker';
import "rc-time-picker/assets/index.css";
import "../TeamAvailability/news.css";
import "../TeamAvailability/tabelCss.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const schUID = cookies.get("ovUid");

const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      display: "none",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#858585",
    },
  },
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
    marginLeft: 10,
  },
  listCell1: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  listCell2: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "20%",
    },
  },
  listCell3: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell6: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell5: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  listCell8: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "20%",
    },
  },
  listCell9: {
    width: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },
  // listCell1: {
  //   width: "10.5rem",
  //   // padding:'8px 4px'
  // },
  // listCell2: {
  //   width: "6rem",
  // },
  // listCell3: {
  //   width: "6rem",
  //   // padding:'8px 0'
  // },
  // listCell4: {
  //   width: "6rem",
  // },
  // listCell5: {
  //   width: "5rem",
  //   // padding:'8px 0'
  // },
  // listCell6: {
  //   width: "6rem",
  //   // padding:'8px 0'
  // },
  // listCell7: {
  //   width: "8rem",
  //   // padding:'8px 0'
  // },
  // listCell8: {
  //   width: "6rem",
  // },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },
  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    background: "#FFFCFC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: " 16px 68px 0px 68px",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: "100%",
    borderRadius: "10px",
    cursor: "pointer",
    height:"100%"
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
  mainDivScale: {
    paddingTop: "1%",
    lineHeight: "1%",
    // border: "1px solid #F3F2F2",
    margin: "2%",
    width: "97%",
  },
  timeRang: {
    width: "100%",
    paddingLeft: "10%",
    marginTop: "-2%",
  },
  mainweek: {
    paddingLeft: "0%",
  },
  root: {
    padding: theme.spacing(2),
  },
  btnChild: {
    cursor: "pointer",
  },
});

// export function fetchSCSettingsScheduling() {
//   window.Scheduling.getScheduleData();
// }

var format = "h:mm a";
var setTimePreStartNew = dayjs("2022-04-07");
var setTimePreEndNew = dayjs("2022-04-07");
var setTimeNew = dayjs("2022-04-07");
var setTimeEndNew = dayjs("2022-04-07");
class Scheduling extends Component {
  constructor(props) {
    super(props);
    window.Scheduling = this;
    this.state = {
      disable: true,
      isWeek: false,
      getbusinessData: "",
      flageed: [
        { value: 1, label: "Flagged" },
        { value: 0, label: "Blocked" },
      ],
      applyToAll: 0,
      workschedule: false,
      workRestriction: false,
      Reminders: false,
      breakss: false,
      alertsAndNotification: false,
      emoji: 1,
      anchorEl: null,
      checked: true,
      open: false,
      openLeft: false,
      startDate: new Date(),
      scheduler_lid: cookies.get("scheduler_lid"),
      startTime: moment().hour(0).minute(0),
      timezone: "",
      openModalSch: false,
      setTime: setTimeNew,
      setTimeEnd: setTimeEndNew,
      setTimePreStart: setTimePreStartNew,
      // setTimePreStartNew: '',
      setTimePreEnd: setTimePreEndNew,
      valueStartTime: moment(),
      valueStartTimeNew: moment(),
      valueEndtime: moment(),
      valueEndTimeNew: moment(),
      settings: {},
      valueTab: "0",
      data: {},
      allBreaks: [],
      timeArray: [],
      isWholeChecked: 0,
      addBreaksData: [],
      myavailabilityData: [],
      usersData: [],
      location_timezone: "",
      newLocationState: "",
      dayState: "",
      deleterowId: "",
      break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      getApiResponse: [],
      isMustAknowledge_comment: false,
      BlockDropdownData: [
        { value: 2, label: "Block" },
        { value: 1, label: "Flag" },
      ],
      PaidDropdownData: [
        { value: 1, label: "Paid" },
        { value: 2, label: "Unpaid" },
      ],
      MandatoryDropdownData: [
        { value: 1, label: "Mandatory" },
        { value: 2, label: "Optional" },
      ],
      ScheduleDay: [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
      ],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
      ],

      dropdownData: [],
    };
  }
  componentDidMount = () => {
    // this.fetchBusinessData();
    // this.fetchData();
    // this.getScheduleData();
  };
  toggleDisable = () => {
    if (this.state.breakss === true) {
      this.setState((prevState) => ({
        disable: !prevState.disable,
      }));
    }
  };

  fetchChildDataByIdNew = (index, time) => {
    // var weekData = this.state.weeks
    // var data = {
    //     av_id: this.state.av_id,
    //     lid: this.props.scheduler_lid
    // }
    // myAvailabilityService.fetchDataByIdNew(data).then(response => {
    //     this.setState({
    //         getApiResponse: response.data
    //     })
    //     if (response.data.success === 2) {
    //         // invalidToken(response.data.message)
    //     } else {
    //         if (response.data.eventsData.length > 0) {
    //             let temp = [...this.state.weeks]
    //             for (var i = 0; i < response.data.eventsData.length; i++) {
    //                 temp = temp.map((el, index1) => {
    //                     if (response.data.eventsData[i].day_id == el.id) {
    //                         return { ...el, value: { start: response.data.eventsData[i].start_time, end: response.data.eventsData[i].end_time } }
    //                     }
    //                     else {
    //                         return { ...el }
    //                     }
    //                 }
    //                 )
    //             }
    //             this.setState({
    //                 weeks: temp
    //             })
    //         }
    //         else {
    //             this.setState({
    //                 weeks: this.state.weeksOrignal
    //             })
    //         }
    //     }
    //     this.setState({
    //         eventsData: response.data.eventsData,
    //         isLoading: false
    //     })
    // });
  };

  // handleChangeMainAvailability = (e) => {
  //   this.setState(
  //     {
  //       isLoading: true,
  //       av_id: e.target.value,
  //     },
  //     () => {
  //       history.push(`/scheduler/myavailabilitynew?av_id=${this.state.av_id}`);
  //       this.fetchChildDataByIdNew();
  //     }
  //   );
  // };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleViewModalOpen = () => {
    this.setState({
      viewModalOpen: true,
    });
  };

  // showPreviewBreak = () => {
  //   this.handleViewModalOpen();
  //   this.setState({
  //     isLoading: true,
  //   });
  //   settingsServices
  //     .previewBreaks(this.props.scheduler_lid)
  //     .then((response) => {
  //       if (response.data.success === 2) {
  //         alert.error(response.data.message);
  //       } else {
  //         this.setState({
  //           previewBreakData: response.data.PayrollData,
  //           isLoading: false,
  //         });

  //         alert.success(response.data.message);
  //       }
  //     });
  // };

  onDeleteBreak = (id) => {
    settingsServices.deleteBreaks(id).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        this.setState({
          previewBreakData: response.data.PayrollData,
        });
        alert.success(response.data.message);
      }
    });
  };

  handleViewModalClose = () => {
    this.setState({
      viewModalOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
      if (this.props.LocationDetaildata.WorkScheduleData) {
        const newWeekDaysTimingsData = [
          ...this.props.LocationDetaildata.WorkScheduleData,
        ].map((item) => {
          var cloneItem = item;
          var schedule_day_start_time = moment(item.schedule_day_start_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          var schedule_day_end_time = moment(item.schedule_day_end_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          cloneItem["schedule_day_start_time"] = schedule_day_start_time;
          cloneItem["schedule_day_end_time"] = schedule_day_end_time;
          return cloneItem;
        });
        this.setState({
          weekDaysTimingsData: newWeekDaysTimingsData,
        });
      }
      this.setState({
        isLoading: false,
        location_timezone:
          this.props.LocationDetaildata.locationDetail.location_timezone,
        data: this.props.LocationDetaildata.locationDetail,
      });
    }
  }

  handleChangeData = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDate = (date, state) => {
    let d = new Date(date);
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [state]: date,
        },
      }),
      () => {}
    );
  };

  editworkRestriction = () => {
    var sendObj = {
      early_clock_in_before_shift_start:
        this.state.data.early_clock_in_before_shift_start,
      early_clock_in_before_shift_start_type:
        this.state.data.early_clock_in_before_shift_start_type,
      early_clock_in_before_shift_start_minutes:
        this.state.data.early_clock_in_before_shift_start_minutes,
      ending_break_earlier_than_break_settings:
        this.state.data.ending_break_earlier_than_break_settings,
      automatically_clock_out_employees:
        this.state.data.automatically_clock_out_employees,
      automatically_clock_out_employees_minutes:
        this.state.data.automatically_clock_out_employees_minutes,
      automatically_clock_out_employees_flag_issue:
        this.state.data.automatically_clock_out_employees_flag_issue,
      automatically_end_breaks_employees:
        this.state.data.automatically_end_breaks_employees,
      automatically_end_breaks_employees_minutes:
        this.state.data.automatically_end_breaks_employees_minutes,
      automatically_end_breaks_employees_flag_issue:
        this.state.data.automatically_end_breaks_employees_flag_issue,
      mark_employee_as_late: this.state.data.mark_employee_as_late,
      mark_employee_as_late_minutes:
        this.state.data.mark_employee_as_late_minutes,
      mark_employee_as_late_flag_issue:
        this.state.data.mark_employee_as_late_flag_issue,
    };
    settingsServices
      .workRestriction(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            workRestriction: false,
          });
          // this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editReminders = () => {
    var sendObj = {
      clock_out_reminder_before_shift_end:
        this.state.data.clock_out_reminder_before_shift_end,
      clock_out_reminder_before_shift_end_minutes:
        this.state.data.clock_out_reminder_before_shift_end_minutes,
      clock_out_reminder_after_shift_end:
        this.state.data.clock_out_reminder_after_shift_end,
      clock_out_reminder_after_shift_end_minutes:
        this.state.data.clock_out_reminder_after_shift_end_minutes,
    };
    settingsServices
      .Reminders(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            Reminders: false,
          });
          // this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editBreaks = () => {
    var sendObj = {
      isbreak_enable: this.state.data.isbreak_enable,
      break_start_date: this.state.data.break_start_date,
      break_end_date: this.state.data.break_end_date,
      break_Employees_get_minutes: this.state.data.break_Employees_get_minutes,
      break_Employees_get_count: this.state.data.break_Employees_get_count,
      break_Employees_get_hour: this.state.data.break_Employees_get_hour,
      isbreak_hours_type: this.state.data.isbreak_hours_type,
      paid_break_to_an_unpaid_break:
        this.state.data.paid_break_to_an_unpaid_break,
      automatically_apply_breaks_on_timecard:
        this.state.data.automatically_apply_breaks_on_timecard,
    };
    settingsServices
      .breaks(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            breakss: false,
          });
          // this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editAlertsAndNotification = () => {
    var sendObj = {
      manager_approval_for_availability_requests:
        this.state.data.manager_approval_for_availability_requests,
      employees_create_correction_requests:
        this.state.data.employees_create_correction_requests,
      shift_feedback_after_end: this.state.data.shift_feedback_after_end,
    };
    settingsServices
      .alertsandnotification(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            alertsAndNotification: false,
          });
          // this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  handleChangeSelectValue = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeText = (e) => {
    let state = e.target.name;
    let value = e.target.value;

    if (state) {
      const validMin = value.match(/^[0-9]+$/);
      if (!validMin && value !== "") {
        alert("Not a valid number");
      }
    }
    // if (this.state.data.enforce_clockout_reminder_min < this.state.data.enforce_auto_clockout_min) {
    //     alert("Clock-out reminder should be less than Automatically clock-out employee")
    // }
    // else{
    //     // value = ""
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeDays = (e) => {
    this.setState({
      location_timezone: e.target.value,
    });
  };

  handleApplyToAll = (e) => {
    this.setState({
      applyToAll: e.target.checked ? 1 : 0,
    });
  };

  rangeCalculater = (date, index) => {
    if (date === undefined || date === null) {
      return false;
    }
    var formatStart = moment(date.start_time, "hh:mm:ss").format("HH");
    var formatEnd = moment(date.end_time, "hh:mm:ss").format("HH");
    var formatStartNew = moment(date.start_time, "hh:mm:ss").format("HH:mm");
    var formatEndNew = moment(date.end_time, "hh:mm:ss").format("HH:mm");
    const startRange = formatStart !== "" ? Number(formatStart) : null;
    const endRange = formatEnd !== "" ? Number(formatEnd) : null;
    if (startRange === null || endRange === null) {
      return false;
    }

    const inbtwnRange = index >= startRange && index <= endRange ? true : false;

    return {
      inbtwnRange: inbtwnRange,
      startIndex: {
        index: startRange,
        value: formatStartNew,
      },
      endIndex: {
        index: endRange,
        value: formatEndNew,
      },
    };
  };

  handleChangeCheck = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleClickTwo = (event, index) => {
    this.props.getApiResponse.eventsData.filter((item) => {
      setTimePreStartNew = moment(item.start_time, "hh:mm:ss").format(
        "YYYY-MM-DDhh:mm:ss"
      );
      setTimePreEndNew = moment(item.end_time, "hh:mm:ss").format(
        "YYYY-MM-DDhh:mm:ss"
      );
      if (item.day_id === index) {
        return this.setState({
          openLeft: true,
          dayState: index,
          setTimePreStart: setTimePreStartNew,
          setTimePreEnd: setTimePreEndNew,
        });

        // setTimeNew = moment(this.state.setTime,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
        // setTimeEndNew = moment(this.state.setTimeEnd,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
      }
    });
  };

  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const date = moment(this.state.startDate).format("YYYY-MM-DD");
    const time = moment(this.state.startTime).format("THH:mm:ssZ");
    const total = date + time;
    const now = moment().hour(0).minute(0);
    const { dropdownData } = this.state;

    const {
      permissionsData,
      classes,
      LocationDetaildata,
      isLoading,
      timezone,
      getApiResponse,
    } = this.props;

    var selectedDropdown = dropdownData.find(
      (dropdown) => parseInt(this.state.av_id) === dropdown.id
    );

    console.log("jkxkjkjdcnkjdnckj",this.props.getApiResponse.user_team)
    if (this.state.isLoading) return <LoadingData />;
    if (isLoading) return <LoadingData />;
    return (
      // permissionsData.scheduler_settings ?
      <>
        <Grid container spacing={2} style={{ padding: "8px" }}>
          <TableContainer component={Paper}>
            <Table
              style={{ tableLayout: "fixed" }}
              aria-label="collapsible table"
            >
              <TableHead style={{ backgroundImage: schedulerColor.main }}>
                <TableRow>
                  <TableCell
                    className={classes.listCell1}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.listCell3}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Availablity %
                  </TableCell>
                  <TableCell
                    className={classes.listCell4}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Available
                  </TableCell>
                  <TableCell
                    className={classes.listCell6}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Unavailable
                  </TableCell>
                  <TableCell
                    className={classes.listCell5}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Preferred
                  </TableCell>
                  <TableCell
                    className={classes.listCell7}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Payrule
                  </TableCell>
                  <TableCell
                    className={classes.listCell8}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Available Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.getApiResponse.user_team &&
                  this.props.getApiResponse.user_team.map((items, index) => {
                    return (
                      <>
                        <TableRow hover>
                          <TableCell
                            className={classes.listCell1}
                            onClick={() =>
                              this.props.handleClickToggle(items.open, index)
                            }
                            align="left"
                          >
                            <Box display={"flex"} style={{ gap: "0.4rem" }}>
                            {items.open ? 
                                                                    <Box
                                                                    
                                                                    >
                                                                      <ExpandLessRoundedIcon style={{ color: 'white', cursor: "pointer",background:"rgba(170, 7, 107, 1)",borderRadius:"13px",fontSize:"20px" }} />
                                                                    </Box> 
                                                                    :
                                                                    <Box
                                                                        
                                                                      >
                                                                        <ExpandMoreRoundedIcon style={{ color: 'white', cursor: "pointer",background:"rgba(170, 7, 107, 1)",borderRadius:"13px",fontSize:"20px" }} />
                                                                      </Box>}
                              

                              <Typography
                                className={classes.subjectName}
                                style={{
                                  color: schedulerColor.main,
                                  opacity: 0.8,
                                }}
                                variant="h6"
                              >
                                {items.user_name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              this.props.handleClickToggle(items.open, index)
                            }
                            className={classes.listCell7}
                            align="left"
                          >
                            <Typography
                              noWrap
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              {items.availability}
                            </Typography>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              this.props.handleClickToggle(items.open, index)
                            }
                            className={classes.listCell3}
                            align="left"
                          >
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                                      {items.available}
                            </Typography>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              this.props.handleClickToggle(items.open, index)
                            }
                            className={classes.listCell4}
                            align="left"
                          >
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {items.unavailable}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell5} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              {items.preferred}
                            </Typography>
                          </TableCell>

                          <TableCell
                            onClick={() =>
                              this.props.handleClickToggle(items.open, index)
                            }
                            className={classes.listCell6}
                            align="left"
                          >
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {items.payrule}
                            </Typography>
                          </TableCell>

                          <TableCell className={classes.listCell6} align="left">
                            {/* <Button
                              onClick={() =>
                                this.props.newModalOpen(
                                  items,
                                  this.props.scheduler_lid
                                )
                              }
                              style={{
                                textTransform: "none",
                                backgroundImage: schedulerColor.main,
                                color: "#fff",
                              }}
                            >
                              Add
                            </Button> */}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={7}
                          >
                            <Collapse in={items.open}>
                              <Box
                                sx={{
                                  margin: "0px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item sm={12} className="d-flex">
                                    <TableContainer component={Paper}>
                                      <Table
                                        style={{ tableLayout: "fixed" }}
                                        aria-label="collapsible table"
                                      >
                                        {items?.effective_dates?.length === 0 ? (
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              margin: "4rem",
                                            }}
                                          >
                                            <Typography variant="h4">
                                              No Availability
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <TableBody>
                                            {items?.effective_dates &&
                                              items?.effective_dates.map(
                                                (item, indexx) => {
                                                  return (
                                                    <>
                                                      <TableRow
                                                        onClick={() =>
                                                          this.props.handleClickToggleInside(
                                                            item.openInside,
                                                            indexx,
                                                            items.lid,
                                                            item.av_id
                                                          )
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "rgba(170, 7, 107, 0.1)",
                                                        }}
                                                        hover
                                                      >
                                                        <TableCell
                                                          style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                          }}
                                                          className={
                                                            classes.listCell1
                                                          }
                                                          align="left"
                                                        >
                                                          <Box
                                                            display={"flex"}
                                                            style={{
                                                              gap: "0.4rem",
                                                            }}
                                                          >
                                                       {item.openInside ? 
                                                                    <Box
                                                                    
                                                                    >
                                                                      <ExpandLessRoundedIcon style={{ color: 'white', cursor: "pointer",background:"rgba(170, 7, 107, 1)",borderRadius:"13px",fontSize:"20px" }} />
                                                                    </Box> 
                                                                    :
                                                                    <Box
                                                                        
                                                                      >
                                                                        <ExpandMoreRoundedIcon style={{ color: 'white', cursor: "pointer",background:"rgba(170, 7, 107, 1)",borderRadius:"13px",fontSize:"20px" }} />
                                                                      </Box>}
                                                            {/* <Box
                                                              style={{
                                                                transform:
                                                                  item.openInside
                                                                    ? "rotate(180deg)"
                                                                    : "",
                                                              }}
                                                            >
                                                              <ArrowDropDownCircleRoundedIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(170, 7, 107, 1)",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              />
                                                            </Box> */}

                                                            <Typography
                                                              className={
                                                                classes.subjectName
                                                              }
                                                              style={{
                                                                color:
                                                                  schedulerColor.main,
                                                                opacity: 0.8,
                                                                lineHeight:"25px"
                                                              }}
                                                              variant="h6"
                                                            >
                                                            
                                                            {moment(
                                                                      item.effective_start_date
                                                                    ).format(
                                                                      "YYYY-MM-DD"
                                                                    )} { item.avail_status === 2  || item.avail_status === 1 || item.avail_status === 0  ? "to" : "-"} {  item.avail_status === 2  || item.avail_status === 1 || item.avail_status === 0  ? "ongoing" : moment(
                                                                      item.effective_end_date
                                                                    ).format(
                                                                      "YYYY-MM-DD"
                                                                    )}
                                                            </Typography>
                                                          </Box>
                                                          <div
                                                            className={
                                                              classes.listCell3
                                                            }
                                                            align="left"
                                                          >
                                                            <Typography
                                                              className={
                                                                classes.subjectName
                                                              }
                                                              style={{
                                                                color:
                                                                  schedulerColor.main,
                                                                display: "flex",
                                                                opacity: 0.8,
                                                              }}
                                                              variant="h6"
                                                            >
                                                              {item.avail_status ===
                                                              1   ? (
                                                               permissionsData.scheduler_teamavailability_approve ?
                                                                <>
                                                                  <CheckCircleRoundedIcon
                                                                    onClick={() =>
                                                                      this.props.approveRejectStatus(
                                                                        item.av_id,
                                                                        items,
                                                                        2
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "green",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  />
                                                                  <CancelRoundedIcon
                                                                    onClick={() =>
                                                                      this.props.approveRejectStatus(
                                                                        item.av_id,
                                                                        items,
                                                                        3
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  />
                                                                </>  : ""
                                                              ) : item.avail_status ===
                                                                2 ? (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#1B693F",
                                                                    fontWeight: 700,
                                                                  }}
                                                                >
                                                                  Approved
                                                                </span>
                                                              ) : item.avail_status ===
                                                                3 ? (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#AA0707",
                                                                    fontWeight: 700,
                                                                  }}
                                                                >
                                                                  Rejected
                                                                </span>
                                                              ) : item.avail_status ===
                                                                4 ? (
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#AA0707",
                                                                    fontWeight: 700,
                                                                  }}
                                                                >
                                                                  Expired
                                                                </span>
                                                              ) : (
                                                                <span
                                                                style={{
                                                                  color:
                                                                    "#AA0707",
                                                                  fontWeight: 700,
                                                                }}
                                                              >
                                                                Pending
                                                              </span>
                                                              )}
                                                            </Typography>
                                                          </div>
                                                        </TableCell>
                                                        {/* <TableCell
                                                          className={
                                                            classes.listCell3
                                                          }
                                                          align="left"
                                                        ></TableCell>{" "}
                                                        <TableCell
                                                          className={
                                                            classes.listCell3
                                                          }
                                                          align="left"
                                                        ></TableCell> */}

                                                        {/* <TableCell
                                                          className={
                                                            classes.listCell3
                                                          }
                                                          align="left"
                                                        ></TableCell>
                                                        <TableCell
                                                          className={
                                                            classes.listCell3
                                                          }
                                                          align="left"
                                                        ></TableCell>
                                                        <TableCell
                                                          className={
                                                            classes.listCell3
                                                          }
                                                          align="left"
                                                        ></TableCell> */}
                                                      </TableRow>

                                                      <TableRow>
                                                        <TableCell
                                                          style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            padding: "0",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Collapse
                                                            in={item.openInside}
                                                          >
                                                            <Box
                                                              sx={{
                                                                margin:
                                                                  "0px auto",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Grid
                                                                container
                                                                spacing={2}
                                                              >
                                                                <div
                                                                  // item
                                                                  // md={12}
                                                                  // className="d-flex"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <div
                                                                    // item
                                                                    // md={12}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "center",
                                                                      padding:
                                                                        "40px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        // marginTop:
                                                                        //   "15px",
                                                                        // padding:
                                                                        //   "0px 16px 12px 16px",
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "center",
                                                                        gap: "20px",
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                    >
                                                                      <Box
                                                                        // display={
                                                                        //   "flex"
                                                                        // }
                                                                        // justifyContent={
                                                                        //   "space-between"
                                                                        // }
                                                                        style={{
                                                                          // marginTop:
                                                                          //   "15px",
                                                                          // padding:
                                                                          //   "0px 16px 12px 16px",
                                                                          display:
                                                                            "flex",
                                                                          gap: "2vw", flexWrap: "wrap"
                                                                        }}
                                                                      >
                                                                        {item.schDay?.length >
                                                                          0 &&
                                                                          item.schDay?.map(
                                                                            (
                                                                              weekDay,
                                                                              index
                                                                            ) => {
                                                                              if(weekDay.is_wholeday === 1){
                                                                                var unavailable_start_timetemp = new Date(weekDay.unavailable_start_time)
                                                                                var unavailable_start_time = 
                                                                                moment(weekDay.unavailable_start_time)
                                                                                .tz(this.props.timezone[0].location_timezone)
                                                        
                                                                                var unavailable_end_timetemp = new Date(weekDay.unavailable_end_time)
                                                                                var unavailable_end_time = 
                                                                                moment(weekDay.unavailable_end_time)
                                                                                .tz(this.props.timezone[0].location_timezone)
                                                     
                                                                                var prefered_start_timetemp = new Date(weekDay.prefered_start_time)
                                                                                var prefered_start_time = 
                                                                                 moment(weekDay.prefered_start_time)
                                                                                 .tz(this.props.timezone[0].location_timezone)
                                                                          
                                                                                var prefered_end_timetemp = new Date(weekDay.prefered_end_time)
                                                                                var prefered_end_time =  
                                                                                 moment(weekDay.prefered_end_time)
                                                                                 .tz(this.props.timezone[0].location_timezone)
                                                                    
                                                                              }else{
                                                                              var unavailable_start_timetemp = new Date(weekDay.unavailable_start_time)
                                                                              var unavailable_start_time = 
                                                                              // moment(weekDay.unavailable_start_time)
                                                                              // .tz(this.props.timezone[0].location_timezone)
                                                                              unavailable_start_timetemp.toLocaleString("en-US", {
                                                                                timeZone: this.props.timezone[0].location_timezone,
                                                                              });
                                                                              var unavailable_end_timetemp = new Date(weekDay.unavailable_end_time)
                                                                              var unavailable_end_time = 
                                                                              // moment(weekDay.unavailable_end_time)
                                                                              // .tz(this.props.timezone[0].location_timezone)
                                                                              unavailable_end_timetemp.toLocaleString("en-US", {
                                                                                timeZone: this.props.timezone[0].location_timezone,
                                                                              });
                                                    
                                                                              var prefered_start_timetemp = new Date(weekDay.prefered_start_time)
                                                                              var prefered_start_time = 
                                                                              // moment(weekDay.prefered_start_time)
                                                                              // .tz(this.props.timezone[0].location_timezone)
                                                                               prefered_start_timetemp.toLocaleString("en-US", {
                                                                                timeZone: this.props.timezone[0].location_timezone,
                                                                              });
                                                    
                                                                              var prefered_end_timetemp = new Date(weekDay.prefered_end_time)
                                                                              var prefered_end_time =  
                                                                              // moment(weekDay.prefered_end_time)
                                                                              // .tz(this.props.timezone[0].location_timezone)
                                                                              prefered_end_timetemp.toLocaleString("en-US", {
                                                                                timeZone: this.props.timezone[0].location_timezone,
                                                                              });
                                                                            }
                                                                          return(                              
                                                                            <div
                                                                           style={{ marginBottom: "40px" }}
                                                                              // onClick={() =>
                                                                              //   this.props.handlePopupModal(
                                                                              //     weekDay,
                                                                              //     index,
                                                                              //     items.location_name,
                                                                              //     "edit"
                                                                              //   )
                                                                              // }
                                                                              key={  index }>
                                                                                 {weekDay.unavailable_start_time === null && weekDay.unavailable_end_time === null && weekDay.prefered_start_time === null && weekDay.prefered_end_time === null ?
                                                                                   <div style={{ height: "190px", width: "150px" }} className={classes.divWdth}>  
                                                                                      <div 
                                                                                      
                                                                                     style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                    

                                                                                            
                                                                                             <button style={{ 
                                                                                              background: "none",
                                                                                              color: "#AA076B",
                                                                                             
                                                                                              padding: "4px",
                                                                                             
                                                                                          
                                                                                             }}>Available whole day</button> 
                                                                                             
                                                                                         </div>                                        
                                                                                     
                                                                                                                                                                     
                                                                                   </div> :
                                                                           <div
                                                                           style={{ height: "190px", width: "150px" }} className={classes.divWdth}
                                                                              >  <>
                                                                                          {weekDay.unavailable_start_time === null && weekDay.unavailable_start_time === null ? "" :
                                                              <div style={{ background: "#AA076B" }}>
                                                                <Typography style={{ padding: "3px", marginTop: "23px", color: "white", textAlign: "center", fontSize: "13px" }}>
                                                                  <span>Unavailable</span><br />
                                                                  {moment(unavailable_start_time).format("hh:mm A")} - {moment(unavailable_end_time).format("hh:mm A")}
                                                                </Typography>
                                                              </div>}
                                                            {weekDay.prefered_start_time === null && weekDay.prefered_end_time === null ? "" :
                                                              <div style={{ background: "rgba(170, 7, 107, 0.1)" }}>
                                                                <Typography style={{ padding: "3px", marginTop: "23px", color: "#AA076B", textAlign: "center", fontSize: "13px", marginBottom: "31px" }}>
                                                                  Preferred<br />
                                                                  {moment(prefered_start_time).format("hh:mm A")} - {moment(prefered_end_time).format("hh:mm A")}
                                                                </Typography>
                                                              </div>}
                                                                                        </>
                                                                                 
                                                                              </div>}

                                                                                  <Typography
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "0.4rem",
                                                                                    }}
                                                                                    align="center"
                                                                                    variant="h6"
                                                                                  >
                                                                                    {weekDay.day_id ===
                                                                                    0
                                                                                      ? "Sunday"
                                                                                      : weekDay.day_id ===
                                                                                        1
                                                                                      ? "Monday"
                                                                                      : weekDay.day_id ===
                                                                                        2
                                                                                      ? "Tuesday"
                                                                                      : weekDay.day_id ===
                                                                                        3
                                                                                      ? "Wednesday"
                                                                                      : weekDay.day_id ===
                                                                                        4
                                                                                      ? "Thrusday"
                                                                                      : weekDay.day_id ===
                                                                                        5
                                                                                      ? "Friday"
                                                                                      : weekDay.day_id ===
                                                                                        6
                                                                                      ? "Saturday"
                                                                                      : ""}
                                                                                  </Typography>
                                                                                </div>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </Box>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Grid>
                                                            </Box>
                                                          </Collapse>
                                                        </TableCell>
                                                      </TableRow>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        )}
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <StickyContainer className="app">
                        <Sticky topOffset={194 - 64}>
                            {({ style }) => (
                                <div
                                    className="create-task"
                                    style={{
                                        ...style,y
                                        top: 64,
                                        width: "82%"
                                    }}
                                >
                                    <Table>
                                        <TableHead style={{ backgroundColor: schedulerColor.main }}>
                                            <TableRow>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    Employee
                                                </TableCell>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    Available Hours
                                                </TableCell>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    UnAvailable Hours
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            )}
                        </Sticky>
                        <div className="content">
                            <Paper style={{ width: "100%", backgroundColor: "red" }} className={classes.paper}>
                                sssss

                            </Paper>
                        </div>

                    </StickyContainer> */}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkScheduleData: state.schedulerSettingsLocationdata.WorkScheduleData,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
  locationData: state.settingsLocation.locationData,
});

const ConnectWith = connect(mapStateToProps, {
  // getScheduleData,
  // updateScheduleData,
})(Scheduling);
export default withStyles(styles)(ConnectWith);
