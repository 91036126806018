import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const MyAvailabilityService = {
  fetchMyAvailabilityData,
  fetchTeamAvaData,
  addAvailability,
  editAvailability,
  approveAvailability,
  deleteAvailability,
  addMyAvailability,
  submitAvailability,
  getTeamAvailabilityData,
  publishEffectiveDate,
  approveRejectStatus
};

function fetchMyAvailabilityData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability/my`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveRejectStatus(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability/approve`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}


function publishEffectiveDate(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability/publish`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}
function fetchTeamAvaData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/avaliblity/team/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addMyAvailability(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addAvailability(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/add/my/availiblity/time`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveAvailability(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/teamavailability/approve/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editAvailability(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability/time`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getTeamAvailabilityData(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/availability/team?lid=${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteAvailability(id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/delete/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function submitAvailability(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/add/my/availiblity/time`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}
