import { HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const scrolesServices = {
    fetchData,
    addData,
    editData,
    deleteData
}

function fetchData() {
    return axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/roles`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/roles`,  data, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/roles/${data._id}`,data , HEADER_TOKEN).then(response => response)
};

function deleteData(role_id) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/roles/${role_id}`, HEADER_TOKEN).then(response => response)
};