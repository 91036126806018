//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    float: "left",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px!important",
    lineHeight: "18px",
    marginTop: "9px",
  },
  closeIcon: {
    padding: "10px 22px",
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  saveButton: {
    color: "white",
    padding: "10px 22px",
  },
  // closeButton: {
  //   color: "#ffffff",
  //   padding: "10px 22px",
  // },
  closesaveButton: {
    color: "#ffffff",
    padding: "8px 14px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "15px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class MyRightModal extends Component {
  render() {
    const { classes, children ,data} = this.props;
    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            backgroundImage: this.props.bgColor ? this.props.bgColor : "gray",
            backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
            fontWeight: 700,
            fontStyle: "initial",
          }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4">
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>
        {data?.isPublished ?
        <DialogActions style={{justifyContent:"center"}}>
          {data?.clock_in && data?.clock_out ? "" :
          <Button
            variant="outlined"
            size="medium"
            onClick={this.props.clockInShift}
            style={{
                backgroundImage: this.props.bgColor ? this.props.bgColor : "gray",
                fontWeight: 700,
                fontStyle: "initial",
                textTransform:"capitalize",
                backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
                fontWeight: 700,
                fontStyle: "initial",
            }}
            className={classes.saveButton}
          >

            {(data?.clock_in && data?.break_start === null)  ? "Take Break" : (data?.break_start  &&  data?.clock_in ) ? "End Break" : "Clock In" }
          </Button>}

        {data?.break_start  &&  data?.clock_in ? "" :
        data?.clock_in && data?.clock_out ? "" :
          <Button
             aria-describedby={this.props.popoverId} 
            style={{
              backgroundColor: "rgba(159, 13, 156, 0.25)",
              fontWeight: 700,
              fontStyle: "initial",
              color:"rgba(170, 7, 107, 1)" ,
              fontWeight: 700,
              fontStyle: "initial",
              textTransform:"capitalize",
            }}
            variant="outlined"
            size="medium"
            className={classes.saveButton}
            onClick={data?.clock_in && data?.break_start === null ? this.props.clockoutShift : this.props.popoverOpen}
          >
            {(data?.clock_in && data?.break_start === null)  ? "Clock Out" : "Can't Work" }
          </Button>}
        </DialogActions>:""}
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyRightModal);
