import {
  SET_PAYROLL_SUMMARY_LOADING,
  SET_PAYROLL_SUMMARY,
  ADD_PAYROLL_SUMMARY,
  EDIT_PAYROLL_SUMMARY,
  DELETE_PAYROLL_SUMMARY,
  SET_PAYROLL_HEADER,
  GET_SCHEDULE_SETTING_DATA
} from "./constants";

const initialState = {
  isLoading: true,
  payrollWeeks: [],
  MonthData:[],
  approvalData: [],
  AprroveData: [],
  LocationDetaildata: [],
  WorkSchedule: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAYROLL_SUMMARY_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PAYROLL_SUMMARY:
      return {
        ...state,
        isLoading: false,
        MonthData:payload.MonthData,
        payrollWeeks: payload.payrollWeeks,
        AprroveData: payload.AprroveData

      };
      case GET_SCHEDULE_SETTING_DATA:
        return {
          ...state,
          isLoading: false,
          LocationDetaildata: payload?.location_settings,
          WorkSchedule: payload.work_schedule,
        };
      case SET_PAYROLL_HEADER:
        return {
          ...state,
          isLoading: false,
          items:payload.HeaderData,
          payroll_ids:payload.payroll_ids
        };
    case ADD_PAYROLL_SUMMARY:
      return {
        ...state,
        isLoading: false,
        payrollWeeks: [...state.payrollWeeks, payload]
      }
    case EDIT_PAYROLL_SUMMARY:
      return {
        ...state,
        isLoading: false,
        payrollWeeks: state.payrollWeeks.map((item) => item.cid === payload.cid ? { ...payload } : item)
      }
    case DELETE_PAYROLL_SUMMARY:
      return {
        ...state,
        isLoading: false,
        payrollWeeks: state.payrollWeeks.filter((item) => item.cid !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;