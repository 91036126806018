import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MyDatePicker, MyTimePicker, MySelectField, BasicTimePicker } from "../../../components";
import {
  Grid,
  Button,
  Typography,
  Divider,
  IconButton,
  TextField,
} from "@material-ui/core";
import { schedulerColor } from "../../../config";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import DatePicker from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "react-datepicker/dist/react-datepicker.css";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlarmIcon from '@mui/icons-material/Alarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import ClockIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
const styles = (theme) => ({
  root1: {
    padding: theme.spacing(8),
  },
  saveButton: {
    backgroundImage: schedulerColor.main,
    color: '#ffffff',
    padding: '10px 22px',
    width: '200px',
    '&:hover': {
      color: schedulerColor.main,
      borderColor: schedulerColor.main,
    }

  },
  closeButton: {
    borderColor: schedulerColor.main,
    color: schedulerColor.main,
    padding: '10px 22px',
    width: '150px',
    marginRight: '20px'

  },
});

class AddUser extends Component {
  render() {
    const {
      classes,
      myItem,
      errors,
      errorsHelper,
      timecardStep,
      usersData,
      rolesData,
    } = this.props;

    return timecardStep === 1 ? (
      <Grid className={classes.root2} container spacing={2}>
        <Grid item sm={4} xs={12} style={{ marginTop: "8px" }}>
          <MySelectField
            label="Role"
            name="role_id"
            onChange={this.props.handleChange}
            onFocus={this.props.handleFocus}
            value={myItem.role_id}
            error={errors.role_id}
            helperText={errors.role_id ? errorsHelper.role_id : ""}
            placeholder="Please Select Role"
          >
            <option value={0}>Select Role</option>
            {rolesData.map((item) => {
              return (
                <option key={item._id} value={item._id}>
                  {item.role_name}
                </option>
              );
            })}
          </MySelectField>
        </Grid>

        <Grid item sm={4} xs={12} style={{ textAlign: "center" }}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
              key={`dateTime-${this.props.timezone[0].location_timezone}`}
              fixWidth={true}
              // disableFuture
              hideTabs
              showTodayButton
              label="Clock In"
              name="clock_in"
              todayText="now"
              openTo="hours"
              value={myItem.clock_in ? myItem.clock_in : myItem.currentDate}
              onChange={(date) => {
                this.props.handleChangeDateTime(date, "clock_in");
              }}
              minDate={myItem.currentDate}
              onFocus={this.props.handleFocus}
              error={errors.clock_in}
              helperText={errors.clock_in ? errorsHelper.clock_in : ""}
              components={{
                LeftArrowIcon: AlarmIcon,
                RightArrowIcon: SnoozeIcon,
                OpenPickerIcon: ClockIcon,
              }}
              //  leftArrowButtonText="Open previous month"
              //  rightArrowButtonText="Open next month"
              //  minTime={new Date(0, 0, 0, 9)}
              //  maxTime={new Date(0, 0, 0, 20)}
              // renderInput={(params) => (
              //     <TextField {...params}  helperText={errors.clock_in ? errorsHelper.clock_in : ""}/>
              // )}
              ></KeyboardDateTimePicker>
              </MuiPickersUtilsProvider> */}
          <BasicTimePicker
            label="Clock In"
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="clock_in"
            value={dayjs(myItem.clock_in ? myItem.clock_in : myItem.currentDate)}
            onChange={(date) => {
              this.props.handleChangeDateTime(date, "clock_in");
            }}
            onFocus={this.props.handleFocus}
            error={errors.clock_in}
            helperText={errors.clock_in ? errorsHelper.clock_in : ""}
            renderInput={(params) => (
              <TextField {...params} helperText={errors.clock_in ? errorsHelper.clock_in : ""} />
            )}
          />
           
        </Grid>

        <Grid item sm={4} xs={12}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
              key={`dateTime-${this.props.timezone[0].location_timezone}`}
              fixWidth={true}
              // disableFuture
              hideTabs
              showTodayButton
              label="Clock Out"
              name="clock_out"
              todayText="now"
              openTo="hours"
              value={myItem.clock_out ?  myItem.clock_out : myItem.currentDate}
              onChange={(date) => {
                this.props.handleChangeDateTime(date, "clock_out");
              }}
              minDate={myItem.currentDate}
              onFocus={this.props.handleFocus}
              error={errors.clock_out}
              //helperText={errors.revised_clock_in ? errorsHelper.revised_clock_out : ""}
              components={{
                LeftArrowIcon: AlarmIcon,
                RightArrowIcon: SnoozeIcon,
                OpenPickerIcon: ClockIcon,
              }}
              //  leftArrowButtonText="Open previous month"
              //  rightArrowButtonText="Open next month"
              //  minTime={new Date(0, 0, 0, 9)}
              //  maxTime={new Date(0, 0, 0, 20)}
              renderInput={(params) => (
                  <TextField {...params}  helperText={errors.clock_out ? errorsHelper.clock_out : ""}/>
              )}
              ></KeyboardDateTimePicker>
              </MuiPickersUtilsProvider> */}
          {/* <MyTimePicker
            label="Clock Out"
            name="clock_out"
            onChange={(date) => {
              this.props.handleChangeDateTime(date, "clock_out");
            }}
            onFocus={this.props.handleFocus}
            error={errors.clock_out}
            helperText={errors.clock_out ? errorsHelper.clock_out : ""}
            value={myItem.clock_out}
            placeholder="Please select clock_out"
          /> */}

          <BasicTimePicker
            label="Clock Out"
            key={`dateTime-${this.props.timezone[0].location_timezone}`}
            name="clock_out"
            value={dayjs(myItem.clock_out ? myItem.clock_out : myItem.currentDate)}
            onChange={(date) => {
              this.props.handleChangeDateTime(date, "clock_out");
            }}
            onFocus={this.props.handleFocus}
            error={errors.clock_out}
            helperText={errors.clock_out ? errorsHelper.clock_out : ""}
            renderInput={(params) => (
              <TextField {...params} helperText={errors.clock_out ? errorsHelper.clock_out : ""} />
            )}
    
          />
                 {this.props.nextDay &&
                    <Typography style={{color:"red"}}>Next Day</Typography>}
        </Grid>

        <Grid item sm={12} xs={12} style={{ padding: "12px 8px" }}>
          <Divider style={{ width: "100%", backgroundColor: "#dddddd" }} />
        </Grid>

        {myItem.allBreaks.length > 0 ? (
          <Grid item sm={12} xs={12}>
            {myItem.allBreaks.map((breakItem, breakIndex) => {
              return (
                <Grid className={classes.root2} container spacing={2} style={{ height: 60 }}>
                  <Grid item sm={3} xs={12}>
                    <Typography style={{ lineHeight: "4rem" }} variant="body1">
                      Break {breakIndex + 1}
                    </Typography>
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <KeyboardDateTimePicker
                      key={`dateTime-${this.props.timezone[0].location_timezone}`}
                        fixWidth={true}
                        // disableFuture
                        hideTabs
                        showTodayButton
                        label="Start"
                        name="break_start"
                        todayText="now"
                        openTo="hours"
                        value={breakItem.break_start}
                        onChange={(date) => {
                          this.props.handleChangeBreakTime(
                            date,
                            breakIndex,
                            "break_start"
                          );
                        }}
                        minDate={myItem.currentDate}
                        onFocus={(e) =>
                          this.props.handleFocusBreak(e, breakIndex)
                        }
                        error={breakItem.break_start_error}
                        helperText={breakItem.break_start_error === true ? breakItem.break_start_error_helper : ""}
                        //helperText={errors.revised_clock_in ? errorsHelper.revised_clock_out : ""}
                        components={{
                          LeftArrowIcon: AlarmIcon,
                          RightArrowIcon: SnoozeIcon,
                          OpenPickerIcon: ClockIcon,
                        }}
                        //  leftArrowButtonText="Open previous month"
                        //  rightArrowButtonText="Open next month"
                        //  minTime={new Date(0, 0, 0, 9)}
                        //  maxTime={new Date(0, 0, 0, 20)}
                        // renderInput={(params) => <TextField 
      
                        //   {...params} sx={{ width:"100%" }} 
                        //   error
                        //   helperText={breakItem.break_start_error  ? breakItem.break_start_error_helper  : "" }
                        // />}
                        // renderInput={(params) => (
                        //     <TextField {...params}   helperText={breakItem.break_start_error  ? breakItem.break_start_error_helper  : "" }/>
                        // )}
                        ></KeyboardDateTimePicker>
                        </MuiPickersUtilsProvider> */}
                    {/* <MyTimePicker
                      label="Start"
                      name="break_start"
                      onFocus={(e) =>
                        this.props.handleFocusBreak(e, breakIndex)
                      }
                      onChange={(date) => {
                        this.props.handleChangeBreakTime(
                          date,
                          breakIndex,
                          "break_start"
                        );
                      }}
                      error={breakItem.break_start_error}
                      helperText={
                        breakItem.break_start_error
                          ? breakItem.break_start_error_helper
                          : ""
                      }
                      value={breakItem.break_start}
                      placeholder="Please select break_start"
                    /> */}

                    <BasicTimePicker
                      label="Start"
                      name="break_start"
                      key={`dateTime-${this.props.timezone[0].location_timezone}`}
                      value={dayjs(breakItem.break_start)}
                      onChange={(date) => {
                        this.props.handleChangeBreakTime(
                          date,
                          breakIndex,
                          "break_start"
                        );
                      }}
                      onFocus={(e) =>
                        this.props.handleFocusBreak(e, breakIndex)
                      }
                      renderInput={(params) => <TextField {...params} sx={{ width:"100%" }} error helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}/>}
                      error={breakItem.break_start_error}
                      helperText={breakItem.break_start_error === true ? breakItem.break_start_error_helper : ""}
                      // renderInput={(params) => (
                      //   <TextField {...params} helperText={errors.break_start_error ? errorsHelper.break_start_error : ""} />
                      // )}
                    />
                    {breakItem.break_start_error &&
                    <Typography style={{color:"red"}}>{breakItem.break_start_error ? breakItem.break_start_error_helper : ""}</Typography>}
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                        key={`dateTime-${this.props.timezone[0].location_timezone}`}
                        fixWidth={true}
                        // disableFuture
                        hideTabs
                        showTodayButton
                        label="End"
                        name="break_end"
                        todayText="now"
                        openTo="hours"
                        value={breakItem.break_end}
                        onChange={(date) => {
                          this.props.handleChangeBreakTime(
                            date,
                            breakIndex,
                            "break_end"
                          );
                        }}
                        minDate={myItem.currentDate}
                        onFocus={(e) =>
                          this.props.handleFocusBreak(e, breakIndex)
                        }
                        error={breakItem.break_end_error}
                        helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                        //helperText={errors.revised_clock_in ? errorsHelper.revised_clock_out : ""}
                        components={{
                          LeftArrowIcon: AlarmIcon,
                          RightArrowIcon: SnoozeIcon,
                          OpenPickerIcon: ClockIcon,
                        }}
                        //  leftArrowButtonText="Open previous month"
                        //  rightArrowButtonText="Open next month"
                        //  minTime={new Date(0, 0, 0, 9)}
                        //  maxTime={new Date(0, 0, 0, 20)}
                        // renderInput={(params) => (
                        //     <TextField {...params}   helperText={breakItem.break_end_error  ? breakItem.break_end_error_helper  : "" }/>
                        // )}
                        ></KeyboardDateTimePicker>
                        </MuiPickersUtilsProvider> */}
  
                    <BasicTimePicker
                      label="End"
                      name="break_end"
                      key={`dateTime-${this.props.timezone[0].location_timezone}`}
                      value={dayjs(breakItem.break_end)}
                      onChange={(date) => {
                        this.props.handleChangeBreakTime(
                          date,
                          breakIndex,
                          "break_end"
                        );
                      }}
                      onFocus={(e) =>
                        this.props.handleFocusBreak(e, breakIndex)
                      }
                      error={breakItem.break_end_error}
                      renderInput={(params) => <TextField {...params} sx={{ width:"100%" }} 
                        error
                        helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                      />}
                      helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                      // renderInput={(params) => (
                      //   <TextField {...params} helperText={errors.break_end_error ? errorsHelper.break_end_error : ""} />
                      // )}
                    />
                       {breakItem.break_end_error === true &&
                    <Typography style={{color:"red"}}>{breakItem.break_end_error ? breakItem.break_end_error_helper : ""}</Typography>}
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <IconButton
                      style={{ padding: "19px" }}
                      onClick={() => {
                        this.props.removeBreakRow(breakIndex);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        )}

        <Grid item sm={12} xs={12}>
          <Button onClick={this.props.addBreakRow}
            style={{ backgroundImage: schedulerColor.main, padding: "6px", color: "white", textTransform: "capitalize" }}>Add break</Button>
        </Grid>
      </Grid>
    ) : (
      <Grid className={classes.root1} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom align="center">
            Add Timecard
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <MySelectField
            label="User"
            name="uid"
            onChange={this.props.handleChange}
            onFocus={this.props.handleFocus}
            value={myItem.uid}
            error={errors.uid}
            helperText={errors.uid ? errorsHelper.uid : ""}
            placeholder="Please Select User"
          >
            <option value={0}>Select Employee</option>
            {usersData?.map((item) => {
              return (
                <option key={item._id} value={item._id}>
                  {item.user_name}
                </option>
              );
            })}
          </MySelectField>
        </Grid>

        <Grid item xs={12}>
          <MySelectField
            label="Role"
            name="role_id"
            onChange={this.props.handleChange}
            onFocus={this.props.handleFocus}
            value={myItem.role_id}
            error={errors.role_id}
            helperText={errors.role_id ? errorsHelper.role_id : ""}
            placeholder="Please Select Role"
          >
            <option value={0}>Select Role</option>
            {rolesData.map((item) => {
              return (
                <option key={item._id} value={item._id}>
                  {item.role_name}
                </option>
              );
            })}
          </MySelectField>
        </Grid>

        <Grid item xs={12}>
          <MyDatePicker
            disablePast={false}
            disableFuture={true}
            label="Date"
            name="currentDate"
            onChange={(date) => {
              this.props.handleChangeDate(date, "currentDate");
            }}
            error={errors.currentDate}
            helperText={errors.currentDate ? errorsHelper.currentDate : ""}
            value={myItem.currentDate}

            placeholder="placeholder"
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            className={classes.closeButton}
            onClick={this.props.handleClose}
          >
            Close
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            className={classes.saveButton}
            onClick={this.props.nextTimecard}
          >
            Add Time Card
          </Button>

        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddUser);

