import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const coverServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    approveRejectTeamCoverByManager,
    fetchTeamDetailsById,
    approveRejectTeamCoverByUser,
    approveRejectMyCover,
    fetchTeamData
}



function fetchData( data) {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/covers/my?page=${data.pageNo}&limit=${process.env.REACT_APP_LIMIT}`
    if(data.lid){
      url=`${url}&lid=${data.lid}`
    }
    if (data.status !== null && data.status !== undefined) {
      url = `${url}&status=${data.status}`;
    }
    return axios
      .get(
        url,
        HEADER_TOKEN
      )
      .then((response) => response);
}

function fetchTeamData( data) {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/covers/team?page=${data.pageNo}&limit=${process.env.REACT_APP_LIMIT}`
    if(data.lid){
      url=`${url}&lid=${data.lid}`
    }
    if(data.user_name){
        url=`${url}&requested_by=${data.user_name}`
    }
    return axios
      .get(
        url,
        HEADER_TOKEN
      )
      .then((response) => response);
}


function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/cover`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/cover/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/new/${id}`, HEADER_TOKEN).then(response => response)
};

function approveRejectTeamCoverByManager(id, data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/covers/${id}/manager`, data , HEADER_TOKEN).then(response => response)
};

function approveRejectTeamCoverByUser(id, data) {
  return axios.put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/covers/${id}/user`, data , HEADER_TOKEN).then(response => response)
};


function approveRejectMyCover(id, data) {
    return axios.put(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/my/${id}`,  data , HEADER_TOKEN).then(response => response)
};

function fetchTeamDetailsById(id) {
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/${id}`, HEADER_TOKEN).then(response => response)
};
