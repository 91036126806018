import {
  SET_LOCATION_LOADING,
  SET_LOCATION,
  SET_ASSIGN_LOCATION,
  ADD_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { LocationServices } from './services'
import { commonpayrollServices } from '../../PayrollManager/modues/services';

export function fetchAssignedLocations(isReload) {
  return function (dispatch) {
    LocationServices.fetchAssignedLocations()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_ASSIGN_LOCATION,
            payload: response.data
          });
        }
      });
  };
}

export function fetchData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_LOCATION_LOADING,
        payload: true
      });
    }
    LocationServices.fetchData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_LOCATION,
            payload: response.data
          });
        }
      });
  };
}

export function fetchAllData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_LOCATION_LOADING,
        payload: true
      });
    }
    LocationServices.fetchAllData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_LOCATION,
            payload: response.data
          });
        }
      });
  };
}


export function deleteData(lid) {
  return function (dispatch) {
    LocationServices.deleteData(lid)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        }
        else if (response.data.success === 1) {
          alert.success(response.data.message)
          dispatch({
            type: DELETE_LOCATION,
            payload: lid
          });
        } else {
          alert.error(response.data.message)
          dispatch({
            type: SET_LOCATION_LOADING,
            payload: false
          });
        }
      });
  };
}


export function addData(data) {
  return function (dispatch) {
    LocationServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else if(response.data.status === 400){
          alert.error(response.data.message)
        }else
        {
          alert.success(response.data.message)
          dispatch({
            type: ADD_LOCATION,
            payload: response.data.post
          });
          var TemplateData = {
            rate: "20",
            template_name: response.data.post.name.concat(" ", "Default"),
            hours_limit: 0,
            overlimit_rate: 0,
            payroll_summary_type1: "",
            holiday_provider_id:"",
            overlimit_amount:0,
            payroll_summary_type1_code: "",
            overlimit_rate_code: "",
            overlimit_rate_id: null,
            selectedLocationIds: [response.data.post._id],
            isconected_holiday: 0,
        
          };
          commonpayrollServices.addData(TemplateData).then(response => {
            if (response.data.success === 1) {
              alert.success(response.data.message)
            }
          });
        }
      });
  };
}

export function editData(data) {
  return function (dispatch) {
    LocationServices.editData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_LOCATION,
            payload: response.data.post
          });
        }
      });
  };
}