import { API_URL, HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const payrollSummaryServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchDetails,
  getDetailsPayrollPE,
  getDetailsPayrollCash,
  posttDetailsPayrollCash,
  fetchApproveData,
  resetApprovalData,
  fetchPayrollComment,
  warningCorrectionRequest,
  rejectApprovalData,
  getTableAd,
  getTableAdData,
  getDownloadFilterData,
  getTableHeaderData,
  getSettingsData,
  resetDataDetails,
  getScheduleData,
  // getDetailsPayrollPi
};

function getDetailsPayrollPE(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/details`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getDetailsPayrollCash(data) {
//     return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/payrollcompany/cash`, {data}, HEADER_TOKEN).then(response => response)
// };

function getTableAd(dataPayload) {
  var data = {
    headerArr: dataPayload.headerArr,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/headers/${dataPayload.lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getTableAdData(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/headers/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getSettingsData(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/settings/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getScheduleData(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/work-schedule/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getTableHeaderData(lid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/headers/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getDetailsPayrollCash() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/pi/connected`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function posttDetailsPayrollCash(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payrollcompany/pe`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/months?lid=${data.lid}&date=${data.date}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchApproveData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/approve`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function resetApprovalData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/reset`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchDetails(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/details?lid=${data.lid}&date=${data.date}&hideEmployee=${data.hideEmployee}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function resetDataDetails(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/details/payrun/reset-data`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchPayrollComment(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/comments`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(
      `${API_URL}/scheduler/payroll_summary`,
      {
        data,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(
      `${API_URL}/scheduler/payroll_summary/${data.cid}`,
      {
        data,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteData(cid) {
  return axios
    .delete(`${API_URL}/scheduler/payroll_summary/${cid}`, HEADER_TOKEN)
    .then((response) => response);
}

function warningCorrectionRequest(data) {
  return axios
    .post(
      `${API_URL}/onevision/scheduler/payroll_summary/v2/payroll/correction/user`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function rejectApprovalData(data) {
  return axios
    .post(
      `${API_URL}/onevision/scheduler/payroll_summary/v2/payroll/reject`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getDownloadFilterData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/payrun/download?date=${data.date}&lid=${data.lid}&pi_id=${data.pi_id}&is_period_start=${data?.is_period_start}&is_period_end=${data?.is_period_end}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
