import React, { useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { eLearningColor } from "../../../config";
import moment from "moment";
import {
  Button,
  Grid,
  MenuItem,
  Popover,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Calendar } from "react-multi-date-picker";
import DatePicker from "react-datepicker";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./DateRangeSelector.css";
import { RxCross2 } from "react-icons/rx";

const DateCalendar = ({ startDate, endDate }) => {
  const value = [startDate, endDate];
  return (
    <div
      style={{
        pointerEvents: "none",
        padding: "10px",
      }}
    >
      <Calendar
        className="elearning1CalenderStyles"
        // onlyShowInRangeDates={true}
        value={value}
        range
        minDate={startDate}
        maxDate={endDate}
      />
    </div>
  );
};

const DateRangeCustom = ({ startDate, endDate, onSelectStartEndDate }) => {
  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <DatePicker
        selected={startDate}
        onChange={onSelectStartEndDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
    </div>
  );
};

const styles = (theme) => ({
  PopoverClass: {
    minHeight: "300px",
    minWidth: "220px",
  },
  menu: {
    minWidth: "200px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
  },
  MenuItem: {
    "&:hover": {
      backgroundColor: eLearningColor.main,
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
    },
  },
  Typography: {
    width: "100%",
    fontSize: "15px",
    lineHeight: "2rem",
  },
  buttonLogin: {
    fontWeight: 600,
    color: "#ffffff",
    backgroundImage: eLearningColor.main,
    borderRadius: "10px",
    padding: "8px 40px",
    textTransform: "capitalize",
    marginLeft: "75px",
  },
  menuItemsContainer: {
    width: "27%",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
  },
  Popover: {
    // marginLeft: "58%",
    // marginTop: "8.8%",
  },
});

const PopoverDemoDash = ({
  id,
  open,
  classes,
  anchorEl,
  payPeriod,
  handlePopoverClose,
  selectPayPeriod,
  applyPayPeriod,
  payrollFilterData,
  todayStartDate,
  todayEndDate,
  current_startDate,
  current_endDate,
  previous_startDate,
  previous_endDate,
  custom_startDate,
  custom_endDate,
  month_startDate,
  month_endDate,
  week_startDate,
  week_endDate,
  yesterdayStartDate,
  yesterdayEndDate,
  lastWeek_startDate,
  lastWeek_endDate,
  lastMonth_startDate,
  lastMonth_endDate,
  lastYear_startDate,
  lastYear_endDate,
  thisYear_startDate,
  thisYear_endDate,
  onSelectStartEndDate,
}) => {
  return (
    <Popover
      // className={classes.Popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ zIndex: 99999 }}
    >
      <Grid
        container
        className={classes.PopoverClass}
        style={{ overflowX: "scroll" }}
      >
        <div className={classes.menuItemsContainer}>
          {payrollFilterData.map((items) => (
            <MenuItem
              key={items.id}
              style={{
                backgroundColor:
                  payPeriod === items.id ? "rgb(27 63 143 / 18%)" : "#ffffff",
              }}
              onClick={() => selectPayPeriod(items.id)}
              className={classes.MenuItem}
            >
              <Typography
                className={classes.Typography}
                color="textPrimary"
                variant="inherit"
              >
                {items.title}
              </Typography>
            </MenuItem>
          ))}
        </div>
        <Grid
          style={{
            borderLeft: "1px solid lightgray",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "60px",
          }}
          item
          sm={8}
          xs={12}
        >
          {/* {payPeriod === "current" && (
            <DateCalendar
              startDate={current_startDate}
              endDate={current_endDate}
            />
          )} */}
          {payPeriod === "thisweek" && (
            <DateCalendar startDate={week_startDate} endDate={week_endDate} />
          )}
          {payPeriod === "lastweek" && (
            <DateCalendar
              startDate={lastWeek_startDate}
              endDate={lastWeek_endDate}
            />
          )}
          {payPeriod === "thismonth" && (
            <DateCalendar startDate={month_startDate} endDate={month_endDate} />
          )}
          {payPeriod === "lastmonth" && (
            <DateCalendar
              startDate={lastMonth_startDate}
              endDate={lastMonth_endDate}
            />
          )}
          {payPeriod === "lastyear" && (
            <DateCalendar
              startDate={lastYear_startDate}
              endDate={lastYear_endDate}
            />
          )}
          {payPeriod === "thisyear" && (
            <DateCalendar
              startDate={thisYear_startDate}
              endDate={thisYear_endDate}
            />
          )}
          {payPeriod === "today" && (
            <DateCalendar startDate={todayStartDate} endDate={todayEndDate} />
          )}
          {payPeriod === "yesterday" && (
            <DateCalendar
              startDate={yesterdayStartDate}
              endDate={yesterdayEndDate}
            />
          )}
          {payPeriod === "custom" && (
            <DateRangeCustom
              startDate={custom_startDate}
              endDate={custom_endDate}
              onSelectStartEndDate={onSelectStartEndDate}
            />
          )}
          <Grid sm={12}>
            <Button className={classes.buttonLogin} onClick={applyPayPeriod}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

const DateRangeSelector = withStyles(styles)(
  ({
    classes,
    handleDateRange,
    startDate,
    endDate,
    handleClose,
    customButtonClassName,
    placeholder,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [payPeriod, setPayPeriod] = useState("thisweek");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);

    // Calculate dates
    const todayStartDate = moment().startOf("day").toDate();
    const todayEndDate = moment().endOf("day").toDate();

    const yesterdayStartDate = moment()
      .subtract(1, "days")
      .startOf("day")
      .toDate();
    const yesterdayEndDate = moment().subtract(1, "days").endOf("day").toDate();

    const current_startDate = moment().startOf("day").toDate();
    const current_endDate = moment().endOf("day").toDate();

    const previous_startDate = moment()
      .subtract(1, "day")
      .startOf("day")
      .toDate();
    const previous_endDate = moment().subtract(1, "day").endOf("day").toDate();

    const week_startDate = moment().startOf("isoWeek").toDate();
    const week_endDate = moment().endOf("isoWeek").toDate();

    const lastWeek_startDate = moment()
      .subtract(1, "weeks")
      .startOf("isoWeek")
      .toDate();
    const lastWeek_endDate = moment()
      .subtract(1, "weeks")
      .endOf("isoWeek")
      .toDate();

    const month_startDate = moment().startOf("month").toDate();
    const month_endDate = moment().endOf("month").toDate();

    const lastMonth_startDate = moment()
      .subtract(1, "months")
      .startOf("month")
      .toDate();
    const lastMonth_endDate = moment()
      .subtract(1, "months")
      .endOf("month")
      .toDate();

    const lastYear_startDate = moment()
      .subtract(1, "years")
      .startOf("year")
      .toDate();

    const lastYear_endDate = moment()
      .subtract(1, "years")
      .endOf("year")
      .toDate();

    const thisYear_startDate = moment().startOf("year").toDate();
    const thisYear_endDate = moment().endOf("year").toDate();

    const payrollFilterData = [
      // { id: "current", title: "Current" },
      { id: "thisweek", title: "This Week" },
      { id: "lastweek", title: "Last Week" },
      { id: "thismonth", title: "This Month" },
      { id: "lastmonth", title: "Last Month" },
      { id: "thisyear", title: "This Year" },
      { id: "lastyear", title: "Last Year" },
      { id: "today", title: "Today" },
      { id: "yesterday", title: "Yesterday" },
      { id: "custom", title: "Custom" },
    ];

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const onSelectStartEndDate = (dates) => {
      const [start, end] = dates;

      // Set start date as it is
      setCustomStartDate(start);

      // If end date exists, set it to the end of the day
      if (end) {
        const adjustedEnd = moment(end).endOf("day").toDate();
        setCustomEndDate(adjustedEnd);
      } else {
        setCustomEndDate(null);
      }
    };

    const selectPayPeriod = (period) => {
      setPayPeriod(period);
    };

    const applyPayPeriod = () => {
      setAnchorEl(null);

      let selectedStartDate;
      let selectedEndDate;

      switch (payPeriod) {
        case "thisweek":
          selectedStartDate = week_startDate;
          selectedEndDate = week_endDate;
          break;
        case "lastweek":
          selectedStartDate = lastWeek_startDate;
          selectedEndDate = lastWeek_endDate;
          break;
        case "thismonth":
          selectedStartDate = month_startDate;
          selectedEndDate = month_endDate;
          break;
        case "lastmonth":
          selectedStartDate = lastMonth_startDate;
          selectedEndDate = lastMonth_endDate;
          break;
        case "thisyear":
          selectedStartDate = thisYear_startDate;
          selectedEndDate = thisYear_endDate;
          break;
        case "lastyear":
          selectedStartDate = lastYear_startDate;
          selectedEndDate = lastYear_endDate;
          break;
        case "today":
          selectedStartDate = todayStartDate;
          selectedEndDate = todayEndDate;
          break;
        case "yesterday":
          selectedStartDate = yesterdayStartDate;
          selectedEndDate = yesterdayEndDate;
          break;
        case "custom":
          selectedStartDate = customStartDate;
          selectedEndDate = customEndDate;
          break;
        default:
          selectedStartDate = todayStartDate;
          selectedEndDate = todayEndDate;
          break;
      }

      handleDateRange(selectedStartDate, selectedEndDate);
    };

    const selectedDateRangeText = () => {
      switch (payPeriod) {
        case "current":
          return `${moment(current_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(current_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "thisweek":
          return `${moment(week_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(week_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "lastweek":
          return `${moment(lastWeek_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(lastWeek_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "thismonth":
          return `${moment(month_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(month_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "lastmonth":
          return `${moment(lastMonth_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(lastMonth_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "thisyear":
          return `${moment(thisYear_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(thisYear_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "lastyear":
          return `${moment(lastYear_startDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(lastYear_endDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        case "today":
          return moment(todayStartDate).locale("en-US").format("DD MMM YY");
        case "yesterday":
          return moment(yesterdayStartDate).locale("en-US").format("DD MMM YY");
        case "custom":
          return `${moment(customStartDate)
            .locale("en-US")
            .format("DD MMM YY")} - ${moment(customEndDate)
            .locale("en-US")
            .format("DD MMM YY")}`;
        default:
          return moment(todayStartDate).locale("en-US").format("DD MMM YY");
      }
    };

    // const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
    // const buttonRef = useRef(null);

    // useEffect(() => {
    //   const updatePosition = () => {
    //     if (buttonRef.current) {
    //       const rect = buttonRef.current.getBoundingClientRect();
    //       // Calculate the position to place the popover below the button
    //       const left = rect.left;
    //       const top = rect.bottom - window.scrollY;

    //       setAnchorPosition({ top, left });
    //     }
    //   };

    //   // Call the function initially and also on resize
    //   updatePosition();
    //   window.addEventListener("resize", updatePosition);

    //   // Cleanup the event listener
    //   return () => window.removeEventListener("resize", updatePosition);
    // }, []);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover-scheduler" : undefined;

    return (
      <div>
        <div>
          <Button
            onClick={handlePopoverOpen}
            className="dateRangeSelectorField"
            aria-describedby={id}
            style={customButtonClassName}
          >
            {!startDate && !endDate ? placeholder : selectedDateRangeText()}
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              {startDate && endDate ? (
                <div onClick={handleClose} style={{ fontSize: "12px" }}>
                  <RxCross2 />
                </div>
              ) : (
                ""
              )}
              <BsChevronDown
                style={{
                  padding: "0",
                  color: eLearningColor.main,
                  marginLeft: "5",
                }}
              />
            </div>
          </Button>
        </div>
        <PopoverDemoDash
          id={id}
          open={open}
          anchorEl={anchorEl}
          payPeriod={payPeriod}
          handlePopoverClose={handlePopoverClose}
          selectPayPeriod={selectPayPeriod}
          applyPayPeriod={applyPayPeriod}
          payrollFilterData={payrollFilterData}
          todayStartDate={todayStartDate}
          todayEndDate={todayEndDate}
          current_startDate={current_startDate}
          current_endDate={current_endDate}
          previous_startDate={previous_startDate}
          previous_endDate={previous_endDate}
          custom_startDate={customStartDate}
          custom_endDate={customEndDate}
          month_startDate={month_startDate}
          month_endDate={month_endDate}
          week_startDate={week_startDate}
          week_endDate={week_endDate}
          yesterdayStartDate={yesterdayStartDate}
          yesterdayEndDate={yesterdayEndDate}
          lastWeek_startDate={lastWeek_startDate}
          lastWeek_endDate={lastWeek_endDate}
          lastMonth_startDate={lastMonth_startDate}
          lastMonth_endDate={lastMonth_endDate}
          lastYear_startDate={lastYear_startDate}
          lastYear_endDate={lastYear_endDate}
          thisYear_startDate={thisYear_startDate}
          thisYear_endDate={thisYear_endDate}
          onSelectStartEndDate={onSelectStartEndDate}
          classes={classes}
        />
      </div>
    );
  }
);

export default DateRangeSelector;
