import { HEADER_TOKEN, MY_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const tradeServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchTeamDataById,
  approveRejectTeamTradeByManager,
  approveRejectTeamTradeByUser,
  approveRejectMyTrade,
  fetchTeamData,
};

function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/trades/my?page=${data.pageNo}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.lid) {
    url = `${url}&lid=${data.lid}`;
  }
  if (data.status !== null && data.status !== undefined) {
    url = `${url}&status=${data.status}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}


function fetchTeamData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/trades/team`;

  return axios
    .get(url, {
      ...HEADER_TOKEN,
      params: data,
    })
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/scheduler/trade`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/trade/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteData(id) {
  return axios
    .delete(`${MoNGO_DB_API_URL}/scheduler/trade/${id}`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchTeamDataById(id) {
  return axios
    .get(`${MoNGO_DB_API_URL}/scheduler/trade/${id}`, HEADER_TOKEN)
    .then((response) => response);
}

function approveRejectTeamTradeByManager(id, data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/trades/${id}/manager`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveRejectTeamTradeByUser(id, data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_SCHEDULER_API_VERSION}/${process.env.REACT_APP_PLATFORM}/scheduler/trades/${id}/user`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveRejectMyTrade(id, data) {
  return axios
    .put(`${MoNGO_DB_API_URL}/scheduler/trade/my/${id}`, data, HEADER_TOKEN)
    .then((response) => response);
}
