import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { schedulerColor } from '../../../config';
const styles = (theme) => ({
        root: {
                padding: 0,
                height: 24,
                width: 24,
                color:"rgb(170, 7, 107)", // Change the color to your desired color
                "&.Mui-checked": {
                  color: "rgb(170, 7, 107)", // Change the checked color to your desired color
                },
                "&:hover": {
                  color:"rgb(170, 7, 107)", // Change to your desired hover color
                },
              },
        menu: {
                minWidth: '270px',
                borderRadius: '5px',
                backgroundColor: "#fafafa"
        },
        MenuItem: {
                border: "1px solid #f2f2f2",
                padding: "0px 10px",
                '&:hover': {
                        boxShadow: '4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                        backgroundColor: theme.palette.primary.secondary,
                }
        },
        ListItemIcon: {
                minWidth: "45px"
        },
        mYicon: {
                height: 24,
                width: 24
        },
        Typography: {
                fontSize: '15px',
                lineHeight: '2rem'
        }
})

class ToolsMenus extends Component {

        render() {
                const { classes, toolsAnchor, showHideData, showHideSettings } = this.props;
                const open = Boolean(toolsAnchor);
                return (
                        <Menu
                                MenuListProps={{ disablePadding: true }}
                                anchorEl={toolsAnchor}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                }}
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                }}
                                getContentAnchorEl={null}
                                open={Boolean(toolsAnchor)}
                                onClose={(e) => { this.props.handleShowHideClose("") }}
                                PopoverClasses={{
                                        paper: classes.menu
                                }}
                        >
                                {showHideData.map((value, key) => {
                                        return (
                                                <MenuItem
                                                        onClick={() => { this.props.handleShowHideClose(value.sortName) }}
                                                        className={classes.MenuItem}
                                                >
                                                        <ListItemIcon className={classes.ListItemIcon}>
                                                                <Checkbox
                                                                    
                                                                        className={classes.root}
                                                                        // checked={showHideSettings[value.sortName] ? true : false}
                                                                        name={value.sortName}
                                                                        checked={showHideSettings[value.sortName] === 1}
                                                                        onClick = {this.props.handleChangeCheckbox}

                                                                />
                                                        </ListItemIcon>

                                                        <Typography
                                                                className={classes.Typography}
                                                                color="textPrimary"
                                                                variant="inherit"
                                                        >
                                                                {value.title}
                                                        </Typography>

                                                </MenuItem>
                                        )
                                })}
                        </Menu>
                )
        }
}

export default withStyles(styles)(ToolsMenus);
