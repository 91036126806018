import * as React from "react";
import Box from "@mui/material/Box";
import {
  RoutineColor,
  eLearningColor,
  // schedulerColor
} from "../../../config";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import Pagination from "@mui/material/Pagination";
import { getMyLessonNames } from "./modules/actions";
import Stack from "@mui/material/Stack";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { history } from "../../../history";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { fetchData } from "./modules/actions";
import {
  FailButton,
  GradientButton,
  PassButton,
  PendingButton,
} from "../Grading/GradingStyles";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSearchDropdown from "../../../components/ELearning/Dropdown/CustomSearchDropdown";
import { Tooltip } from "@mui/material";
import { LoadMore } from "../../../components/ELearning/Button/LoadMore";
import { progressServices } from "./modules/services";

const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      display: "none",
    },
  },
  root: {
    padding: theme.spacing(5),
  },
  select: {
    borderRadius: "5px",
    "&:before": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
    "&:after": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
    "&:hover": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
  },
  tabButton: {
    border: `1px solid ${eLearningColor.main}`,
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: 500,
    fontSize: "14px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: RoutineColor.main,
    },
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  typography: {
    color: "#295CDC",
    fontFamily: "Inter",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
  },
  filterButton: {
    color: RoutineColor.main,
    textTransform: "capitalize",
    border: "1px solid white",
    backgroundColor: "white",
    marginLeft: "6px",
    marginBottom: "1px",
    borderRadius: "6px 6px 0px 0px",
    width: "85px",
    "&:hover": {
      color: RoutineColor.main,
      textTransform: "capitalize",
      border: "1px solid white",
      backgroundColor: "white",
      borderRadius: "6px 6px 0px 0px",
      width: "85px",
    },
  },
  image: {
    width: "23px",
    height: "auto",
  },
  toolbarIcons: {
    display: "flex",
    justifyContent: "end",
  },
  icdiv: {
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
  },
  titleShare: {
    color: "rgba(19, 80, 152, 1)",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  publishButton: {
    backgroundColor: RoutineColor.main,
    color: "#ffffff",
    textTransform: "capitalize",
    width: "130px",
    "&:hover": {
      backgroundColor: RoutineColor.main,
    },
  },
  avatar: {
    height: "30px",
    width: "30px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: RoutineColor.main,
    color: "white",
  },
  toolbarTitle: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 600,
  },
  iconImage: {
    border: "1px solid #DEDEDE",
    padding: "5px",
    marginRight: "13px",
    borderRadius: "4px",
    width: "37px",
    height: "34px",
    cursor: "pointer",
  },
  pagination: {
    width: "fit-content",
    marginLeft: "65%",
    padding: "10px",
  },
  searchIconStyle: {
    backgroundColor: "rgba(41, 92, 220, 0.3)",
    borderRadius: "50px",
    padding: "6px",
    marginTop: "-5px",
  },
});

const MyGradingSkeletonLoader = () => {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} sx={{ cursor: "pointer" }}>
      <SkeletonTheme
        baseColor="#ebebeb"
        // highlightColor="#f5f5f5"
        duration={4}
      >
        <TableCell
          component="th"
          align="center"
          scope="row"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
          padding="none"
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ color: " #848484" }}>
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ color: " #848484" }}>
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
      </SkeletonTheme>
    </TableRow>
  );
};

function Progress(props) {
  const {
    classes,
    // isProgressLoading,
    headingFlag = true,
  } = props;

  // const [page, setpage] = React.useState(1);
  const [myGradingCurrentPage, setMyGradingCurrentPage] = React.useState(1);
  const [myGradingTotalPages, setMyGradingTotalPages] = React.useState(0);
  const [myGradingData, setMyGradingData] = React.useState([]);
  const [myGradingLoading, setMyGradingLoading] = React.useState(false);

  const createDataAndFetch = (newData, key) => {
    let data = {
      page: 1,
      limit: 50,
    };

    if (lesson) {
      data.lesson_id = lesson;
    }

    if (videoStatus) {
      data.video_quiz_status = videoStatus;
    }
    if (lessonQuizStatus) {
      data.lesson_quiz_status = lessonQuizStatus;
    }
    if (managerStatus) {
      data.manager_quiz_status = managerStatus;
    }

    if (newData) {
      data[key] = newData;
    } else {
      delete data[key];
    }

    setMyGradingLoading(true);

    // props.fetchData(data);
    progressServices
      .fetchData(data)
      .then((response) => {
        if (response.data.success === 1) {
          setMyGradingLoading(false);

          setMyGradingData((prevData) => [
            ...prevData,
            ...response.data.result,
          ]);
          setMyGradingCurrentPage(response.data.currentPage);
          setMyGradingTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => {
        console.error("Error fetching team grading data:", error);
        setMyGradingLoading(false);
      });
  };

  React.useEffect(() => {
    createDataAndFetch();
    props.getMyLessonNames();
  }, []);

  const loadMoreData = () => {
    const nextPage = myGradingCurrentPage + 1;
    if (nextPage <= myGradingTotalPages) {
      setMyGradingCurrentPage(nextPage);
      createDataAndFetch(nextPage, "page");
    }
  };

  const [videoStatus, setVideoStatus] = React.useState("");
  const [lesson, setLesson] = React.useState("");
  const [lessonQuizStatus, setLessonQuizStatus] = React.useState("");
  const [managerStatus, setManagerStatus] = React.useState("");

  const handleChangeDropDownLesson = (event) => {
    let newData = event.value;
    setLesson(newData);
    setMyGradingData([]);
    setMyGradingCurrentPage(1);
    createDataAndFetch(newData, "lesson_id");
  };

  const handleChangeDropDownLessonQuizStatus = (event) => {
    let newData = event.value;
    setMyGradingData([]);
    setMyGradingCurrentPage(1);

    createDataAndFetch(newData, "lesson_quiz_status");

    setLessonQuizStatus(newData);
  };

  const handleChangeDropDownVideo1 = (event) => {
    setMyGradingData([]);
    setMyGradingCurrentPage(1);
    let newData = event.value;
    createDataAndFetch(newData, "video_quiz_status");
    setVideoStatus(newData);
  };

  const handleChangeDropDownManagerStatus = (event) => {
    let newData = event.value;

    setMyGradingData([]);
    setMyGradingCurrentPage(1);
    createDataAndFetch(newData, "manager_quiz_status");
    setManagerStatus(newData);
  };

  const handleSubmitVideo = (e, _id, ecid, lesson_result_id) => {
    history.push(`/elearning/grading/video/${_id}/${lesson_result_id}`);
  };

  const handleView = (e, ecid, _id) => {
    window.open(`/elearning/myhelpdesk/slider/${ecid}/${_id}`, "_blank");
  };

  const numberOfSkeletons = 20;

  const MyGradingSkeletonArray = Array.from(
    { length: numberOfSkeletons },
    (_, index) => <MyGradingSkeletonLoader key={index} />
  );

  return (
    <Box sx={{ display: "flex", background: "#F7F8FC" }}>
      <Grid container spacing={4} className={classes.root}>
        {headingFlag && (
          <Grid item md={12} className={classes.flex}>
            <Grid item md={12}>
              <Typography className={classes.titleShare}>
                My Progress
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper
            style={{
              borderRadius: "10px",
              overflowX: "auto",
            }}
          >
            <TableContainer
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <Table className={classes.table} size="medium">
                <TableHead style={{ backgroundColor: eLearningColor.main }}>
                  <TableRow>
                    {[
                      {
                        id: 1,
                        heading: "Lesson Name",
                      },
                      {
                        id: 2,
                        heading: "Lesson quiz",
                      },
                      {
                        id: 3,
                        heading: "Video Quiz",
                      },
                      {
                        id: 4,
                        heading: "Manager Quiz",
                      },
                      {
                        id: 5,
                        heading: "Status",
                      },
                      {
                        id: 6,
                        heading: "Action",
                      },
                    ].map((item, index) => (
                      <TableCell
                        style={{
                          color: "#1B3F8F",
                          backgroundColor: "#1B3F8F40",
                          fontWeight: "400",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                        key={item.id}
                      >
                        {item.heading}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {[
                      {
                        id: 1,
                        heading: "Lesson Name",
                        filter: (
                          <CustomSearchDropdown
                            options={[
                              { value: "", label: "All Lessons" },
                              ...props.myLessonNamesData.map((item) => ({
                                value: item._id,
                                label: item?.lesson_name,
                              })),
                            ]}
                            selectedValue={lesson}
                            handleChange={handleChangeDropDownLesson}
                            placeholder={
                              props.myLessonNamesData.find(
                                (item) => item._id === lesson
                              )
                                ? props.myLessonNamesData.find(
                                    (item) => item._id === lesson
                                  ).lesson_name
                                : "Lesson"
                            }
                            width={170}
                            borderColor="transparent"
                            borderRadius="10px"
                            textSize="15px"
                            textColor="#1B3F8F"
                            dropdownTextSize="12px"
                            dropdownTextColor="#333"
                            dropdownTextAlign="left"
                          />
                        ),
                      },
                      {
                        id: 2,
                        heading: "Lesson quiz",
                        filter: (
                          <CustomSearchDropdown
                            options={[
                              { value: "", label: "Lesson Quiz" },
                              { value: "Passed", label: "Passed" },
                              {
                                value: "Pending",
                                label: "Pending",
                              },
                            ]}
                            selectedValue={lessonQuizStatus}
                            handleChange={handleChangeDropDownLessonQuizStatus}
                            placeholder={lessonQuizStatus || "Lesson Quiz"}
                            width={170}
                            borderColor="transparent"
                            borderRadius="10px"
                            textSize="15px"
                            textColor="#1B3F8F"
                            dropdownTextSize="12px"
                            dropdownTextColor="#333"
                            dropdownTextAlign="left"
                          />
                        ),
                      },
                      {
                        id: 3,
                        heading: "Video Quiz",
                        filter: (
                          <CustomSearchDropdown
                            options={[
                              { value: "", label: "Video Quiz" },
                              {
                                value: "Pending",
                                label: "Pending",
                              },
                              {
                                value: "Pass",
                                label: "Pass",
                              },
                              {
                                value: "Reassigned",
                                label: "Reassigned",
                              },
                              {
                                value: "Enabled",
                                label: "Enabled",
                              },
                              {
                                value: "Disabled",
                                label: "Disabled",
                              },
                            ]}
                            selectedValue={videoStatus}
                            handleChange={handleChangeDropDownVideo1}
                            placeholder={videoStatus || "Video Quiz"}
                            width={170}
                            borderColor="transparent"
                            borderRadius="10px"
                            textSize="15px"
                            textColor="#1B3F8F"
                            dropdownTextSize="12px"
                            dropdownTextColor="#333"
                            dropdownTextAlign="left"
                          />
                        ),
                      },
                      {
                        id: 4,
                        heading: "Manager Quiz",
                        filter: (
                          <CustomSearchDropdown
                            options={[
                              { value: "", label: "Manager Quiz" },
                              {
                                value: "Reassigned",
                                label: "Reassigned",
                              },
                              {
                                value: "Pending",
                                label: "Pending",
                              },
                              {
                                value: "Pass",
                                label: "Pass",
                              },
                              {
                                value: "Enabled",
                                label: "Enabled",
                              },
                              {
                                value: "Disabled",
                                label: "Disabled",
                              },
                            ]}
                            selectedValue={managerStatus}
                            handleChange={handleChangeDropDownManagerStatus}
                            placeholder={managerStatus || "Manager Quiz"}
                            width={170}
                            borderColor="transparent"
                            borderRadius="10px"
                            textSize="15px"
                            textColor="#1B3F8F"
                            dropdownTextSize="12px"
                            dropdownTextColor="#333"
                            dropdownTextAlign="left"
                          />
                        ),
                      },
                      {
                        id: 5,
                        heading: "Status",
                      },
                      {
                        id: 6,
                        heading: "Action",
                      },
                    ].map((item, index) => (
                      <TableCell
                        key={item.id}
                        style={{
                          // color: "#1B3F8F",
                          backgroundColor: "#1B3F8F40",
                          // fontWeight: "400",
                          // fontSize: "20px",
                          // textAlign: "center",
                        }}
                        align="center"
                      >
                        <Stack direction="column" spacing={2}>
                          {item.filter && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              {item.filter}
                            </div>
                          )}
                        </Stack>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myGradingData?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          align="center"
                          scope="row"
                          style={{
                            color: " #848484",
                            fontSize: "16px",
                          }}
                          padding="none"
                        >
                          <Tooltip title={row.lesson_name}>
                            <Typography
                              style={{
                                color: "#848484",
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            >
                              {row.lesson_name.slice(0, 20)}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: " #848484",
                            fontSize: "16px",
                          }}
                        >
                          {row.lesson_quiz_status}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {row.video_quiz_status === "Reassigned" ||
                          row.video_quiz_status === "Enabled" ? (
                            row.lesson_result && row.lesson_result.video_url ? (
                              <Box>
                                <Typography
                                  style={{
                                    color: "#848484",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                >
                                  {row.video_quiz_status}
                                </Typography>
                              </Box>
                            ) : row.lesson_result ? (
                              <GradientButton
                                variant="contained"
                                value="Record"
                                onClick={(e) => {
                                  handleSubmitVideo(
                                    e,
                                    row._id,
                                    row.ecid,
                                    row.lesson_result._id
                                  );
                                }}
                              >
                                Record
                              </GradientButton>
                            ) : (
                              <Box>
                                <Typography
                                  style={{
                                    color: "#848484",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                >
                                  {row.video_quiz_status}
                                </Typography>
                              </Box>
                            )
                          ) : (
                            <Box>
                              <Typography
                                style={{
                                  color: "#848484",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                {row.video_quiz_status}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: " #848484",
                            fontSize: "16px",
                          }}
                        >
                          {row.manager_quiz_status}
                        </TableCell>
                        <TableCell align="center" style={{ color: " #848484" }}>
                          {row.overall_status === "Pending" ? (
                            <PendingButton variant="contained" disableElevation>
                              Pending
                            </PendingButton>
                          ) : row.overall_status === "Pass" ? (
                            <PassButton variant="contained" disableElevation>
                              Pass
                            </PassButton>
                          ) : (
                            <FailButton variant="contained" disableElevation>
                              Fail
                            </FailButton>
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ color: " #848484" }}>
                          <GradientButton
                            variant="contained"
                            onClick={(e) => handleView(e, row.ecid, row._id)}
                          >
                            View
                          </GradientButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {myGradingLoading && MyGradingSkeletonArray}
                </TableBody>
              </Table>

              {!myGradingLoading && myGradingData.length === 0 && (
                <Box textAlign="center">
                  <Typography
                    style={{
                      color: "#848484",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    spacing={2}
                    m={"1rem"}
                  >
                    No Record Found
                  </Typography>
                </Box>
              )}
              {/* <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Stack spacing={2} m={"1rem"}>
                  <Pagination
                    color="primary"
                    count={!isProgressLoading ? props.totalPage : 1}
                    variant="outlined"
                    page={!isProgressLoading ? props.pageNumber : 1}
                    onChange={handleChange}
                  />
                </Stack>
              </div> */}
            </TableContainer>
          </Paper>
          {myGradingCurrentPage < myGradingTotalPages && (
            <LoadMore handlePageChange={loadMoreData} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ProgressData: state.elearningProgress.ProgressData,
  isProgressLoading: state.elearningProgress.isProgressLoading,
  totalPages: state.elearningProgress.totalPage,
  pageNumber: state.elearningProgress.pageNumber,
  myLessonNamesData: state.elearningProgress.myLessonNamesData,
  isMyLessonNamesDataLoading:
    state.elearningProgress.isMyLessonNamesDataLoading,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  getMyLessonNames,
})(Progress);

export default withStyles(styles)(ConnectWith);
