import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { history } from "../../../../history";
import { SlideDetail, SlidesBox } from "../../components";
import { Box, Typography } from "@material-ui/core";
import "./MobileLessonDetail.scss";

export const LessonDetail = ({
  lessonSlider,
  slideIndex,
  lessonName,
  setLessondata,
  feedbackBtnClicked,
}) => {
  return (
    <section className={"myLibraryMobileDetailRoot"}>
      <article className={"myLibraryMobileDetailArticle"}>
        <Box className={"myLibraryMobileDetailBackButton"}>
          <BiArrowBack
            size={20}
            onClick={() => {
              history.push("/elearning/myhelpdesk");
            }}
          />
        </Box>

        <Typography variant="h2" className={"myLibraryMobileDetailHeader"}>
          {lessonName.length > 30
            ? `${lessonName.slice(0, 30)}...`
            : lessonName}
        </Typography>

        {/* <Box className={"myLibraryMobileDetailFeedbackWrapper"}>
          <button
            className={"myLibraryMobileDetailFeedbackButton"}
            onClick={feedbackBtnClicked}
          >
            Feedback
          </button>
        </Box> */}
      </article>
      {lessonSlider?.map(
        (item, index) =>
          slideIndex === index && <SlideDetail description={item} />
      )}
      {lessonSlider && lessonSlider.length > 0 && (
        <div className={"myLibraryMobileDetailslidesBox"}>
          {" "}
          <SlidesBox
            slides={lessonSlider}
            handleSlide={setLessondata}
            slideIndex={slideIndex}
          />
        </div>
      )}
    </section>
  );
};
