import { useEffect, useState } from "react";
import { mobileTheme } from "../../constant";
import { Toggel } from "../../utilities/common/Button";
import "./MobileViewEarning.scss";
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GradientButton } from "../../utilities/common/Button/buttonStyles";
import { headRowMyTabItems, headRowTeamTabItems } from "./constant";
import { history } from "../../../../history";
import {
  Dropdown,
  EarningDateRangePicker,
  EarningMultiSelectDropdown,
  SearchWithCounter,
} from "../../../../pages/eLearning/Earning/Components/Dropdown";
import { NoPermission } from "../../../../components";
import CustomSearchDropdown from "../../../../components/ELearning/Dropdown/CustomSearchDropdown";
import { LoadMoreMobile } from "../../components/LoadMoreMobile";

export const EarningMobileView = ({
  myEarningData,
  myEarningLoading,
  statusOptions,
  teamEarningData,
  accumulatedData,
  teamEarningLoading,
  handlePageChange,
  loadMoreData,
  selectedTab,
  handleTabChange,
  allEarningLeassonsData,
  myEarningLeassonsData,
  lessonIds,
  setLessonIds,
  selectedStatus,
  setSelectedStatus,
  selectedEarningPoints,
  setSelectedEarningPoints,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleDateChange,
  permissionsData,
  checkPermissions,
  permissionsLoading,
  earningEmployeesData,
  userIds,
  setUserIds,
  handleStatus,
  // location
  selectedLocation,
  setSelectedLocation,
  getAllLocationsData,
  handleSelectLocation,
  // role
  selectedRole,
  roleDetails,
  setSelectedRole,
  handleSelectRole,
}) => {
  const renderHeadRows = (data) =>
    data.map((item, index) => (
      <TableCell key={item.id} className={"headCell"}>
        {item.dropdown ? (
          <div
            style={{
              display: "flex",
              justifyContent: index === 0 ? "start" : "center",
              alignItems: "start",
              padding: "3px 0",
              paddingLeft: index === 0 ? "20px" : "",
            }}
          >
            {item.dropdown}
          </div>
        ) : (
          item.heading
        )}
      </TableCell>
    ));

  const renderMyDataRows = (data) => {
    return data?.map(
      (
        {
          _id,
          lesson_name,
          earningPoints,
          earningAmounts,
          lessonExpiryDays,
          lessonExpiryHours,
          lessonExpiryMinutes,
          createdAt,
          paymentStatus,
        },
        index
      ) => {
        let formattedDate = "";
        if (createdAt) {
          const date = new Date(createdAt);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are zero-based, so we add 1
          const day = date.getDate();
          formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
            day < 10 ? "0" : ""
          }${day}`;
        }

        return (
          <TableRow key={_id}>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {lesson_name?.length > 20
                ? `${lesson_name?.slice(0, 20)}...`
                : lesson_name}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {earningPoints}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {earningAmounts}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {`${lessonExpiryDays > 0 ? `${lessonExpiryDays} Days ` : ""}${
                lessonExpiryHours > 0 ? `${lessonExpiryHours} Hours ` : ""
              }${
                lessonExpiryMinutes > 0 ? `${lessonExpiryMinutes} Minutes` : ""
              }`}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {formattedDate}
            </TableCell>
            <TableCell
              className={"bodyCell"}
              style={{
                color:
                  paymentStatus === 0
                    ? "#F6B000"
                    : paymentStatus === 1
                    ? "#287502"
                    : "",
              }}
              component="th"
              scope="row"
            >
              {paymentStatus === 0 ? "Pending" : paymentStatus === 1 && "Paid"}
            </TableCell>
          </TableRow>
        );
      }
    );
  };

  const renderTeamDataRows = (data) => {
    return data?.map(
      (
        {
          _id,
          user_name,
          lesson_count,
          paid_amount,
          Unpaid_amount,
          latestPaymentDate,
          payment_Status,
        },
        index
      ) => {
        let formattedDate = "";
        if (latestPaymentDate) {
          const date = new Date(latestPaymentDate);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are zero-based, so we add 1
          const day = date.getDate();
          formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
            day < 10 ? "0" : ""
          }${day}`;
        }
        return (
          <TableRow key={_id}>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {user_name}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {lesson_count}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {paid_amount}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {Unpaid_amount}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {formattedDate}
            </TableCell>
            <TableCell
              className={"bodyCell"}
              component="th"
              scope="row"
              onClick={() =>
                history.push(
                  `/elearning/earning/payments/${_id}?user_name=${encodeURIComponent(
                    user_name
                  )}`
                )
              }
            >
              {payment_Status === 0 ? (
                <GradientButton
                  style={{ textWrap: "nowrap" }}
                  onClick={() =>
                    history.push(
                      `/elearning/earning/payments/${_id}?user_name=${encodeURIComponent(
                        user_name
                      )}`
                    )
                  }
                >
                  Pay Now
                </GradientButton>
              ) : (
                payment_Status === 1 && "Paid"
              )}
            </TableCell>
          </TableRow>
        );
      }
    );
  };

  const handleDateRange = (startDate, endDate) => {
    handleDateChange(startDate, endDate);
  };

  const headRowTeamTabItems = [
    {
      id: 1,
      heading: "Employee",
      dropdown: (
        <EarningMultiSelectDropdown
          size={{ width: "140px", height: "26px" }}
          bgColor="white"
          arrowColor={"#1B3F8F"}
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          buttonText={"Employee"}
          rounded={"10px"}
          options={earningEmployeesData?.map((option) => ({
            value: option._id,
            label: option.user_name,
          }))}
          selectedOptions={userIds}
          onOptionSelect={(value) =>
            setUserIds((prev) => {
              const isSelected = prev.includes(value);
              if (isSelected) {
                return prev.filter((id) => id !== value);
              } else {
                return [...prev, value];
              }
            })
          }
          earningEmployeesData={earningEmployeesData}
          userIds={userIds}
          setUserIds={setUserIds}
          labelProperty="user_name"
          selectedOptionWidth="200px"
          selectedOptionFontSize={"8px"}
        />
      ),
    },
    {
      id: 2,
      heading: "Lessons",
      dropdown: "",
    },
    {
      id: 3,
      heading: "Paid Amount",
      dropdown: "",
    },
    {
      id: 4,
      heading: "Unpaid",
      dropdown: "",
    },
    {
      id: 5,
      heading: "Date",
      dropdown: (
        <EarningDateRangePicker
          size={{ width: "100px" }}
          bgColor="white"
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          rounded={"10px"}
          startDate={startDate}
          endDate={endDate}
          handleClose={() => handleDateRange("", "")}
          handleDateChange={handleDateRange}
        />
      ),
    },
    {
      id: 6,
      heading: "Status",
      dropdown: (
        <CustomSearchDropdown
          options={statusOptions}
          selectedValue={selectedStatus}
          handleChange={handleStatus}
          placeholder={
            selectedStatus === 1
              ? "Paid"
              : selectedStatus === 0
              ? "Pending"
              : "Status"
          }
          width={100}
          borderColor="transparent"
          borderRadius="10px"
          textSize="10px"
          textColor="#1B3F8F"
          dropdownTextSize="12px"
          dropdownTextColor="#333"
          dropdownTextAlign="left"
        />
      ),
    },
  ];

  const headRowMyTabItems = [
    {
      id: 1,
      heading: "Lesson name",
      dropdown: (
        <EarningMultiSelectDropdown
          size={{ width: "140px", height: "26px" }}
          bgColor="white"
          arrowColor={"#1B3F8F"}
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          buttonText={"Employee"}
          rounded={"10px"}
          options={myEarningLeassonsData?.result?.map((option) => ({
            value: option._id,
            label: option.lesson_name,
          }))}
          selectedOptions={lessonIds}
          onOptionSelect={(value) =>
            setLessonIds((prev) => {
              const isSelected = prev.includes(value);
              if (isSelected) {
                return prev.filter((id) => id !== value);
              } else {
                return [...prev, value];
              }
            })
          }
          earningEmployeesData={myEarningLeassonsData?.result}
          userIds={lessonIds}
          setUserIds={setLessonIds}
          labelProperty="lesson_name"
          selectedOptionWidth="200px"
          selectedOptionFontSize={"8px"}
        />
      ),
    },
    {
      id: 2,
      heading: "Point",
      dropdown: (
        <SearchWithCounter
          size={{ width: "100px" }}
          bgColor="white"
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          rounded={"10px"}
          buttonText={"Point"}
          textSize={"12px"}
          value={selectedEarningPoints}
          onChange={(value) => setSelectedEarningPoints(Number(value))}
        />
      ),
    },
    {
      id: 3,
      heading: "Amount",
      dropdown: "",
    },
    {
      id: 4,
      heading: "Duration",
      dropdown: "",
    },
    {
      id: 5,
      heading: "Date",
      dropdown: (
        <EarningDateRangePicker
          size={{ width: "150px" }}
          bgColor="white"
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          rounded={"10px"}
          startDate={startDate}
          endDate={endDate}
          handleClose={() => handleDateRange("", "")}
          handleDateChange={handleDateRange}
        />
      ),
    },
    {
      id: 6,
      heading: "Status",
      dropdown: (
        <CustomSearchDropdown
          options={statusOptions}
          selectedValue={selectedStatus}
          handleChange={handleStatus}
          placeholder={
            selectedStatus === 1
              ? "Paid"
              : selectedStatus === 0
              ? "Pending"
              : "Status"
          }
          width={100}
          borderColor="transparent"
          borderRadius="10px"
          textSize="10px"
          textColor="#1B3F8F"
          dropdownTextSize="12px"
          dropdownTextColor="#333"
          dropdownTextAlign="left"
        />
      ),
    },
  ];

  return (
    <>
      {
        <div className={"EarningListRoot"}>
          <div className={"toggelContainer"}>
            <Toggel
              options={[
                {
                  id: 1,
                  heading: "my",
                  value: "my",
                  path: "/elearning/earning/my",
                },
                {
                  id: 2,
                  heading: "team",
                  value: "team",
                  path: "/elearning/earning/team",
                },
              ].filter((tab) => tab)}
              tabSize={{ width: "65px", height: "30px" }}
              selected={selectedTab}
              selectedBgColor={mobileTheme}
              selectedTextColor={"white"}
              textColor={"#1B3F8F"}
              size={{
                width: "130px",
                height: "34px",
              }}
              bgColor={"white"}
              border={"1px solid rgba(27, 63, 143,1)"}
              borderImageSource={
                "linear-gradient(180deg, #1B3F8F 0%, #635BFF 100%)"
              }
              handleTabChange={handleTabChange}
            />
          </div>

          {checkPermissions() ? (
            <>
              {selectedTab === "team" && (
                <Box
                  mt={"1.5rem"}
                  mx={"1.5rem"}
                  mb={"2.5rem"}
                  display={"flex"}
                  gap={"20px"}
                  flexWrap={"wrap"}
                  style={{
                    justifyContent:
                      window.innerWidth < 700 ? "center" : "start",
                  }}
                >
                  <CustomSearchDropdown
                    options={[
                      { value: "", label: "All Locations" },
                      ...getAllLocationsData?.map((option) => ({
                        value: option._id,
                        label: option.name,
                      })),
                    ]}
                    selectedValue={selectedLocation}
                    handleChange={handleSelectLocation}
                    placeholder={
                      getAllLocationsData.find(
                        (item) => item._id === selectedLocation
                      )?.name || "Locations"
                    }
                    width={100}
                    borderColor="#ccc"
                    borderRadius="10px"
                    textSize="10px"
                    textColor="#1B3F8F"
                    dropdownTextSize="12px"
                    dropdownTextColor="#333"
                    dropdownTextAlign="left"
                    boxShadowFlag={true}
                  />

                  <CustomSearchDropdown
                    options={[
                      { value: "", label: "All Role" },
                      ...roleDetails?.map((option) => ({
                        value: option._id,
                        label: option.role_name,
                      })),
                    ]}
                    selectedValue={selectedRole}
                    handleChange={handleSelectRole}
                    placeholder={
                      roleDetails.find((item) => item._id === selectedRole)
                        ?.role_name || "Roles"
                    }
                    width={100}
                    borderColor="#ccc"
                    borderRadius="10px"
                    textSize="10px"
                    textColor="#1B3F8F"
                    dropdownTextSize="12px"
                    dropdownTextColor="#333"
                    dropdownTextAlign="left"
                    boxShadowFlag={true}
                  />
                </Box>
              )}

              <TableContainer className={`scroll tableContainer`}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {selectedTab === "my" &&
                      !permissionsLoading &&
                      !!permissionsData?.elearning_my_earnings
                        ? renderHeadRows(headRowMyTabItems)
                        : !permissionsLoading &&
                          !!permissionsData?.elearning_team_earnings &&
                          renderHeadRows(headRowTeamTabItems)}
                    </TableRow>
                  </TableHead>
                  <TableBody className={"tableBodyContainer"}>
                    {selectedTab === "my" &&
                    !permissionsLoading &&
                    !!permissionsData?.elearning_my_earnings
                      ? renderMyDataRows(accumulatedData)
                      : !permissionsLoading &&
                        !!permissionsData?.elearning_team_earnings &&
                        renderTeamDataRows(accumulatedData)}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectedTab === "my"
                ? myEarningLoading && (
                    <div className="loaderContainer">
                      <CircularProgress style={{ color: "#1B3F8F" }} />
                    </div>
                  )
                : teamEarningLoading && (
                    <div className="loaderContainer">
                      <CircularProgress style={{ color: "#1B3F8F" }} />
                    </div>
                  )}

              {selectedTab === "my"
                ? !myEarningLoading &&
                  myEarningData?.result?.length === 0 && (
                    <Box textAlign="center">
                      <Typography
                        style={{
                          color: "#848484",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        spacing={2}
                        m={"1rem"}
                      >
                        No Record Found
                      </Typography>
                    </Box>
                  )
                : !teamEarningLoading &&
                  teamEarningData?.result?.length === 0 && (
                    <Box textAlign="center">
                      <Typography
                        style={{
                          color: "#848484",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        spacing={2}
                        m={"1rem"}
                      >
                        No Record Found
                      </Typography>
                    </Box>
                  )}
              {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2} m={"1rem"}>
              <Pagination
                color="primary"
                size="small"
                count={
                  selectedTab === "my"
                    ? myEarningData?.totalPages
                    : teamEarningData?.totalPages
                }
                variant="outlined"
                page={
                  selectedTab === "my"
                    ? myEarningData?.currentPage
                    : teamEarningData?.currentPage
                }
                onChange={handlePageChange}
              />
            </Stack>
          </div> */}
              {selectedTab === "my" ? (
                myEarningData?.currentPage < myEarningData?.totalPages &&
                !myEarningLoading ? (
                  <LoadMoreMobile handlePageChange={loadMoreData} />
                ) : (
                  ""
                )
              ) : teamEarningData?.currentPage < teamEarningData?.totalPages &&
                !teamEarningLoading ? (
                <LoadMoreMobile handlePageChange={loadMoreData} />
              ) : (
                ""
              )}
            </>
          ) : (
            <NoPermission />
          )}
        </div>
      }
    </>
  );
};
