import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MySelectField, NoPermission, LoadingData, MyDatePicker } from '../../../components'
import { getScheduleData, updateScheduleData } from './modules/actions';
import { connect } from "react-redux";
import { schedulerColor } from '../../../config';
import ApplyToAllLocation from "./ApplyToAllLocation";
import PayrollTemplate from '../PayrollTemplate/PayrollTemplate'
import TimeCode from '../TimeCode/TimeCode'
import { excel as ExcelIcon, pe as PEIcon } from "../../../icons"
import CheckIcon from '@material-ui/icons/Check';
import {
    Avatar,
    Button,
    CardContent,
    Typography,
    Grid,
    Card,
    Checkbox,
    FormControlLabel
}
    from '@material-ui/core';
const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    mainDiv: {
        border: "2px solid #7C7C7C",
        borderRadius: "6px",
        margin: "20px",
        BoxSizing: "border-box"
    },
    HeadCell: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        color: "#232056",
        marginLeft: 10
    },
    buttonMain: {
        textAlign: "right",
        fontWeight: 700
    },
    button: {
        background: "#005F73",
        borderRadius: 8,
        color: "white",
        padding: 10,
    },
    flex: {
        display: "flex",
        margin: "20px",
    },
    Heading: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        fontWeight: 700
    },
    HeadingValue: {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "36px",
    },
    HeadingColor: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        color: "#B71C1C",
        fontWeight: 700
    },
    HeadingCompany: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "33px",
    },
    CardContent: {
        padding: "10px 15px 10px 15px",
        "&:last-child": {
            paddingBottom: '0px'
        },
    },
    connectButton: {
        backgroundColor: schedulerColor.main,
        fontSize: "10px",
        color: "#ffffff",
        border: "1px solid green"
    },
    connectedButton: {
        fontWeight: 800,
        fontSize: "10px",
        color: "green"
    },
    courseTitle: {
        fontWeight: '400',
        fontSize: '14px'
    },
    courseDescription: {
        fontWeight: '400',
        fontSize: '14px'
    }
});

export function fetchSCSettingsPayroll() {
    window.SettingsPayroll.getScheduleData();
}

class SettingsPayroll extends Component {
    constructor(props) {
        super(props)
        window.SettingsPayroll = this;
        this.state = {
            applyToAll: 0,
            emoji: 1,
            anchorEl: null,
            data: {
            },
            payrollRunArray: [
                { id: 1, name: "every week" },
                { id: 2, name: "every two weeks" },
                { id: 3, name: "once a month" },
                { id: 4, name: "twice a month" },
            ],
            payrollDaysArray: [
                { id: 0, name: "Sunday" },
                { id: 1, name: "Monday" },
                { id: 2, name: "Tuesday" },
                { id: 3, name: "Wednesday" },
                { id: 4, name: "Thrusday" },
                { id: 5, name: "Friday" },
                { id: 6, name: "Saturday" }
            ],
            payrollDatesArray: [
                { id: "01", name: "1st" },
                { id: "02", name: "2nd" },
                { id: "03", name: "3rd" },
                { id: "04", name: "4th" },
                { id: "05", name: "5th" },
                { id: "06", name: "6th" },
                { id: "07", name: "7th" },
                { id: "08", name: "8th" },
                { id: "09", name: "9th" },
                { id: "10", name: "10th" },
                { id: "11", name: "11th" },
                { id: "12", name: "12th" },
                { id: "13", name: "13th" },
                { id: "14", name: "14th" },
                { id: "15", name: "15th" },
                { id: "16", name: "16th" },
                { id: "17", name: "17th" },
                { id: "18", name: "18th" },
                { id: "19", name: "19th" },
                { id: "20", name: "20th" },
                { id: "21", name: "21st" },
                { id: "22", name: "22nd" },
                { id: "23", name: "23rd" },
                { id: "24", name: "24th" },
                { id: "25", name: "25th" },
                { id: "26", name: "26th" },
                { id: "27", name: "27th" },
                { id: "28", name: "28th" },
                { id: "29", name: "29th" },
                { id: "30", name: "30th" },
                { id: "31", name: "31th" }
            ]
        }
    }

    componentDidMount = () => {
        if(this.props.permissionsData?.scheduler_settings){
        this.getScheduleData()
        }
    }

    getScheduleData = () => {
        this.setState({
            isLoading: true
        })
        var lid = this.props.scheduler_lid
        this.props.getScheduleData(lid)
    }

    componentDidUpdate(prevProps) {
        if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
            this.setState({
                isLoading: true
            })
            var data = this.props.LocationDetaildata.locationDetail
            data.payroll_export_types = data.payroll_export_types.split(',');

            this.setState({
                isLoading: false,
                data
            })
        }
    }

    handleChangeDropdown = (e) => {
        let state = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: e.target.value
            }
        }))
    }

    handleChangeData = (e) => {
        let state = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: checked === true ? 1 : 0
            }
        }))
    }

    onsubmitData = () => {
        var data = {
            lid: this.state.data.lid,
            applyToAll: this.state.applyToAll,
            payroll_export_types: this.state.data.payroll_export_types.join(),
            payroll_is_run: this.state.data.payroll_is_run,
            payroll_start_day: this.state.data.payroll_start_day,
            payroll_start_date: this.state.data.payroll_start_date,
            payroll_twice_month_day1: this.state.data.payroll_twice_month_day1,
            payroll_twice_month_day2: this.state.data.payroll_twice_month_day2,
            payroll_once_in_month_day: this.state.data.payroll_once_in_month_day,
            payroll_lock_timesheets_approval: this.state.data.payroll_lock_timesheets_approval
        }
        this.props.updateScheduleData(data)
    }

    removeFromExport = (name) => {
        var payroll_export_types = this.state.data.payroll_export_types
        if (payroll_export_types.indexOf(name) > -1) {
            payroll_export_types.splice(payroll_export_types.indexOf(name), 1);
        }
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                payroll_export_types
            }
        }))
    }

    addToExport = (name) => {
        var payroll_export_types = this.state.data.payroll_export_types
        payroll_export_types.push(name)
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                payroll_export_types
            }
        }))
    }

    handleApplyToAll = (e) => {
        this.setState({
            applyToAll: e.target.checked ? 1 : 0
        })
    }

    handleChangeDate = (date, state) => {
        let d = new Date(date);
        let newdate = d.getDate();
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [state]: date,
            },
        }), () => {
        });
    };

    render() {
        const { permissionsData, classes, isLoading, pageNo, rowsPerPage } = this.props;
        
        if (this.state.isLoading) return <LoadingData />
        if (isLoading) return <LoadingData />
        var open = Boolean(this.state.anchorEl)
        return (
            permissionsData.scheduler_settings ?
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    {/* <Grid item md={12} >
                        <ApplyToAllLocation
                            applyToAll={this.state.applyToAll}
                            handleApplyToAll={this.handleApplyToAll}
                            handleSave={this.onsubmitData}
                        />
                        <Typography style={{marginLeft:22,fontSize:10,lineHeight:0}}><span style={{color:"red"}}>*</span> Except payroll rule , geo-fence and Break Rules</Typography>
                    </Grid> */}

                    <Grid container spacing={2}>
                        <Grid item md={7} >
                            <Grid className={classes.flex}>
                                <Grid
                                    style={{
                                        background: "#005F73",
                                        height: 10,
                                        width: 10
                                    }} />
                                <Typography
                                    style={{ lineHeight: '12px' }}
                                    className={classes.HeadCell}
                                >
                                    Payroll
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        sm={12}
                    >
                        <PayrollTemplate
                            permissionsData={permissionsData}
                            scheduler_lid={this.props.scheduler_lid}
                            pageNo={pageNo}
                            rowsPerPage={rowsPerPage}
                        />
                    </Grid>


                    <Grid
                        item
                        md={12}
                    >
                        <Card className={classes.card}>
                            <CardContent className={classes.CardContent} >
                                <Grid container spacing={2}>
                                    <Grid item md={6} style={{ lineHeight: 20 }}>
                                        <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                            Get all your systems working together to save money and time. <span style={{ color: 'blue' }}> Learn More</span>
                                        </Typography>

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} >
                                    <Grid item md={3} style={{ lineHeight: 20 }}>
                                        <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                            Payroll is run
                                        </Typography>

                                    </Grid>
                                    <Grid item md={3} style={{ lineHeight: 3 }}>
                                        <MySelectField
                                            label=""
                                            name="payroll_is_run"
                                            onChange={this.handleChangeDropdown}
                                            onFocus={this.handleFocus}
                                            value={this.state.data.payroll_is_run}
                                            error={false}
                                            helperText={false}
                                            placeholder="Please Select"
                                        >
                                            {this.state.payrollRunArray.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id} >
                                                        {item.name}
                                                    </option>
                                                )
                                            })}
                                        </MySelectField>
                                    </Grid>
                                </Grid>

                                {parseInt(this.state.data.payroll_is_run) === 1 ?
                                    <Grid container spacing={2} >
                                        <Grid item md={3} style={{ lineHeight: 20 }}>
                                            <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                                Our payroll periods begin on the
                                            </Typography>

                                        </Grid>
                                        <Grid item md={3} style={{ lineHeight: 3 }}>
                                            <MySelectField
                                                label=""
                                                name="payroll_start_day"
                                                onChange={this.handleChangeDropdown}
                                                onFocus={this.handleFocus}
                                                value={this.state.data.payroll_start_day}
                                                error={false}
                                                helperText={false}
                                                placeholder="Please Select"
                                            >
                                                {this.state.payrollDaysArray.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid>
                                    </Grid> : ""}

                                {parseInt(this.state.data.payroll_is_run) === 2 ?
                                    <Grid container spacing={2} >
                                        <Grid item md={3} style={{ lineHeight: 20 }}>
                                            <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                                Our payroll periods begin on the
                                            </Typography>

                                        </Grid>
                                        <Grid item md={3} style={{ lineHeight: 3 }}>
                                            {/* <MySelectField
                                                label=""
                                                name="payroll_start_date"
                                                onChange={this.handleChangeDropdown}
                                                onFocus={this.handleFocus}
                                                value={this.state.data.payroll_start_date}
                                                error={false}
                                                helperText={false}
                                                placeholder="Please Select"
                                            >
                                                {this.state.payrollDatesArray.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField> */}
                                            <MyDatePicker
                                                 label=""
                                                 name="payroll_start_date"
                                                //  onChange={this.handleChangeDate}
                                                onChange={(date) => {
                                                    this.handleChangeDate(date, "payroll_start_date");
                                                  }}
                                                 onFocus={this.handleFocus}
                                                 value={this.state.data.payroll_start_date}
                                                 error={false}
                                                 helperText={false}
                                                 placeholder="Please Select">

                                            </MyDatePicker>
                                        </Grid>
                                    </Grid> : ""}

                                {parseInt(this.state.data.payroll_is_run) === 3 ?
                                    <Grid container spacing={2} >
                                        <Grid item md={3} style={{ lineHeight: 20 }}>
                                            <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                                Our payroll periods begin on the
                                            </Typography>

                                        </Grid>
                                        <Grid item md={3} style={{ lineHeight: 3 }}>
                                            <MySelectField
                                                label=""
                                                name="payroll_once_in_month_day"
                                                onChange={this.handleChangeDropdown}
                                                onFocus={this.handleFocus}
                                                value={this.state.data.payroll_once_in_month_day}
                                                error={false}
                                                helperText={false}
                                                placeholder="Please Select"
                                            >
                                                {this.state.payrollDatesArray.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid>
                                    </Grid> : ""}



                                {parseInt(this.state.data.payroll_is_run) === 4 ?
                                    <Grid container spacing={2} >
                                        <Grid item md={3} style={{ lineHeight: 20 }}>
                                            <Typography style={{ fontSize: 14, fontWeight: 700, marginTop: 4 }} color="text.secondary" gutterBottom>
                                                Our payroll periods begin on the
                                            </Typography>

                                        </Grid>
                                        <Grid item md={3} style={{ lineHeight: 3 }}>
                                            <MySelectField
                                                label=""
                                                name="payroll_twice_month_day1"
                                                onChange={this.handleChangeDropdown}
                                                onFocus={this.handleFocus}
                                                value={this.state.data.payroll_twice_month_day1}
                                                error={false}
                                                helperText={false}
                                                placeholder="Please Select"
                                            >
                                                {this.state.payrollDatesArray.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid>

                                        <Grid item md={3} style={{ lineHeight: 3 }}>
                                            <MySelectField
                                                label=""
                                                name="payroll_twice_month_day2"
                                                onChange={this.handleChangeDropdown}
                                                onFocus={this.handleFocus}
                                                value={this.state.data.payroll_twice_month_day2}
                                                error={false}
                                                helperText={false}
                                                placeholder="Please Select"
                                            >
                                                {this.state.payrollDatesArray.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} >
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid>
                                    </Grid> : ""}


                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid
                        item
                        sm={12}
                    >
                        <TimeCode
                            permissionsData={permissionsData}
                            scheduler_lid={this.props.scheduler_lid}
                            pageNo={pageNo}
                            rowsPerPage={rowsPerPage}
                        />
                    </Grid>


                    {permissionsData.scheduler_payroll_view_export ?
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <Card className={classes.card}>
                                <CardContent className={classes.CardContent} >
                                    <Grid container style={{ paddingBottom: "10px" }}>
                                        <Grid
                                            item
                                            sm={1}
                                            xs={12}
                                        >
                                            <Avatar
                                                src={PEIcon}
                                                className={classes.avatar}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            sm={10}
                                            xs={12}
                                        >
                                            <Typography
                                                className={classes.courseTitle}
                                                variant="h6"
                                                color="textSecondary"
                                                component="p">
                                                PE
                                            </Typography>

                                            <Typography
                                                className={classes.courseDescription}
                                                variant="body1"
                                                color="textSecondary"
                                                component="p">
                                                description..
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            style={{ margin: 'auto' }}
                                            item
                                            sm={1}
                                            xs={12}
                                        >
                                            {this.state.data.payroll_export_types &&
                                                this.state.data.payroll_export_types.length > 0
                                                && this.state.data.payroll_export_types.includes("pe") ?
                                                <Button
                                                    disabled={permissionsData.scheduler_payroll_change_export ? false : true}
                                                    onClick={() => { this.removeFromExport("pe") }}
                                                    variant="text"
                                                    className={classes.connectedButton}
                                                    startIcon={<CheckIcon />}
                                                >
                                                    Connected
                                                </Button> :
                                                <Button
                                                    disabled={permissionsData.scheduler_payroll_change_export ? false : true}
                                                    onClick={() => { this.addToExport("pe") }}
                                                    variant="outlined"
                                                    className={classes.connectButton}
                                                >
                                                    Connect
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>


                            <Card className={classes.card}>
                                <CardContent className={classes.CardContent} >
                                    <Grid container style={{ paddingBottom: "10px" }}>
                                        <Grid
                                            item
                                            sm={1}
                                            xs={12}
                                        >
                                            <Avatar
                                                src={ExcelIcon}
                                                className={classes.avatar}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            sm={10}
                                            xs={12}
                                        >
                                            <Typography
                                                className={classes.courseTitle}
                                                variant="h6"
                                                color="textSecondary"
                                                component="p">
                                                Cash
                                            </Typography>

                                            <Typography
                                                className={classes.courseDescription}
                                                variant="body1"
                                                color="textSecondary"
                                                component="p">
                                                description..
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            style={{ margin: 'auto' }}
                                            item
                                            sm={1}
                                            xs={12}
                                        >
                                            <Button
                                                disabled={permissionsData.scheduler_payroll_change_export ? false : true}
                                                onClick={() => { this.removeFromExport("cash") }}
                                                variant="text"
                                                className={classes.connectedButton}
                                                startIcon={<CheckIcon />}
                                            >
                                                Connected
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        : <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <Card
                                style={{ padding: '3rem' }}
                                className={classes.card}>
                                <CardContent className={classes.CardContent} >
                                    <Typography
                                        style={{ textAlign: 'center' }}
                                        className={classes.courseTitle}
                                        variant="h2"
                                        color="textSecondary"
                                        component="p">
                                        View Permissions to view export types
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }

                    <Grid
                        item
                        md={12}
                    >
                        <Card>
                            <CardContent>
                                <Typography variant="h6" component="div" style={{ fontWeight: 700, fontSize: '14px' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color={schedulerColor.main}
                                            name="payroll_lock_timesheets_approval"
                                            checked={this.state.data.payroll_lock_timesheets_approval ? true : false}
                                            onChange={this.handleChangeData}
                                        />}
                                    />
                                    Lock Timesheets after approval
                                </Typography>
                                <Typography variant="h6" component="div" style={{ marginLeft: 46, fontSize: '14px', fontWeight: 400 }}>
                                    Timesheets will be locked and cannot be edited after approval.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid > :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerSettingsLocationdata.isLoading,
    LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
});

const ConnectWith = connect(mapStateToProps, { getScheduleData, updateScheduleData })(SettingsPayroll);
export default withStyles(styles)(ConnectWith);
