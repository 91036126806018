import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  DeleteModal,
  MySearchBar,
  MyLeftSchedulerModal,
  MoreMenu,
} from "../../../components";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import { fetchData, addData, editData, deleteData } from "./modules/actions";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { utcToLocal, invalidToken, alert } from "../../../utilities";
import { FILE_URL, schedulerColor } from "../../../config";
import { tradeServices } from "./modules/services";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SearchBar from "material-ui-search-bar";
import CancelIcon from "@mui/icons-material/Cancel";
import ApprovalIcon from "@mui/icons-material/Approval";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Avatar,
  Button,
} from "@material-ui/core";
import { history } from "../../../history";
import Cookies from "universal-cookie";
import DateRangeSelector from "../../../components/Scheduler/DateRange/DateRangeSelector";
const cookies = new Cookies();
const schUID = cookies.get("ovUid");

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  TableCell: {
    padding: "10px",
    minWidth: "155px",
  },
  headCell: {
    padding: "0.3rem 0rem",
    color: "#aa076b",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "20px 10px 20px 10px",
    },
  },
  filtersContainer: {
    display: "flex",
    gap: "10px",
    // width: "100%",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    margin: "10px 0px 10px 0px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      width: "100%",
      margin: "0",
    },
  },
  title: {
    // flex: "1 1 100%",
    width: "auto",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "27px",
    display: "flex",
    justifyContent: "flex-start",
    color: "#1E2134",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardDate: {
    backgroundImage: schedulerColor.main,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  userName: {
    maxWidth: "250px",
    // lineHeight: 3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#1E2134",
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "35px",
    width: "35px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundImage: theme.palette.primary.main,
    color: "white",
  },
  button: {
    color: "#ffffff",
    padding: "6px 10px",
    width: "80px",
    fontWeight: "400",
    textTransform: "Capitalize",
  },
  SearchBar: {
    fontSize: "14px !important",
    color: "#7D7D7D !important",
    width: "455px !important",
    height: "46px !important",
    border: "none !important",
    fontFamily: "century_gothic !important",
    borderRadius: "22px !important",
    paddingLeft: "20px !important",
    paddingTop: "9px !important",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25) !important",
  },
  icon: {
    position: "absolute",
    // right: "32px",
    left: "10px",
    fontSize: "23px",
    top: "50%",
    color: "#7D7D7D",
    transform: "translate(0%, -50%)",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "5px 0 5px 0",
  },
});

export function fetchTradeShiftsData() {
  window.TradeShift.fetchData(true);
}

class TradeShift extends Component {
  constructor(props) {
    super(props);
    window.TradeShift = this;
    this.state = {
      myItem: {},
      order: "desc",
      orderBy: "id",
      searchUsers: "",
      teamTradeData: [],
      moreData: [
        {
          icon: ApprovalIcon,
          title: "Approve",
          sortName: "approve",
          onClick: this.modifyData,
        },
        {
          icon: CancelIcon,
          title: "Reject",
          sortName: "reject",
          onClick: this.modifyData,
        },
      ],
      searchUsersData: "",
      timer: null,
      page: 1,
      statusFilter: "0",
      trade_shift_start_time: null,
      trade_shift_end_time: null,
      replace_shift_start_time: null,
      replace_shift_end_time: null,
      headCellsReqBy: [
        { id: "request_to", label: "Requested To", numeric: false },
        { id: "location_name", label: "Location", numeric: false },
        {
          id: "shift_to_trade_startTime",
          label: "Shift To Trade",
          numeric: false,
        },
        {
          id: "shift_to_trade_startTime",
          label: "Requested By",
          numeric: false,
        },
        { id: "replace_with_currentDate", label: "Trade with", numeric: false },
        { id: "requestedBy", label: "Req date", numeric: false },
        { id: "manager_status", label: "Co-worker approval", numeric: false },
        { id: "manager_status", label: "Manager approval", numeric: false },
      ],
      headCells: [
        { id: "request_to", label: "Requested to", numeric: false },
        { id: "location_name", label: "Location", numeric: false },
        {
          id: "shift_to_trade_startTime",
          label: "Shift To Trade",
          numeric: false,
        },
        { id: "replace_with_currentDate", label: "Trade with", numeric: false },
        { id: "requestedBy", label: "Req date", numeric: false },
        { id: "manager_status", label: "Co-worker approval", numeric: false },
        { id: "manager_status", label: "Manager approval", numeric: false },
      ],
    };
  }

  componentDidMount = () => {
    if (this.props.permissionsData?.scheduler_trade_shift) {
      const queryParameters = new URLSearchParams(window.location.search);
      const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
      this.fetchData(value);
    }
  };


  handleChange = (event, value) => {
    var url = `/scheduler/tradeshift/team?page=` + value;
    this.setState(
      {
        page: value,
      },
      () => {
        this.fetchData(value);
        history.push(url);
      }
    );
  };

  fetchData = (value) => {
    this.setState({
      page: value,
      isLoading: true,
    });

    const {
      trade_shift_start_time,
      trade_shift_end_time,
      replace_shift_start_time,
      replace_shift_end_time,
      searchUsersData,
      statusFilter,
    } = this.state;

    let data = {
      page: value,
      limit: 10,
      lid: this.props.scheduler_lid,
      ...(trade_shift_start_time && trade_shift_end_time
        ? { trade_shift_start_time, trade_shift_end_time }
        : {}),
      ...(replace_shift_start_time && replace_shift_end_time
        ? { replace_shift_start_time, replace_shift_end_time }
        : {}),
    };
    if (searchUsersData) {
      // data.request_to = searchUsersData;
      data.request_by = searchUsersData;
    }
    if (statusFilter !== "0") {
      data.manager_status = parseInt(statusFilter);
    }
    tradeServices.fetchTeamData(data).then((response) => {
      this.setState({
        teamTradeData: response.data.result,
        totalpage: response.data.totalPages,
        isLoading: false,
      });
    });
  };

  // onChangeRowsPerPage = (event) => {
  //     this.setState({
  //       rowsPerPage: parseInt(event.target.value, 10)
  //     },() => {
  //         this.fetchData(true)
  //     })
  //   }

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    this.props.deleteData(this.state.myItem.id);
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  moreMenuClick = (item, value) => {
    this.setState({
      myItem: item,
    }, () => {
      this.modifyData(value, item)
    });
  };

  modifyData = (value, item) => {
    if (value === "approve") {
      this.handleApprove();
    }
    if (value === "reject") {
      this.handleReject();
    }
    this.setState({
      myItem: item,
      deleteModal: value === "delete" ? true : false,
    });
  };

  updateUrlAndFetchData = (component, page) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", page);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, "", newUrl);

    component.setState({ page }, () => component.fetchData(page));
  };

  handleUserSearch = (value) => {
    this.setState({
      searchUsersData: value,
    });

    clearTimeout(this.state.timer);

    const newTimer = setTimeout(() => {
      this.updateUrlAndFetchData(this, 1);
    }, 1500);

    this.setState({ timer: newTimer });
    // setTimer(newTimer);
  };

  handleStatusFilter = (e) => {
    const value = e.target.value;

    console.log("value", value);

    this.setState({ statusFilter: value }, () => {
      this.updateUrlAndFetchData(this, 1);
    });
  };

  // Main component methods
  handleTradeShiftDateRange = (startDate, endDate) => {
    this.setState(
      { trade_shift_start_time: startDate, trade_shift_end_time: endDate },
      () => this.updateUrlAndFetchData(this, 1)
    );
  };

  handleClearTradeShiftDate = () => {
    this.setState(
      { trade_shift_start_time: null, trade_shift_end_time: null },
      () => this.updateUrlAndFetchData(this, 1)
    );
  };

  handleReplaceShiftDateRange = (startDate, endDate) => {
    this.setState(
      { replace_shift_start_time: startDate, replace_shift_end_time: endDate },
      () => this.updateUrlAndFetchData(this, 1)
    );
  };

  handleClearReplaceShiftDate = () => {
    this.setState(
      { replace_shift_start_time: null, replace_shift_end_time: null },
      () => this.updateUrlAndFetchData(this, 1)
    );
  };

  openDetailModal = (row) => {
    this.setState({
      detailModal: true,
      tradeUserData: row,
      isUserLoading: true,
    });
  };

  closeDetalModal = () => {
    this.setState({
      detailModal: false,
    });
  };

  handleApproveRejectCoWorker = (row, value) => {
    var data = {
      status: value,
    };
    tradeServices
      .approveRejectTeamTradeByUser(row._id, data)
      .then((response) => {
        if (response.data.success === 2) {
          invalidToken(response.data.message);
        } else {
          alert.success(response.data.message);
          // this.closeDetalModal()
          const queryParameters = new URLSearchParams(window.location.search);
          const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
          this.fetchData(value);
        }
      });

  };

  handleApproveRejectManager = (row, value) => {
    var data = {
      status: value,
      uid: row.requested_to_uid,
    };
    tradeServices
      .approveRejectTeamTradeByManager(row._id, data)
      .then((response) => {
        if (response.data.success === 2) {
          invalidToken(response.data.message);
        } else {
          alert.success(response.data.message);
          // this.closeDetalModal()
          const queryParameters = new URLSearchParams(window.location.search);
          const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
          this.fetchData(value);
        }
      });
  };

  render() {
    const { classes, isLoading, pageNo, permissionsData, rowsPerPage } =
      this.props;
    const { teamTradeData, totalpage } = this.state;
    var nextDayDate = false;
    var currentDate = new Date(this.state.tradeUserData?.currentDate);
    var startTime = new Date(this.state.tradeUserData?.startTime);
    var endTime = new Date(this.state.tradeUserData?.endTime);
    var currentDateNew = currentDate.toLocaleString("en-US", {
      timeZone: this.props.timezone[0].location_timezone,
    });
    var startTimeNew = startTime.toLocaleString("en-US", {
      timeZone: this.props.timezone[0].location_timezone,
    });
    var endTimeNew = endTime.toLocaleString("en-US", {
      timeZone: this.props.timezone[0].location_timezone,
    });

    if (
      moment(endTimeNew, "DD/MM/YYYY,hh:mm:ss A").format("HH:mm:ss A") <
      moment(startTimeNew, "DD/MM/YYYY,hh:mm:ss A").format("HH:mm:ss A")
    ) {
      nextDayDate = true;
    }
    return permissionsData.scheduler_trade_shift ? (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <Paper>
            <Toolbar variant="regular" className={classes.rootToolbar}>
              <Typography
                display="inline"
                className={classes.title}
                variant="h6"
              >
                <b
                  style={{
                    color: "#AA076B",
                    fontWeight: "500",
                    fontSize: "17px",
                    marginLeft: "15px",
                  }}
                >
                  Team Trade{" "}
                </b>
              </Typography>

              <div className={classes.filtersContainer}>
                <TextField
                  style={{ width: "200px" }}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  placeholder="Search req by user..."
                  onChange={(e) => this.handleUserSearch(e.target.value)}
                  value={this.state.searchUsersData}
                />
                <TextField
                  autoFocus={false}
                  medium
                  select
                  SelectProps={{ native: true }}
                  style={{ width: "200px" }}
                  required={this.props.required ? this.props.required : false}
                  label="Time Off Request"
                  onChange={this.handleStatusFilter}
                  value={this.state.statusFilter}
                  variant="outlined"
                  size="small"
                >
                  <option key={0} value="0">
                    All
                  </option>
                  <option key={1} value={"1"}>
                    Approved
                  </option>
                  <option key={2} value={"2"}>
                    Rejected
                  </option>
                </TextField>

                {/* <MySearchBar
                               
                                    clearSearch={this.clearSearch}
                                    handleSearch={this.handleSearch}
                                    value={this.state.searchUsers}
                                    placeholder="Search user"
                                /> */}

                <DateRangeSelector
                  handleDateRange={this.handleTradeShiftDateRange}
                  startDate={this.state.trade_shift_start_time}
                  handleClose={this.handleClearTradeShiftDate}
                  endDate={this.state.trade_shift_end_time}
                  customButtonClassName={{
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    color: "black",
                    border: `1px solid #ccc`,
                    // borderRadius: "7px",
                    minWidth: "200px",
                    height: `40px`,
                    textTransform: "capitalize",
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 15px 8px 12px",
                  }}
                  placeholder={"Select Trade Shift Date"}
                />
                <DateRangeSelector
                  handleDateRange={this.handleReplaceShiftDateRange}
                  startDate={this.state.replace_shift_start_time}
                  handleClose={this.handleClearReplaceShiftDate}
                  endDate={this.state.replace_shift_end_time}
                  customButtonClassName={{
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    color: "black",
                    border: `1px solid #ccc`,
                    // borderRadius: "7px",
                    minWidth: "200px",
                    height: `40px`,
                    textTransform: "capitalize",
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 15px 8px 12px",
                  }}
                  placeholder={"Select Replace Shift Date"}
                />
              </div>
            </Toolbar>

            <TableContainer>
              <Table className={classes.table} size="medium">
                <TableHead
                  style={{ backgroundColor: "rgba(170, 7, 107, 0.125)" }}
                >
                  <TableRow>
                    {this.props.permissionsData?.scheduler_tradeshifts_approve === 1 ?
                      this.state.headCellsReqBy.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sortDirection={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : false
                          }
                          style={{ minWidth: "155px" }}
                        >
                          <TableSortLabel
                            className={classes.headCell}
                            active={this.state.orderBy === headCell.id}
                            direction={
                              this.state.orderBy === headCell.id
                                ? this.state.order
                                : "asc"
                            }
                            onClick={this.createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {this.state.orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {this.state.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      )) :
                      this.state.headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sortDirection={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : false
                          }
                          style={{ minWidth: "155px" }}
                        >
                          <TableSortLabel
                            className={classes.headCell}
                            active={this.state.orderBy === headCell.id}
                            direction={
                              this.state.orderBy === headCell.id
                                ? this.state.order
                                : "asc"
                            }
                            onClick={this.createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {this.state.orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {this.state.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))

                    }
                  </TableRow>
                </TableHead>
                {this.state.isLoading ? (
                  <TableBody>
                    <LoadingData />
                  </TableBody>
                ) : (
                  <TableBody>
                    {stableSort(
                      teamTradeData,
                      getComparator(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        pageNo * rowsPerPage,
                        pageNo * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        var reqDate = new Date(row?.createdAt);
                        var created_at = reqDate.toLocaleString("en-US", {
                          timeZone: this.props.timezone[0].location_timezone,
                        });
                        ////Shift To trade
                        var shift_to_trade_currentDateTemp = new Date(
                          row?.shift_to_trade_currentDate
                        );
                        var shift_to_trade_currentDate =
                          shift_to_trade_currentDateTemp.toLocaleString(
                            "en-US",
                            {
                              timeZone:
                                this.props.timezone[0].location_timezone,
                            }
                          );
                        var shift_to_trade_startTimeTemp = new Date(
                          row?.shift_to_trade_startTime
                        );
                        var shift_to_trade_startTime =
                          shift_to_trade_startTimeTemp.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });
                        var shift_to_trade_endTimeTemp = new Date(
                          row?.shift_to_trade_endTime
                        );
                        var shift_to_trade_endTime =
                          shift_to_trade_endTimeTemp.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });

                        ///End shift to trade

                        ////Trade with
                        var replace_with_currentDateTemp = new Date(
                          row?.replace_with_currentDate
                        );
                        var replace_with_currentDate =
                          replace_with_currentDateTemp.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });
                        var replace_with_startTimeTemp = new Date(
                          row?.replace_with_startTime
                        );
                        var replace_with_startTime =
                          replace_with_startTimeTemp.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });
                        var replace_with_endTimeTemp = new Date(
                          row?.replace_with_endTime
                        );
                        var replace_with_endTime =
                          replace_with_endTimeTemp.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });

                        var requestedIdSameAsLoginId = row.requested_to_uid === schUID

                        ////end trade with
                        return (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell
                              style={{ verticalAlign: "middle" }}
                              className={classes.TableCell}
                            >
                              <Typography
                                className={classes.userName}
                                style={{ marginTop: "5px" }}
                              >
                                {row.request_to}
                              </Typography>
                            </TableCell>

                            <TableCell className={classes.TableCell}>
                              <Typography className={classes.userName}>
                                {row.location_name}
                              </Typography>
                            </TableCell>

                            <TableCell className={classes.TableCell}>
                              <Typography className={classes.userName}>

                                <div style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>

                                  {moment(shift_to_trade_currentDate)
                                    .locale("en-US")
                                    .format("DD MMM")}
                                  <div
                                    style={{
                                      borderLeft: '2px solid #AA076B',
                                      height: '45px',
                                      margin: '0 6px',
                                    }}
                                  ></div>
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div>
                                      {moment(shift_to_trade_startTime)
                                        .locale("en-US")
                                        .format(" hh:mm A")}
                                      -{" "}
                                      {moment(shift_to_trade_endTime)
                                        .locale("en-US")
                                        .format("hh:mm A")}
                                    </div>
                                    <div>
                                      {row?.shift_to_trade_scheduler_role}
                                    </div>

                                  </div>
                                </div>
                              </Typography>
                            </TableCell>
                            {this.props.permissionsData?.scheduler_tradeshifts_approve === 1 ?
                              <TableCell
                                style={{ verticalAlign: "middle" }}
                                className={classes.TableCell}
                              >
                                <Typography
                                  className={classes.userName}
                                  style={{ marginTop: "5px" }}
                                >
                                  {row.request_by}
                                </Typography>
                              </TableCell> : ""
                            }

                            <TableCell className={classes.TableCell}>
                              <Typography className={classes.userName}>
                                <div style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>

                                  {moment(replace_with_currentDate)
                                    .locale("en-US")
                                    .format("DD MMM")}
                                  <div
                                    style={{
                                      borderLeft: '2px solid #AA076B',
                                      height: '45px',
                                      margin: '0 6px',
                                    }}
                                  ></div>
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div>
                                      {moment(replace_with_startTime)
                                        .locale("en-US")
                                        .format(" hh:mm A")}
                                      -{" "}
                                      {moment(replace_with_endTime)
                                        .locale("en-US")
                                        .format("hh:mm A")}
                                    </div>
                                    <div>
                                      {row?.replace_with_scheduler_role}
                                    </div>
                                  </div>
                                </div>

                              </Typography>
                            </TableCell>


                            <TableCell className={classes.TableCell}>
                              <Typography className={classes.userName}>
                                {moment(created_at)
                                  .locale("en-US")
                                  .format("DD MMM YYYY , hh:mm A")}
                                <br />
                              </Typography>
                            </TableCell>

                            <TableCell className={classes.TableCell} align="left">
                              <Typography
                                className={classes.userName}
                                style={{
                                  color:
                                    parseInt(row.user_status) === 0
                                      ? "#ADB005"
                                      : parseInt(row.user_status) === 1
                                        ? "#1B693F"
                                        : parseInt(row.user_status) === 2
                                          ? "red"
                                          : ""
                                }}
                              >
                                {requestedIdSameAsLoginId
                                  ?
                                  parseInt(row.user_status) === 0 ?
                                    <>
                                      <CheckCircleRoundedIcon
                                        onClick={() => this.handleApproveRejectCoWorker(row, 1)
                                        }
                                        style={{
                                          color:
                                            "green",
                                          cursor:
                                            "pointer",
                                        }}
                                      />
                                      <CancelRoundedIcon
                                        onClick={() => this.handleApproveRejectCoWorker(row, 2)
                                        }
                                        style={{
                                          color:
                                            "red",
                                          cursor:
                                            "pointer",
                                        }}
                                      />
                                    </>
                                    : parseInt(row.user_status) === 1
                                      ? "Approved"
                                      : parseInt(row.user_status) === 2
                                        ? "Rejected"
                                        : ""
                                  :
                                  parseInt(row.user_status) === 0 ?
                                    "Pending"
                                    : parseInt(row.user_status) === 1
                                      ? "Approved"
                                      : parseInt(row.user_status) === 2
                                        ? "Rejected"
                                        : ""}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.TableCell} align="left">
                              <Typography
                                className={classes.userName}
                                style={{
                                  color:
                                    parseInt(row.manager_status) === 0
                                      ? "#ADB005"
                                      : parseInt(row.manager_status) === 1
                                        ? "#1B693F"
                                        : parseInt(row.manager_status) === 2
                                          ? "red"
                                          : ""
                                }}
                              >
                                {
                                  requestedIdSameAsLoginId && row.manager_status === 0  ? (
                                    // If `requestedIdSameAsLoginId` is true, show buttons
                                    <>
                                      <CheckCircleRoundedIcon
                                        onClick={() => this.handleApproveRejectManager(row, 1)}
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <CancelRoundedIcon
                                        onClick={() => this.handleApproveRejectManager(row, 2)}
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </>
                                  ) : parseInt(row.user_status) === 1 ? (
                                    // If `user_status` is 1
                                    parseInt(row.manager_status) === 0 ? (
                                      // If `manager_status` is 0, show approval/rejection buttons
                                      <>
                                        <CheckCircleRoundedIcon
                                          onClick={() => this.handleApproveRejectManager(row, 1)}
                                          style={{
                                            color: "green",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <CancelRoundedIcon
                                          onClick={() => this.handleApproveRejectManager(row, 2)}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </>
                                    ) : parseInt(row.manager_status) === 1 ? (
                                      // If `manager_status` is 1, show "Approved"
                                      "Approved"
                                    ) : parseInt(row.manager_status) === 2 ? (
                                      // If `manager_status` is 2, show "Rejected"
                                      "Rejected"
                                    ) : (
                                      // If no manager status, show empty string
                                      <>
                                      <CheckCircleRoundedIcon
                                        style={{
                                          color: "gray",
                                          cursor: "default",
                                        }}
                                      />
                                      <CancelRoundedIcon
                                        style={{
                                          color: "gray",
                                          cursor: "default",
                                        }}
                                      />
                                    </>
                                    )
                                  ) : (
                                      parseInt(row.manager_status) === 0
                                      ? "Pending" :
                                          parseInt(row.manager_status) === 1
                                      ? "Approved"
                                      : parseInt(row.manager_status) === 2
                                        ? "Rejected"
                                        : ""
                                    // If `user_status` is not 1, show disabled buttons (gray)
                                    
                                  )
                                }

                              </Typography>
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              className={classes.TableCell}
                            >
                              <MoreMenu
                              disabled={parseInt(row.manager_status) === 0 ? false : true}
                                moreMenuClick={() => {
                                  this.moreMenuClick(row);
                                }}
                                moreData={this.state.moreData}
                              />
                            </TableCell> */}
                          </TableRow>
                        );
                      })}

                    {teamTradeData && teamTradeData.length < 1 ? (
                      <TableRecordNotFound
                        colSpan={8}
                        label="No Data Found.."
                      />
                    ) : (
                      ""
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
              <Stack spacing={2}>
                <Pagination
                  color="primary"
                  count={totalpage === 0 ? 1 : totalpage}
                  variant="outlined"
                  page={parseInt(this.state.page)}
                  onChange={this.handleChange}
                  className={classes.pagination}
                />
              </Stack>
            </div>
          </Paper>

          <DeleteModal
            bgColor={schedulerColor.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />


        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerTradeShift.isLoading,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  addData,
  editData,
  deleteData,
})(TradeShift);
export default withStyles(styles)(ConnectWith);
